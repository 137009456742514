import React, { Component } from 'react'
import {FormControl,InputLabel,OutlinedInput,Button} from '@material-ui/core'
import image_line from 'assets/icon/image_line.svg'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
import {handleFile,getBase64} from 'components/handleFile'
import {connect} from 'react-redux'
import {modalToggleReset} from 'redux/actions/general'
import {setTestimony} from 'redux/actions/event'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#4CAF50',
        },
        secondary:{
            main:'#f44336'
        }
    } 
})
class testimony extends Component {
    state={
        file_error:null,
        file_url:null,
        file_name:null,
        file:null,
        name:'',
        title:'',
        company:'',
        testimony:''
    }
    componentWillMount(){
        const {modal_data}=this.props.general
        console.log('modal',modal_data,this.props.event.testimony)
        if(modal_data!==null){
            this.setState({
                file:modal_data.file,
                file_url:modal_data.file_url,
                name:modal_data.name,
                title:modal_data.title,
                company:modal_data.company,
                testimony:modal_data.testimony,
            })
        }
    }
    onChange=(e)=>{
        this.setState({[e.target.name]:e.target.value})
    }
    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    handleTestimony=(evt)=>{
        let handle= handleFile(evt)
        if(handle.file_error===null){
            let file_url=URL.createObjectURL(handle.file)
            getBase64(handle.file,(result)=>{
                this.setState({file_url,file:result,file_error:null,file_name:handle.file_name})

            })
        }else{
            console.log('err',handle)
            this.setState({file_error:handle.file_error})
        }
      
    }
    deleteImage=()=>{
        this.setState({file_error:null,file_url:null,file:null})
    }
    
    addTestimony=()=>{
        const {file,name,title,company,testimony,file_url,file_name}=this.state
       
        let testimony_state={id:0,file,name,title,company,testimony,file_url,file_name}
        let existing=[
            ...this.props.event.testimony,
            testimony_state,
        ]

        this.props.setTestimony(existing)
        this.props.modalToggleReset()
    }
    updateTestimony=()=>{
        const {file,name,title,company,testimony,file_url}=this.state
        const {modal_data}=this.props.general
        this.props.event.testimony[modal_data.index].file=file
        this.props.event.testimony[modal_data.index].name=name
        this.props.event.testimony[modal_data.index].title=title
        this.props.event.testimony[modal_data.index].company=company
        this.props.event.testimony[modal_data.index].testimony=testimony
        this.props.event.testimony[modal_data.index].file_url=file_url
        this.props.modalToggleReset()
        // console.log('eve',modal_data,this.props.event.testimony)

    }
    render() {
        const {file,name,file_error,title,company,testimony,file_url}=this.state
        const {modal_action}=this.props.general

        return (
            <div className='testimony-modal-wrapper'>
                <p className='bold'>Image</p>
               <div className='card-container'>
                   {file_url!==null?
                        <img src={file_url} style={{width:200,marginRight:20,height:200,borderRadius:10,objectFit:'cover'}}/>
                   :
                    <div className='square-image-testimony'>
                        <MuiThemeProvider theme={themeButton}>
                        <Button
                            className='btn-remove-capital'
                            variant='text'
                            startIcon={<img src={image_line}/>}
                            color='primary'
                            component="label"
                        >
                            Add image here
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={this.handleTestimony}
                            />
                        </Button>
                        </MuiThemeProvider>
                    </div>
                    }
                    {file_error!==null&&<p style={{color:'red',fontSize:10}}>{file_error}</p>}
                    <div className='testimony-form'>
                        
                        <FormControl variant="outlined" size="small"  className='add-investment__field'>
                            <InputLabel  htmlFor="date">Name</InputLabel>
                            <OutlinedInput
                                id="name"
                                type='text'
                                name='name'
                                value={name}
                                onChange={this.onChange}
                                required
                                labelWidth={40}
                                className='field-radius'
                            />
                        </FormControl>
                        <FormControl variant="outlined" size="small"  className='add-investment__field'>
                            <InputLabel  htmlFor="job_title">Job title</InputLabel>
                            <OutlinedInput
                                
                                id="job_title"
                                type='text'
                                name='title'
                                onChange={this.onChange}
                                value={title}

                                required
                                labelWidth={60}
                                className='field-radius'
                            />
                        </FormControl>
                        <FormControl variant="outlined" size="small" className='add-investment__field' >
                            <InputLabel  htmlFor="company_name">Company name</InputLabel>
                            <OutlinedInput
                                
                                id="company_name"
                                type='text'
                                name='company'
                                onChange={this.onChange}
                                value={company}

                                required
                                labelWidth={120}
                                className='field-radius'
                            />
                        </FormControl>
                        <FormControl variant="outlined" size="small" className='add-investment__field' >
                            <InputLabel  htmlFor="testimony">Testimony</InputLabel>
                            <OutlinedInput
                                
                                id="testimony"
                                type='text'
                                name='testimony'
                                onChange={this.onChange}
                                value={testimony}
                                multiline
                                required
                                labelWidth={80}
                                className='field-radius'
                            />
                        </FormControl>


                    </div>
                </div>
                <div className='card-event-footer'>
                    <MuiThemeProvider theme={themeButton}>
                        <Button
                            className='btn-remove-capital'
                            variant='text'
                            color='secondary'
                            onClick={this.deleteImage}
                        >
                            Delete image
                        </Button>
                    </MuiThemeProvider>
                    {modal_action==='add_testimony'?
                    <Button onClick={this.addTestimony} size='small' color='primary' variant='contained' className='head-add-section__btn '>Add</Button>
                    :
                    <Button onClick={this.updateTestimony}  size='small' color='primary' variant='contained' className='head-add-section__btn'>Update</Button>
                    }
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    event:state.event,
    general:state.general
})
const mapDispatchToProps={
    setTestimony,
    modalToggleReset
}
export default connect(mapStateToProps,mapDispatchToProps)(testimony)