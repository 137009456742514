import React, { Component } from 'react'
import './style.css'

import chevron from 'assets/icon/chevron-down.svg'
import {Button,Collapse} from '@material-ui/core'
import moment from 'moment'
const data_agenda=[
    {
        date:'3 March 2020',
        time:'09.00-17.00',
        agenda:'<p><em><strong>Challenges of 4.0 Industrial Revolution and Impact on Organization Agility Five Characteristics of an Agile Organization.</strong></em></p> <p><strong><em>Overview GML Research in Organization Design</em></strong></p> <p> <br> </p> <p><strong>1. Agile Characteristic 1. Shared Purpose, Vision, and Strategy</strong></p> <p>&nbsp; &nbsp; &nbsp; - Practices of mission and vision in agile corporations</p> <p>&nbsp; &nbsp; &nbsp; - SPEx2 framework in cascading strategy into execution-</p> <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;oriented actions</p> <p><strong>2. Agile Characteristic 2. Rapid Decision Making and Development Process</strong></p> <p>&nbsp; &nbsp; &nbsp;- Value chain framework and Process Management Xcellenct&nbsp;</p> <p>&nbsp; &nbsp; &nbsp; &nbsp; (PMX)</p> <p>&nbsp; &nbsp; &nbsp;- LEAN-Startup methodology</p> <p>&nbsp; &nbsp; &nbsp;- Death of performance appraisal, rise of Continuous</p> <p>&nbsp; &nbsp; &nbsp; &nbsp;Performance Management</p> <p><strong>3. Agile Characteristics 3. Execution-biased Organization Culture.</strong></p> <p>&nbsp; &nbsp; &nbsp;- Organization culture mapping</p> <p>&nbsp; &nbsp; &nbsp;- Various 4.0 value statements</p> <p>&nbsp; &nbsp; - Values internalization strategy for the milenials</p> <p> <br> </p> <p> <br> </p>'
    },
    {
        date:'3 March 2020',
        time:'09.00-17.00',
        agenda:'<p><em><strong>Challenges of 4.0 Industrial Revolution and Impact on Organization Agility Five Characteristics of an Agile Organization.</strong></em></p> <p><strong><em>Overview GML Research in Organization Design</em></strong></p> <p> <br> </p> <p><strong>1. Agile Characteristic 1. Shared Purpose, Vision, and Strategy</strong></p> <p>&nbsp; &nbsp; &nbsp; - Practices of mission and vision in agile corporations</p> <p>&nbsp; &nbsp; &nbsp; - SPEx2 framework in cascading strategy into execution-</p> <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;oriented actions</p> <p><strong>2. Agile Characteristic 2. Rapid Decision Making and Development Process</strong></p> <p>&nbsp; &nbsp; &nbsp;- Value chain framework and Process Management Xcellenct&nbsp;</p> <p>&nbsp; &nbsp; &nbsp; &nbsp; (PMX)</p> <p>&nbsp; &nbsp; &nbsp;- LEAN-Startup methodology</p> <p>&nbsp; &nbsp; &nbsp;- Death of performance appraisal, rise of Continuous</p> <p>&nbsp; &nbsp; &nbsp; &nbsp;Performance Management</p> <p><strong>3. Agile Characteristics 3. Execution-biased Organization Culture.</strong></p> <p>&nbsp; &nbsp; &nbsp;- Organization culture mapping</p> <p>&nbsp; &nbsp; &nbsp;- Various 4.0 value statements</p> <p>&nbsp; &nbsp; - Values internalization strategy for the milenials</p> <p> <br> </p> <p> <br> </p>'
    }
]
export default class agenda extends Component {
    state={
        0:true,
    }
    toggleCollapse=(collapse)=>{
        this.setState({[collapse]:!this.state[collapse]})
    }
    render() {
        // const {collapse1,collapse2}=this.state
        const {event}=this.props
        console.log('agenda',event.agenda)
        return (
            <div>
                <br/>
                <div className='agenda'>
                    {event.agenda.map((agenda,i)=>(
                        <div className='agenda-item' key={i} onClick={()=>this.toggleCollapse(i)}>
                        <div className='item-head'>
                            <h3>Day {i+1}</h3>
                            &nbsp;&nbsp;&nbsp;
                            <div className='date-time'>
                                <div className='vr'></div>
                                &nbsp;&nbsp;&nbsp;
                                <p >{moment(agenda.date).format('D MMMM YYYY')}</p>
                                &nbsp;&nbsp;&nbsp;
                                <div className='vr'></div>
                                &nbsp;&nbsp;&nbsp;
                                <p >{agenda.startTime}-{agenda.endTime}</p>
                            </div>
                            <img onClick={()=>this.toggleCollapse(i)} src={chevron} className={this.state[i]?'chevron-collapse-active':'chevron-collapse'} />
                        </div>
                        <div className='border-bottom'></div>
                        <br/>
                        <Collapse in={this.state[i]}>
                            <div className='item-content' dangerouslySetInnerHTML={{__html:agenda.description}}></div>
                        </Collapse>
                        <br/>
                    </div>
                    ))}
                </div>
            </div>
        )
    }
}
