import * as actionTypes from "../constants/event";
import { apiCall } from "service/apiCall";
import { setLoading, setLoadingTable, setError, modalToggle } from "./general";
import { get } from "lodash";
import { URL, USER, PASS } from "service/base_url";
import {
  previewSetTitle,
  previewSetStartDate,
  previewSetEndDate,
  previewSetStartTime,
  previewSetEndTime,
  previewSetAddress,
  previewSetCategory,
  previewSetStatus,
  previewSetTarget,
  previewSetShortDesc,
  previewSetThumbnail,
  previewSetBrochure,
  previewSetDescription,
  previewSetAdditional,
  previewSetFramework,
  previewSetTestimony,
  previewSetAgenda,
  previewSetInvestment,
  previewSetSpeaker,
  previewSetIdEvent,
} from "./preview";
import moment from "moment";
import Cookie from "universal-cookie";
const cookie = new Cookie();
const token = cookie.get("login_cookie");

export const clearState = () => (dispatch) => {
  dispatch({
    type: actionTypes.CLEAR_STATE,
  });
};
export const setTitle = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_TITLE,
    payload: data,
  });
  dispatch(previewSetTitle(data));
};

export const setStartDate = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_START_DATE,
    payload: data,
  });
  dispatch(previewSetStartDate(data));
};
export const setEndDate = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_END_DATE,
    payload: data,
  });
  dispatch(previewSetEndDate(data));
};
export const setStartTime = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_START_TIME,
    payload: data,
  });
  dispatch(previewSetStartTime(data));
};
export const setEndTime = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_END_TIME,
    payload: data,
  });
  dispatch(previewSetEndTime(data));
};
export const setAddress = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ADDRESS,
    payload: data,
  });
  dispatch(previewSetAddress(data));
};
export const setCategory = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CATEGORY,
    payload: data,
  });
  dispatch(previewSetCategory(data));
};
export const setStatus = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_STATUS,
    payload: data,
  });
  dispatch(previewSetStatus(data));
};
export const setTarget = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_TARGET,
    payload: data,
  });
  dispatch(previewSetTarget(data));
};
export const setShortDesc = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SHORT_DESC,
    payload: data,
  });
  dispatch(previewSetShortDesc(data));
};
export const setThumbnail = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_THUMBNAIL,
    payload: data,
  });
  dispatch(previewSetThumbnail(data));
};
export const setBrochure = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_BROCHURE,
    payload: data,
  });
  dispatch(previewSetBrochure(data));
};
export const setFlyer = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_FLYER,
    payload: data,
  });
  // dispatch(previewSetBrochure(data))
};
export const setTakeAways = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_TAKE_AWAYS,
    payload: data,
  });
  // dispatch(previewSetBrochure(data))
};
export const setDescription = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_DESCRIPTION,
    payload: data,
  });
  dispatch(previewSetDescription(data));
};
export const setAdditional = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ADDITIONAL,
    payload: data,
  });
  dispatch(previewSetAdditional(data));
};
export const setFramework = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_FRAMEWORK,
    payload: data,
  });
  dispatch(previewSetFramework(data));
};
export const setTestimony = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_TESTIMONY,
    payload: data,
  });
  dispatch(previewSetTestimony(data));
};
export const setAgenda = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_AGENDA,
    payload: data,
  });
  dispatch(previewSetAgenda(data));
};
export const setInvestment = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_INVESTMENT,
    payload: data,
  });
  dispatch(previewSetInvestment(data));
};
export const setSpeaker = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SPEAKER,
    payload: data,
  });
  dispatch(previewSetSpeaker(data));
};
export const setAction = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ACTION,
    payload: data,
  });
};
export const setSearch = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SEARCH,
    payload: data,
  });
};

export const setMetaTitle = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_META_TITLE,
    payload: data,
  });
};
export const setSlug = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SLUG,
    payload: data,
  });
};
export const setMetaDesc = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_META_DESC,
    payload: data,
  });
};
export const setKeyword = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_KEYWORD,
    payload: data,
  });
};
export const setRegistrationUrl = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_REGISTRATION_URL,
    payload: data,
  });
};
export const setLinkZoom = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LINK_ZOOM,
    payload: data,
  });
};
export const setvideoURL = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_VIDEO_URL,
    payload: data,
  });
};
export const setEmailNotification = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_EMAIL_NOTIF,
    payload: data,
  });
};
export const setEmailSubject = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_EMAIL_SUBJECT,
    payload: data,
  });
};
export const setEmail = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_EMAIL,
    payload: data,
  });
};
export const setEventType = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_EVENT_TYPE,
    payload: data,
  });
};
export const setEventLocation = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_EVENT_LOCATION,
    payload: data,
  });
};
export const setTribe = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_TRIBE,
    payload: data,
  });
};
export const getCategory = () => async (dispatch) => {
  dispatch(setLoadingTable(true));
  let dataReq = {
    url: `/event/categories`,
    method: "GET",
    data: {
      auth: { username: USER, password: PASS },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") === 200) {
    dispatch(setLoadingTable(false));

    dispatch({
      type: actionTypes.GET_CATEGORY,
      payload: res.data,
    });
  } else {
    dispatch(setLoadingTable(false));
  }
};
export const getType = () => async (dispatch) => {
  dispatch(setLoadingTable(true));
  let dataReq = {
    url: `/event/topic`,
    method: "GET",
    data: {
      auth: { username: USER, password: PASS },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") === 200) {
    dispatch(setLoadingTable(false));

    dispatch({
      type: actionTypes.GET_TYPE,
      payload: res.data,
    });
  } else {
    dispatch(setLoadingTable(false));
  }
};
export const getLocation = () => async (dispatch) => {
  dispatch(setLoadingTable(true));
  let dataReq = {
    url: `/event/location`,
    method: "GET",
    data: {
      auth: { username: USER, password: PASS },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") === 200) {
    dispatch(setLoadingTable(false));

    dispatch({
      type: actionTypes.GET_LOCATION,
      payload: res.data,
    });
  } else {
    dispatch(setLoadingTable(false));
  }
};
export const addEvent = (token, payload, slugGet, key) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: "/event",
    method: "POST",
    data: {
      headers: { Authorization: `Bearer ${token}` },
      data: payload,
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    // dispatch(getCarrers(token))
    // alert(dataalert)
    dispatch(getEvent(slugGet, key));
    if (payload.publish === 1) {
      dispatch(setAction("see_event"));
      dispatch(tabToggle("published"));
    }
    dispatch({
      type: actionTypes.SET_ID,
      payload: res.data.event.id,
    });
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Add event succsess",
        modal_component: payload.publish === 1 ? "update_event" : "add_event",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>event have been successfully registered with the name <b>${res.data.event.title}</b></p> `,
          ...res.data,
        },
        modal_action: "success",
      })
    );
    dispatch(setLoading(false));
  } else {
    dispatch(setLoading(false));
  }
};

export const getEvent =
  (slug = "", key) =>
  async (dispatch) => {
    dispatch(setLoadingTable(true));
    let dataReq = {
      url: `/Event/intropage${slug}`,
      method: "GET",
      data: {
        auth: { username: USER, password: PASS },
      },
    };
    let res = await dispatch(apiCall(dataReq));
    if (get(res, "status") === 200) {
      dispatch(setLoadingTable(false));
      dispatch(setLoading(false));

      console.log("from action", res.data);

      dispatch({
        type:
          key === "drafted"
            ? actionTypes.GET_EVENT_DRAFTED
            : actionTypes.GET_EVENT_PUBLISHED,
        payload: res.data.events,
        pagination: res.data.info,
      });
    } else {
      dispatch(setLoadingTable(false));
    }
  };
export const getEventWithoutPaging =
  (slug = "") =>
  async (dispatch) => {
    dispatch(setLoadingTable(true));
    let dataReq = {
      url: `/event/list`,
      method: "GET",
      data: {
        auth: { username: USER, password: PASS },
      },
    };
    let res = await dispatch(apiCall(dataReq));
    if (get(res, "status") == 200) {
      console.log("res.data", res.data);
      let data_select = [];
      res.data.map((data) => {
        data_select.push({ label: data.text, value: data.id });
      });
      dispatch(setLoadingTable(false));
      dispatch({
        type: actionTypes.GET_EVENT_ALL,
        payload: data_select,
      });
    } else {
      dispatch(setLoadingTable(false));
    }
  };
export const unPublishEvent =
  (token, id, is_publish = 0, userId, slugGet, success_msg) =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let dataReq = {
      url: `/Event/publish/${id}/${is_publish}/${userId}`,
      method: "GET",
      data: {
        headers: { Authorization: `Bearer ${token}` },
      },
    };
    let res = await dispatch(apiCall(dataReq));
    if (get(res, "status") == 200) {
      console.log("success", success_msg);
      dispatch(getEvent(slugGet, "published"));
      dispatch(tabToggle("drafted"));
      dispatch(
        modalToggle({
          modal_open: true,
          modal_title: "Update event success",
          modal_component: "unpublish_event",
          modal_size: 400,
          modal_type: "alert",
          modal_data: {
            msg: `<p>Event  successfully updated</p> `,
            ...res.data,
          },
          modal_action: "success",
          success_msg: success_msg,
        })
      );
    } else {
      dispatch(setLoading(false));
    }
  };
export const getDetailEvent = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/${id}`,
    method: "GET",
    data: {
      auth: { username: USER, password: PASS },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoading(false));
    let { data } = res;
    let testimonies = [];
    let speaker = [];
    let investments = [];
    let agenda = [];
    data.testimonies.map((data, i) => {
      testimonies.push({
        id: data.id,
        file: null,
        name: data.name,
        title: data.title,
        company: data.company,
        testimony: data.testimony,
        file_url: data.photo.url,
      });
    });
    data.speakers.map((data, i) => {
      speaker.push({
        id: data.id,
        name: data.name,
        title: data.title,
        company: data.company,
        profile: data.profile,
        profileFileName: data.profileFilename,
      });
    });
    data.investments.map((data, i) => {
      investments.push({
        id: data.id,
        ppn: data.ppn ? 1 : 0,
        title: data.title,
        type: data.type,
        nominal: data.nominal,
        ppnpercent: data.ppnpercent,
      });
    });
    data.agenda.map((data, i) => {
      agenda.push({
        id: data.id,
        date: data.date,
        startTime: data.startTime,
        endTime: data.endTime,
        description: data.description,
      });
    });
    let thumbnail = {
      thumbnail_url: data.thumbnail.url,
      file_name: data.thumbnail.name,
      file: null,
    };
    console.log("thumbnail", thumbnail);
    let brochure = {
      file_name: data.brochure.name,
      file: null,
    };
    dispatch({
      type: actionTypes.SET_ID,
      payload: data.event.id,
    });
    // let keyword="baca,buku,nangis"
    let new_keyword = data.event.keyword.split(",");

    console.log("new_keyword", new_keyword);
    dispatch(setTitle(data.event.title));
    dispatch(setSlug(data.event.slug));
    dispatch(setMetaTitle(data.event.metaTitle));
    dispatch(setMetaDesc(data.event.metaDescription));
    dispatch(setKeyword(data.event.keyword !== "" ? new_keyword : []));
    dispatch(setShortDesc(data.event.intro));
    dispatch(setCategory(data.event.categoryId));
    dispatch(setStartDate(data.event.fromDate));
    dispatch(setEndDate(data.event.toDate));
    dispatch(
      setStartTime(
        data.event.startTime === ""
          ? null
          : moment(data.event.startTime, "hh:mm")
      )
    );
    dispatch(
      setEndTime(
        data.event.endTime === "" ? null : moment(data.event.endTime, "hh:mm")
      )
    );
    dispatch(setTarget(data.event.audience));
    dispatch(setAddress(data.event.address));
    dispatch(setLinkZoom(data.event.linkZoom));
    dispatch(setvideoURL(data.event.videoURL));
    dispatch(setStatus(data.event.publish));
    dispatch(setCategory(data.category.id));
    dispatch(setDescription(data.description));
    dispatch(setThumbnail(data.thumbnail.url !== null ? thumbnail : null));
    dispatch(setSpeaker(speaker));
    dispatch(setBrochure(data.brochure.url !== null ? brochure : null));
    dispatch(setAgenda(agenda));
    dispatch(setTestimony(testimonies));
    dispatch(setInvestment(investments));
    dispatch(setRegistrationUrl(data.event.registrationURL));
    dispatch(setEventType(data.event.topicId));
    dispatch(setEventLocation(data.event.locationId));
    dispatch(setTakeAways(data.takeaways));
    dispatch(
      setFlyer(
        data.flyer.name === null
          ? null
          : { file: "", file_name: data.flyer.name }
      )
    );
    dispatch(previewSetIdEvent(data.event.id));
    dispatch(setEmailNotification(data.emailNotification));
    dispatch(setEmailSubject(data.emailSubject));
    dispatch(setEmail(data.email));
    dispatch(setCdhxCategory(data.event.cdhxCategory));
    dispatch(setTribe(data.event.tribeId));

    // dispatch()
    return res;
    // console.log('res',res.data)
  } else {
    dispatch(setLoading(false));
  }
};

export const updateEvent =
  (token, payload, id, slugGet, key) => async (dispatch) => {
    dispatch(setLoading(true));
    let dataReq = {
      url: `/event/${id}`,
      method: "PUT",
      data: {
        headers: { Authorization: `Bearer ${token}` },
        data: payload,
      },
    };
    let res = await dispatch(apiCall(dataReq));
    if (get(res, "status") == 200) {
      dispatch(getEvent(slugGet, key));
      dispatch(setLoading(false));

      if (key === "published") {
        // alert('asdf')
        dispatch(setAction("see_event"));
        dispatch(tabToggle("published"));
      }

      // // console.log('key', key)
      console.log("update event", key, slugGet);
      dispatch(
        modalToggle({
          modal_open: true,
          modal_title: "Update event succsess",
          modal_component: key === "published" ? "update_event" : "add_event",
          modal_size: 400,
          modal_type: "alert",
          modal_data: {
            msg: `<p>event <b>${payload.title}</b> successfully updated </p> `,
            ...res.data,
          },
          modal_action: "success",
        })
      );
    } else {
      dispatch(setLoading(false));
    }
  };
export const deleteEvent =
  (token, id, userId, slugGet, key) => async (dispatch) => {
    dispatch(setLoading(true));
    let dataReq = {
      url: `/event/${id}/${userId}`,
      method: "DELETE",
      data: {
        headers: { Authorization: `Bearer ${token}` },
      },
    };
    let res = await dispatch(apiCall(dataReq));
    if (get(res, "status") == 200) {
      await dispatch(getEvent(slugGet, key));
      dispatch(
        modalToggle({
          modal_open: true,
          modal_title: "Event Deleted",
          modal_component: "delete_event",
          modal_size: 400,
          modal_type: "alert",
          modal_data: {
            msg: `<p>Event <b>${res.data.title}</b> successfully deleted in our system</p> `,
          },
          modal_action: "success",
          success_msg: "See event list",
        })
      );
    }
  };
export const setImage = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_IMAGE,
    payload: data,
  });
};
export const setCaption = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CAPTION,
    payload: data,
  });
};
export const setEventId = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_EVENT_ID,
    payload: data,
  });
};

export const getImageEvent =
  (slug = "/0/0/1/10", key) =>
  async (dispatch) => {
    dispatch(setLoadingTable(true));
    let dataReq = {
      url: `/event/image${slug}`,
      method: "GET",
      data: {
        auth: { username: USER, password: PASS },
      },
    };
    let res = await dispatch(apiCall(dataReq));
    if (get(res, "status") == 200) {
      dispatch({
        type:
          key === "drafted"
            ? actionTypes.GET_EVENT_IMAGE_DRAFTED
            : actionTypes.GET_EVENT_IMAGE_PUBLISHED,
        payload: res.data.images,
        pagination: res.data.info,
      });
      dispatch(setLoadingTable(false));
    } else {
      dispatch(setLoadingTable(false));
    }
  };
export const addImage = (token, payload, key) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: "/event/image",
    method: "POST",
    data: {
      headers: { Authorization: `Bearer ${token}` },
      data: payload,
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    await dispatch(
      getImageEvent(`/0/${key === "drafted" ? 0 : 1}/1/10/*`, key)
    );

    // alert(dataalert)
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Add image succsess",
        modal_component: "add_image",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>image have been successfully added </p> `,
          ...res.data,
        },
        modal_action: "success",
      })
    );
    dispatch(setLoading(false));
  } else {
    dispatch(setLoading(false));
  }
};

export const updateImage =
  (token, data, id, slugGet, key) => async (dispatch) => {
    dispatch(setLoading(true));
    let dataReq = {
      url: `/event/image/${id}`,
      method: "PUT",
      data: {
        headers: { Authorization: `Bearer ${token}` },
        data,
      },
    };
    let res = await dispatch(apiCall(dataReq));
    if (get(res, "status") == 200) {
      await dispatch(getImageEvent(slugGet, key));
      dispatch(
        modalToggle({
          modal_open: true,
          modal_title: "Update image success",
          modal_component: "update_image",
          modal_size: 400,
          modal_type: "alert",
          modal_data: {
            msg: `<p>Image successfully updated</p> `,
            ...data,
          },
          modal_action: "success",
        })
      );
    }
  };

export const deleteImage = (token, id, userId, key) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/image/${id}/${userId}`,
    method: "DELETE",
    data: {
      headers: { Authorization: `Bearer ${token}` },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    await dispatch(
      getImageEvent(`/0/${key === "drafted" ? 0 : 1}/1/10/*`, key)
    );
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Image Deleted",
        modal_component: "delete_image",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Image successfully deleted in our system</p> `,
        },
        modal_action: "success",
      })
    );
  }
};
export const tabToggle = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.TOGGLE_TAB,
    payload: data,
  });
};

export const getImageEventList = (id, key) => async (dispatch) => {
  dispatch(setLoadingTable(true));
  let dataReq = {
    url: `/event/eventimages/${id}/${key ? 1 : 0}`,
    method: "GET",
    data: {
      auth: { username: USER, password: PASS },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoadingTable(false));
    dispatch({
      type: actionTypes.GET_EVENT_IMAGE_ALL,
      payload: res.data,
    });
  } else {
    dispatch(setLoadingTable(false));
  }
};

export const addCDHXCategory = (token, data) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/Event/cdhx/categories`,
    method: "POST",
    data: {
      headers: { Authorization: `Bearer ${token}` },
      data,
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 204) {
    dispatch(setLoading(false));
    dispatch(getCDHXCategory());
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Update Category",
        modal_component: "",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Category successfull updated</p> `,
          ...data,
        },
        modal_action: "success",
      })
    );
  }
};
export const getCDHXCategory = () => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/Event/cdhx/categories`,
    method: "GET",
    data: {
      auth: { username: USER, password: PASS },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoading(false));
    dispatch({
      type: actionTypes.GET_CDHX_CATEGORY,
      payload: res.data,
    });
  } else {
    dispatch(setLoading(false));
  }
};
export const setCdhxCategory = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CDHX_CATEGORY,
    payload: data,
  });
};

export const getTribes = () => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/pipeline/master`,
    method: "GET",
    data: {
      headers: { Authorization: `Bearer ${token}` },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoading(false));

    dispatch({
      type: actionTypes.GET_TRIBES,
      payload: res.data.tribes,
    });
  } else {
    dispatch(setLoading(false));
  }
};
