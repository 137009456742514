import React, { Component } from 'react'
import {Button} from '@material-ui/core'
import {modalToggle} from 'redux/actions/general'
import {connect} from 'react-redux'
import Edit from 'assets/icon/edit.svg'
import Close from 'assets/icon/close.svg'
import { setAgenda} from 'redux/actions/event'
import moment from 'moment'
class agenda extends Component {
    addAgenda=()=>{
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "Agenda",
            modal_component: "agenda",
            modal_data:null ,
            modal_size:720,
            modal_action:'add_agenda'
        })
    }
    editAgenda=(data,i)=>{
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "Agenda",
            modal_component: "agenda",
            modal_data:{...data,index:i} ,
            modal_size:460,
            modal_action:'edit_agenda'

        })
    }
    deleteAgenda=(id,i,plus1)=>{
        const {event}=this.props
        let new_agenda=[]
        // let agenda=event.agenda.filter((data)=>{
        //     return data.id!==id
        // })
        // agenda.map((data,i)=>{
        //     new_agenda.push({id:i,date:data.date,startTime:data.startTime,endTime:data.endTime,description:data.description})
        // })
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: `Agenda`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:`agenda day ${plus1}`,
                // agenda:new_agenda,
                index:i,
                msg:`<p>Are you sure delete <b>agenda day ${plus1}</b></p>`
            },
            modal_action:'delete_agenda'
        })
       
        // this.props.setAgenda(new_agenda)
    }
    render() {
        const {agenda}=this.props.event
        return (
            <div>
                <p className='bold'>List agenda <span className='semi-bold'>(Optional)</span></p>
                <br/>
                {agenda.length>0&&agenda.map((data,i)=>(
                    <div key={i}>
                        <div className='card-content padding-10'>
                            <div className='card-agenda-header'>
                                <div>
                                    <p style={{margin:0,color:'#777777',fontSize:14,fontWeight:600}}>Day {i+1} &nbsp;&nbsp;&nbsp; {moment(data.date).format('D MMM')} &nbsp;&nbsp;&nbsp;{data.startTime}-{data.endTime}</p>
                                </div>
                                <div>
                                        <img src={Edit} className='icon-size-20' onClick={()=>this.props.handleDisable()?null:this.editAgenda(data,i)}/>
                                        <img src={Close} className='icon-size-20' onClick={()=>this.props.handleDisable()?null:this.deleteAgenda(data.id,i,i+1)}/>
                                </div>
                            </div>
                            <div className='card-agenda-body'>
                                <div dangerouslySetInnerHTML={{ __html:data.description }}/>
                            </div>
                        </div>
                        <br/>
                    </div>
                ))}
                
                <Button onClick={this.addAgenda} disabled={this.props.handleDisable()}  size='small' color='secondary' variant='contained' className='head-section__btn'>Add agenda</Button>
            </div>
        )
    }
}

const mapStateToProps=(state)=>({
    general:state.general,
    event:state.event
})
const mapDispatchToProps={
    modalToggle,
    setAgenda
}
export default connect(mapStateToProps,mapDispatchToProps)(agenda)
