import React, { Component } from 'react'
import {FormControl,InputLabel,OutlinedInput,Button} from '@material-ui/core'
import image_line from 'assets/icon/image_line.svg'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
import {handleFile,getBase64} from 'components/handleFile'
import {connect} from 'react-redux'
import {modalToggleReset} from 'redux/actions/general'
import {postBanner,putBanner} from 'redux/actions/home'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#4CAF50',
        },
        secondary:{
            main:'#f44336'
        }
    } 
})
class testimony extends Component {
    state={
        bannerId:0,
        file_error:null,
        file_url:null,
        file_name:null,
        file:null,
        file_error_mobile:null,
        file_url_mobile:null,
        file_name_mobile:null,
        file_mobile:null,
        link:'',
        title:'',
        description:''
    }
    componentWillMount(){
        const {modal_data}=this.props.general
        console.log('modal',modal_data)
        if(modal_data!==null){
            this.setState({
                file:null,
                file_url:modal_data.url,
                link:modal_data.link,
                bannerId:modal_data.bannerId,
                title:modal_data.title,
                description:modal_data.description,
                file_url_mobile:modal_data.mobileURL
            })
        }
    }
    onChange=(e)=>{
        this.setState({[e.target.name]:e.target.value})
    }
    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    handleTestimony=(evt,device)=>{
        let handle= handleFile(evt)
        if(handle.file_error===null){
            let file_url=URL.createObjectURL(handle.file)
            // getBase64(handle.file,(result)=>{
                

            // })
            if(device==="desktop"){
                this.setState({file_url,file:handle.file,file_error:null,file_name:handle.file_name})

            }else{
                this.setState({file_url_mobile:file_url,file_mobile:handle.file,file_error_mobile:null,file_name_mobile:handle.file_name})

            }
        }else{
            console.log('err',handle)
            // if()
            this.setState({file_error:handle.file_error})
        }
      
    }
    deleteImage=()=>{
        this.setState({file_error:null,file_url:null,file:null,file_error_mobile:null,file_url_mobile:null,file_mobile:null})
    }
    
    addBanner=()=>{
        let fd= new FormData()
        fd.append('BannerId',this.state.bannerId)
        fd.append('image',this.state.file)
        fd.append('mobileImage',this.state.file_mobile)
        fd.append('link',this.state.link)
        fd.append('title',this.state.title)
        fd.append('description',this.state.description)
        fd.append('publish',1)
        fd.append('userId',this.props.profile.id)
        this.props.postBanner(this.props.token,fd)
        // this.props.modalToggleReset()
    }
    updateBanner=()=>{
        let fd= new FormData()
        fd.append('BannerId',this.state.bannerId)
        fd.append('image',this.state.file)
        fd.append('mobileImage',this.state.file_mobile)
        fd.append('link',this.state.link)
        fd.append('title',this.state.title)
        fd.append('description',this.state.description)
        fd.append('publish',1)
        fd.append('userId',this.props.profile.id)
        this.props.putBanner(this.props.token,fd)
        // this.props.modalToggleReset()

    }
    handleDisable=()=>{
        if(this.state.file_url!==null&&this.state.file_url_mobile!==null){
            return false
        }else{
            return true
        }
    }
    render() {
        const {file,name,file_error,file_url,file_url_mobile,link,title,description}=this.state
        const {modal_action}=this.props.general

        return (
            <div className='testimony-modal-wrapper'>
                
               <div className='card-container'>
                   <div>
                    <p className='bold' style={{marginBottom:5}}>Image for Desktop</p>
                   {file_url!==null?
                        <img src={file_url} style={{width:230,marginRight:20,height:70,borderRadius:10,objectFit:'cover'}}/>
                   :
                    <div className='square-image-banner'>
                        <MuiThemeProvider theme={themeButton}>
                        <Button
                            className='btn-remove-capital'
                            variant='text'
                            startIcon={<img src={image_line}/>}
                            color='primary'
                            component="label"
                        >
                           Add Image here 1260x574
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={(e)=>this.handleTestimony(e,'desktop')}
                            />
                        </Button>
                        </MuiThemeProvider>
                    </div>
                    }
                    <p className='bold' style={{marginBottom:5,marginTop:5}}>Image for Mobile</p>
                   {file_url_mobile!==null?
                        <img src={file_url_mobile} style={{width:230,marginRight:20,height:70,borderRadius:10,objectFit:'cover'}}/>
                   :
                    <div className='square-image-banner'>
                        <MuiThemeProvider theme={themeButton}>
                        <Button
                            className='btn-remove-capital'
                            variant='text'
                            startIcon={<img src={image_line}/>}
                            color='primary'
                            component="label"
                        >
                            Add Image here 339x419
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={(e)=>this.handleTestimony(e,'mobile')}
                            />
                        </Button>
                        </MuiThemeProvider>
                    </div>
                    }
                    </div>
                    {file_error!==null&&<p style={{color:'red',fontSize:10}}>{file_error}</p>}
                    <div className='testimony-form'>
                        
                        <FormControl variant="outlined" size="small"  className='add-investment__field'>
                            <InputLabel  htmlFor="date">Link</InputLabel>
                            <OutlinedInput
                                id="link"
                                type='text'
                                name='link'
                                value={link}
                                onChange={this.onChange}
                                required
                                labelWidth={40}
                                className='field-radius'
                            />
                        </FormControl>
                        <FormControl variant="outlined" size="small"  className='add-investment__field'>
                            <InputLabel  htmlFor="date">Title</InputLabel>
                            <OutlinedInput
                                id="title"
                                type='text'
                                name='title'
                                value={title}
                                onChange={this.onChange}
                                required
                                labelWidth={40}
                                className='field-radius'
                            />
                        </FormControl>
                        <FormControl variant="outlined" size="small"  className='add-investment__field'>
                            <InputLabel  htmlFor="date">Description</InputLabel>
                            <OutlinedInput
                                id="description"
                                type='text'
                                name='description'
                                value={description}
                                onChange={this.onChange}
                                required
                                labelWidth={80}
                                className='field-radius'
                                multiline
                            />
                        </FormControl>
                        

                    </div>
                </div>
                <div className='card-event-footer'>
                    <div>
                    <MuiThemeProvider theme={themeButton}>
                        <Button
                            className='btn-remove-capital'
                            variant='text'
                            color='secondary'
                            onClick={this.deleteImage}
                            style={{padding:0}}
                        >
                            Delete image
                        </Button>
                    </MuiThemeProvider>
                    <p className='semi-bold'>For better banner use image with for desktop 1260x574
and for mobile 339x419</p>

                    </div>
                    
                    {modal_action==='add_banner'?
                    <Button disabled={this.handleDisable()} onClick={this.addBanner} size='small' color='primary' variant='contained' className='head-add-section__btn '>Add</Button>
                    :
                    <Button disabled={this.handleDisable()} onClick={this.updateBanner}  size='small' color='primary' variant='contained' className='head-add-section__btn'>Update</Button>
                    }
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    event:state.event,
    general:state.general
})
const mapDispatchToProps={
    postBanner,
    modalToggleReset,
    putBanner
}
export default connect(mapStateToProps,mapDispatchToProps)(testimony)