import React, { Component } from 'react'
import './style.css'
import Banner1 from './banner/banner1'
import Banner2 from './banner/banner2'
import {Button,Tab,Tabs } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
import {connect} from 'react-redux'
import NumberFormat from 'react-number-format';
import Information from './information'
import Agenda from './agenda'
import Registration from './registration'
import Documentation from './documentation'
import DownloadBlue from 'assets/icon/Download-blue.svg'
import {groupBy} from 'lodash'
import {getImageEventList} from 'redux/actions/event'
import moment from 'moment'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00A1ED',
            contrastText: '#FFFFFF',
        },
    } 
})
class detail extends Component {
    state={
        tabs_active:'information',
        tabs_mobile_active:0,
        open:false
    }
    componentWillMount(){
        const {preview}=this.props
        let date=moment(preview.start_date).subtract(1, 'days').format('YYYY-MM-DD')
        let now=moment().format('YYYY-MM-DD')
        let is_open=moment(date).isSameOrAfter(now)
        this.setState({open:is_open})
        if(preview.event_id!==""){
            this.props.getImageEventList(preview.event_id,preview.status)
        }
        
    }
    tabsToggle=(key)=>{
        this.setState({tabs_active:key})
    }
    tabsMobileToggle=(tabs,key)=>{
        this.setState({tabs_mobile_active:key})
    }
    a11yProps=(index)=> {
        return {
          id: `scrollable-auto-tab-${index}`,
          'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
      }
    renderIvestment=(data)=>{
        const event=this.props.preview
        let result=[]
        console.log('invest',event.investment)
        let group=groupBy(event.investment,(data)=>{
            return data.title
        })
        let investment=Object.entries(group)
        let cekdiskon=[]
        let a=investment.map((data,i)=>{
            let havediscount=false
            let cek=data[1].filter(data=>{
                return data.type==='Discount Price'
            })
            
            if(cek.length>0){
                havediscount=true
            }
            cekdiskon.push({...data,havediscount})
        })
        let map=cekdiskon.map((data,i)=>(
            <div key={i}>
                <div className={`investment-title${data[0]==='Free'?'-free':''}`}>
                <p>{data[0]}</p>
                </div>
                {/* {data[0]!=='Free'? */}
                <div className='sub-investment'>
                    {data.havediscount?
                        <>
                        {data[1].map((subdata,i)=>(
                            <div style={{marginBottom:10}}>
                                {subdata.type==='Normal Price'&&
                                <p  style={{color:'#cccccc'}}>Normal Prices :<strike style={{color:'red'}}><span style={{color:'#cccccc'}}><NumberFormat prefix={'Rp'} value={subdata.nominal} displayType={'text'} thousandSeparator={true}  /> {subdata.ppn&&`+ PPn ${subdata.ppnpercent}%`}</span></strike> </p>
                                }
                            </div>
                        ))}
                        {data[1].map((subdata,i)=>(
                            <div style={{marginBottom:10}}>
                                {subdata.type==='Discount Price'&&
                                <p>Now only : <NumberFormat value={subdata.nominal} displayType={'text'} thousandSeparator={true}  prefix={'Rp'}/> {subdata.ppn&&`+ PPn ${subdata.ppnpercent}%`}</p>
                                }
                            </div>
                        ))}
                        </>
                    :
                        data[1].map((subdata,i)=>(
                            <div style={{marginBottom:10}}>
                                
                                {subdata.type!=='Free'&&<p>{subdata.type} : <NumberFormat value={subdata.nominal} displayType={'text'} thousandSeparator={true}  prefix={'Rp'}/> {subdata.ppn&&`+ PPn ${subdata.ppnpercent}%`}</p>}
                                
                            </div>
                        ))
                    }
                    {/* {data[1].map((subdata,i)=>(
                        data.havediscount && subdata.type==='Normal Price'&&
                        <div style={{marginBottom:10}}>
                            <p  style={{color:'#cccccc'}}>Normal Prices :<strike style={{color:'red'}}><span style={{color:'#cccccc'}}><NumberFormat prefix={'Rp'} value={subdata.nominal} displayType={'text'} thousandSeparator={true}  /> {subdata.ppn&&`+ PPn ${subdata.ppnpercent}%`}</span></strike> </p>
                                
                        </div>
    
                    ))}
                    {data[1].map((subdata,i)=>(
                        <div style={{marginBottom:10}}>
                              {subdata.type!=='Free'&&subdata.type!=='Discount Price'&&
                                <p>{data.havediscount?'Now only':subdata.type} : <NumberFormat value={subdata.nominal} displayType={'text'} thousandSeparator={true}  prefix={'Rp'}/> {subdata.ppn&&`+ PPn ${subdata.ppnpercent}%`}</p>
                               }
                        </div>
                    ))} */}
                    
                </div>
                {/* :null} */}
            </div>
        ))
        return map
    }
    render() {
        const {tabs_active,tabs_mobile_active,photoIndex,open}=this.state
        const event=this.props.preview
        const eventprops=this.props.event
        console.log('cookie', event)
        return (
            <div >
                <div className='previeww-mode'>
                    Preview mode
                </div>
                {event.category.toString()==='3'||event.category.toString()==='4'?<Banner2 event={event}/>:<Banner1 event={event}/>}
                <div className={`detail-content ${event.category.toString()==='1'||event.category.toString()==='2'?'margin-top-event':'margin-top-webinar'}`}>
                    <div className='detail-content-description'>
                        <MuiThemeProvider theme={themeButton}>
                            <div className='tab'>
                                <Button onClick={()=>this.tabsToggle('information')} color='primary' className='btn-remove-capital btn-tab' size='small' variant={tabs_active==='information'?'outlined':''}>Information</Button>
                                {event.agenda.length>0?<Button onClick={()=>this.tabsToggle('agenda')} color='primary' className='btn-remove-capital btn-tab' size='small' variant={tabs_active==='agenda'?'outlined':''}>Agenda</Button>:null}
                                {open&&<Button onClick={()=>this.tabsToggle('registration')} color='primary' className='btn-remove-capital btn-tab' size='small' variant={tabs_active==='registration'?'outlined':''}>Registration</Button>}
                                {eventprops.list_image_event_all!==null&&event.category.toString()!=='3'?eventprops.list_image_event_all.length>0&&<Button onClick={()=>this.tabsToggle('documentation')} color='primary' className='btn-remove-capital btn-tab' size='small' variant={tabs_active==='documentation'?'outlined':''}>Documentation</Button>:null}
                            </div>
                        </MuiThemeProvider>
                        {tabs_active==='information'&&<Information event={event}/>}
                        {tabs_active==='agenda'&&<Agenda event={event}/>}
                        {tabs_active==='registration'&&<Registration event={event}/>}
                        {tabs_active==='documentation'&&<Documentation  event={event}/>}
                    </div>
                    {event.category.toString()==='1'||event.category.toString()==='3'||event.category.toString()==='4'?
                    <div>
                    <div className='detail-content-investment'>
                        <div className='investment-head'>
                            <h3>Investment</h3>
                        </div>
                        <div className='investment-body'>
                            {this.renderIvestment()}
                            
                            <br/>
                           <MuiThemeProvider theme={themeButton}>
                            
                           {event.category.toString()!=='3'&&event.category.toString()!=='4'?
                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                    <Button style={{textTransform:'none'}} startIcon={<img src={DownloadBlue} style={{width:15}}/>} size="small" color="primary" onClick={()=>this.toggleRequest(event.title)}>Download brochure</Button>
                                    {open&&<Button style={{textTransform:'none'}} variant='contained' size="small" color="primary" onClick={()=>this.tabsToggle('registration')}>Registration</Button>}
                                </div>
                           :
                                <div style={{display:'flex',justifyContent:'flex-end'}}>
                                    {open&&<Button style={{textTransform:'none'}} variant='contained' size="small" color="primary" onClick={()=>this.tabsToggle('registration')}>Registration</Button>}
                                </div>
                           }
                           </MuiThemeProvider>
                        </div>
                    </div>
                    </div>
                    :null}
                </div>

                    <MuiThemeProvider theme={themeButton}>
                        <div className='tab-mobile'>
                            {/* <Tabs
                                value={tabs_mobile_active}
                                onChange={(tabs,key)=>console.log('tabs',tabs,key)}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                {menu.map((data,i)=>(
                                    <Tab label={data.title} {...a11yProps(data.id)} />
                                ))}
                                <Tab label="Information" {...a11yProps(0)} />
                                {event.detail_event.category.id!==3&&event.detail_event.category.id!==4&&<Tab label="Agenda" {...a11yProps(1)} />}
                                {handleOpen()&&<Tab label="Registration" {...a11yProps(2)} />}
                                {event.image_event.length>0&&<Tab label="Documentation" {...a11yProps(3)} />}
                            </Tabs> */}
                            <li onClick={()=>this.tabsToggle('information')} className={`tab-mobile-list ${tabs_active==='information'&&'tab-mobile-list_active'}`}>INFORMATION</li>
                            {event.agenda.length>0&&<li onClick={()=>this.tabsToggle('agenda')} className={`tab-mobile-list ${tabs_active==='agenda'&&'tab-mobile-list_active'}`}>AGENDA</li>}
                            {open&&<li onClick={()=>this.tabsToggle('registration')} className={`tab-mobile-list ${tabs_active==='registration'&&'tab-mobile-list_active'}`}>REGISTRATION</li>}
                            {eventprops.list_image_event_all!==null&&<li onClick={()=>this.tabsToggle('documentation')} className={`tab-mobile-list ${tabs_active==='documentation'&&'tab-mobile-list_active'}`}>DOCUMENTATION</li>}
                        </div>  
                    </MuiThemeProvider>
                    <div className='detail-content-mobile'>
                    {tabs_active==='information'&&<Information event={event}/>}
                        {tabs_active==='agenda'&&<Agenda event={event}/>}
                        {tabs_active==='registration'&&<Registration event={event}/>}
                        {tabs_active==='documentation'&&<Documentation  event={event}/>}
                        
                        
                    </div>
                    <div className='invest-mobile'>
                    <div className='investment-head '>
                        <h3>Investment</h3>
                    </div>
                    {event.category.toString()!=='2'&&this.renderIvestment()}
                    </div>
                </div>
        )
    }
}
const mapStateToProps=(state)=>({
    preview:state.preview,
    event:state.event,
})
const mapDispatchToProps={
    getImageEventList
}
export default connect(mapStateToProps,mapDispatchToProps)(detail)