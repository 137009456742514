export const SET_TITLE = "SET_TITLE";
export const SET_DATE = "SET_DATE";
export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";
export const SET_START_TIME = "SET_START_TIME";
export const SET_END_TIME = "SET_END_TIME";
export const SET_ADDRESS = "SET_ADDRESS";
export const SET_CATEGORY = "SET_CATEGORY";
export const SET_STATUS = "SET_STATUS";
export const SET_TARGET = "SET_TARGET";
export const SET_THUMBNAIL = "SET_THUMBNAIL";
export const SET_BROCHURE = "SET_BROCHURE";
export const SET_FLYER = "SET_FLYER";
export const SET_DESCRIPTION = "SET_DESCRIPTION";
export const SET_ADDITIONAL = "SET_ADDITIONAL";
export const SET_FRAMEWORK = "SET_FRAMEWORK";
export const SET_TESTIMONY = "SET_TESTIMONY";
export const SET_AGENDA = "SET_AGENDA";
export const SET_INVESTMENT = "SET_INVESTMENT";
export const SET_SPEAKER = "SET_SPEAKER";
export const SET_SHORT_DESC = "SET_SHORT_DESC";
export const GET_EVENT_DRAFTED = "GET_EVENT_DRAFTED";
export const GET_EVENT_PUBLISHED = "GET_EVENT_PUBLISHED";
export const GET_EVENT_ALL = "GET_EVENT_ALL";
export const SET_ACTION = "SET_ACTION";
export const SET_PAGINATION = "SET_PAGINATION";
export const SET_ID = "SET_ID";

export const SET_META_TITLE = "SET_META_TITLE";
export const SET_SLUG = "SET_SLUG";
export const SET_META_DESC = "SET_META_DESC";
export const SET_KEYWORD = "SET_KEYWORD";

export const TOGGLE_TAB = "TOGGLE_TAB";

export const SET_IMAGE = "SET_IMAGE";
export const SET_CAPTION = "SET_CAPTION";
export const SET_EVENT_ID = "SET_EVENT_ID";
export const GET_EVENT_IMAGE_DRAFTED = "GET_EVENT_IMAGE_DRAFTED";
export const GET_EVENT_IMAGE_PUBLISHED = "GET_EVENT_IMAGE_PUBLISHED";
export const GET_EVENT_IMAGE_ALL = "GET_EVENT_IMAGE_ALL";
export const CLEAR_STATE = "CLEAR_STATE";
export const SET_SEARCH = "SET_SEARCH";
export const SET_REGISTRATION_URL = "SET_REGISTRATION_URL";
export const SET_LINK_ZOOM = "SET_LINK_ZOOM";
export const SET_VIDEO_URL = "SET_VIDEO_URL";
export const SET_EVENT_TYPE = "SET_EVENT_TYPE";
export const SET_EVENT_LOCATION = "SET_EVENT_LOCATION";

export const GET_CATEGORY = "GET_CATEGORY";
export const GET_LOCATION = "GET_LOCATION";
export const GET_TYPE = "GET_TYPE";
export const SET_TAKE_AWAYS = "SET_TAKE_AWAYS";
export const SET_EMAIL_NOTIF = "SET_EMAIL_NOTIF";
export const SET_EMAIL_SUBJECT = "SET_EMAIL_SUBJECT";
export const SET_EMAIL = "SET_EMAIL";

export const GET_CDHX_CATEGORY = "GET_CDHX_CATEGORY";
export const SET_CDHX_CATEGORY = "SET_CDHX_CATEGORY";

export const SET_TRIBE = "SET_TRIBE";
export const GET_TRIBES = "GET_TRIBES";
