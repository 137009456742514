import * as actionTypes from "../constants/cdhx";

const initialState = {
  participant: "",
  banner_image: [],
  banner_pagination: null,
  course_list: [],
  alumn_list: [],
  alumn_pagination: null,
  expert_list: [],
  expert_pagination: null,
  client: [],

  course_id: null,

  alumni_detail: {
    id: 0,
    clientId: null,
    eventId: null,
    name: "",
    email: "",
    phone: "",
    department: "",
    jobTitle: "",
    events: [],
  },

  alumni_upload: {
    eventId: null,
    Filename: "",
    FileBase64: "",
    experts: [],
  },

  alumni_email: {
    alumni: [],
    email: "",
    emailSubject: "",
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_ALUMNI_UPLOAD:
      return {
        ...state,
        alumni_upload: {
          eventId: null,
          Filename: "",
          FileBase64: "",
          experts: [],
        },
      };
    case actionTypes.SET_PARTICIPANT:
      return {
        ...state,
        participant: action.payload,
      };
    case actionTypes.SET_ALUMNI_DETAIL:
      return {
        ...state,
        alumni_detail: {
          ...state.alumni_detail,
          [Object.keys(action.payload)]: Object.values(action.payload)[0],
        },
      };
    case actionTypes.SET_ALUMNI_EMAIL:
      return {
        ...state,
        alumni_email: {
          ...state.alumni_email,
          [Object.keys(action.payload)]: Object.values(action.payload)[0],
        },
      };
    case actionTypes.SET_ALUMNI_UPLOAD:
      return {
        ...state,
        alumni_upload: {
          ...state.alumni_upload,
          [Object.keys(action.payload)]: Object.values(action.payload)[0],
        },
      };
    case actionTypes.SET_COURSE_ID:
      return {
        ...state,
        course_id: action.payload,
      };
    case actionTypes.GET_CLIENT:
      return {
        ...state,
        client: action.payload,
      };
    case actionTypes.GET_COURSE:
      return {
        ...state,
        course_list: action.payload,
      };
    case actionTypes.GET_ALUMN:
      return {
        ...state,
        alumn_list: action.payload.alumni,
        alumn_pagination: action.payload.pagination,
      };
    case actionTypes.GET_EXPERT:
      return {
        ...state,
        expert_list: action.payload.expert,
        expert_pagination: action.payload.pagination,
      };
    case actionTypes.SET_BANNER_IMAGE:
      return {
        ...state,
        banner_image: action.payload,
      };
    case actionTypes.SET_BANNER_PAGINATION:
      return {
        ...state,
        banner_pagination: action.payload,
      };

    default:
      return state;
  }
};
