import { combineReducers } from "redux";
import general from "./general";
import careers from "./careers";
import event from "./event";
import preview from "./preview";
import home from "./home";
import cdhx from "./cdhx";
import speaker from "./speaker";
export default combineReducers({
  general,
  careers,
  event,
  preview,
  home,
  cdhx,
  speaker,
});
