import React ,{useState,useEffect}from 'react'
import { TextField,Button } from '@material-ui/core'
import InputMask from 'react-input-mask'
import AutoCompleteSelect from 'components/Select'
import { useDispatch,useSelector } from 'react-redux'
import { setAlumniUpload } from 'redux/actions/cdhx'
import { modalToggle } from 'redux/actions/general'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,
      },
      width:'100%',
      marginBottom:15 
  }
  
}));
export default function Alumni(props) {
    const dispatch = useDispatch()
    const cdhx = useSelector(state => state.cdhx)
    const {modal_data,modal_action}=props
    const [textSelect,setTextSelect]=useState('Type company name')
    const [state, setstate] = useState({
        name:'',address:''
    })
    useEffect(() => {
        if(modal_data!==null){
            // console.log(`modal_data`, modal_data)
            let {data}=modal_data
            setstate({name:data.name,address:data.address})
        }
    }, [])
    const classes=useStyles()
    const onChange=(e)=>{
        let {name,value}=e.target
        setstate({...state,[name]:value})
    }
    const save=()=>{
        if(modal_action==='add_expert'){
            let new_expert=[...cdhx.alumni_upload.experts,{name:state.name,address:state.address}]
            dispatch(setAlumniUpload({experts:new_expert}))
        }else{
            cdhx.alumni_upload.experts[modal_data.i]=state
            dispatch(setAlumniUpload({experts:cdhx.alumni_upload.experts}))

        }
        
        dispatch(modalToggle({
            modal_open: true,
            modal_title: "Upload Alumni",
            modal_component: "upload_alumni",
            modal_size:400,
            modal_data:null,
            modal_action:'upload_alumni',
            modal_type:'multi'

        }))
    }
    const handleDisable=()=>{
        if(state.name===''||state.address===''){
            return true
        }else{
            return false
        }
    }
    return (
        <div>
            {/* <p className='bold'>Please fill out the form below to join the course</p>
            <br/> */}
            <TextField
                label={<>Name </>}
                variant='outlined'
                value={state.name}
                size='small'
                // disabled={handleDisable()}
                name="name"
                className={classes.textField}
                onChange={(e)=>onChange(e)}

            />
            
            <TextField
                label={<>Email </>}
                variant='outlined'
                value={state.address}
                size='small'
                // disabled={handleDisable()}
                name="address"
                className={classes.textField}
                onChange={(e)=>onChange(e)}

            />
        <div style={{textAlign:'right'}}>
        <Button disabled={handleDisable('')} onClick={save}   size='small' color='primary' variant='contained' className='btn-remove-capital btn-rounded'>{modal_action==='add_expert'?'Save':'Update'}</Button>

        </div>
            <br/>
        </div>
    )
}
