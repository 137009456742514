import React, { Component } from 'react'
import {Button,Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
import Edit from 'assets/icon/edit.svg'
import Close from 'assets/icon/close.svg'
import {connect} from 'react-redux'
import {modalToggle} from 'redux/actions/general'
class speaker extends Component {
    addSpeaker=()=>{
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "Speaker",
            modal_component: "speaker",
            modal_data:null ,
            modal_size:350,
            modal_action:'add_speaker'
        })
    }
    editSpeaker=(data,index)=>{
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "Speaker",
            modal_component: "speaker",
            modal_data:{...data,index:index} ,
            modal_size:350,
            modal_action:'edit_speaker'

        })

    }
    deleteSpeaker=(id,i,plus1,data)=>{
        const {event}=this.props
        let new_speaker=[]
        // let speaker=event.speaker.filter((data)=>{
        //     return data.id!==id
        // })
        // speaker.map((data,i)=>{
        //     new_speaker.push({id:i,name:data.name,title:data.title,company:data.company})
        // })
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: `Speaker`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:`speaker ${data.name}`,
                index:i,
                // speaker:new_speaker,
                msg:`<p>Are you sure delete <b>speaker ${data.name}</b></p>`
            },
            modal_action:'delete_speaker'
        })
       
        // this.props.setAgenda(new_agenda)
    }
    render() {
        const {event}=this.props
        console.log(`event.speaker`, event.speaker)

        return (
            <div>
                 <div className='subcontent-container'>
                    <p className='bold'>List speaker</p>
                    <Button onClick={this.addSpeaker} disabled={this.props.handleDisable()}  size='small' color='secondary' variant='contained' className='head-section__btn'>
                        Add new speaker
                    </Button>
                </div>
                <br/>
                <Table  size="small" aria-label="a dense table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="left">Speaker name</TableCell>
                        <TableCell align="left">Speaker job title</TableCell>
                        <TableCell align="left">Company</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {event.speaker.length>0&&event.speaker.map((data,i)=>(
                            <TableRow>
                            <TableCell align="left">{data.name}</TableCell>
                                <TableCell align="left">{data.title}</TableCell>
                                <TableCell align="left">{data.company}</TableCell>
                                <TableCell align="right">
                                    <img src={Edit} onClick={()=>this.props.handleDisable()?null:this.editSpeaker(data,i)} className='icon-action'/>
                                    <img src={Close} onClick={()=>this.props.handleDisable()?null:this.deleteSpeaker(data.id,i,i+1,data)} className='icon-action'/>
                                </TableCell>
                            </TableRow>
                        ))}
                        {event.speaker.length<1&&
                        <TableRow>
                            <TableCell colSpan={5} style={{textAlign:'center'}}>
                            <Button disabled={this.props.handleDisable()} color='secondary' size='small' className='btn-remove-capital' onClick={this.addSpeaker}>Add speaker</Button>
                            </TableCell>
                        </TableRow>
                        }
                       
                    </TableBody>
                </Table>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    event:state.event,
    general:state.general
})
const mapDispatchToProps={
    modalToggle
}
export default connect(mapStateToProps,mapDispatchToProps)(speaker)