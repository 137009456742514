import React, { Component } from 'react'
import {Button,FormControl,InputLabel,OutlinedInput,InputAdornment} from '@material-ui/core'
import Drafted from './list_drafted'
import Published from './list_published'
import SearchImg from 'assets/icon/Search.png'
import {connect} from 'react-redux'
import {modalToggle} from 'redux/actions/general'
import Skeleton  from 'react-loading-skeleton';
import Loading from 'components/Loading'
import {
    
    addImage,
    getImageEvent,
    getEventWithoutPaging,
    tabToggle,
    clearState,
    setSearch
} from 'redux/actions/event'
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {debounce ,isEmpty} from 'lodash'

class index extends Component {
    state={
        active_tab:'drafted',
        drafted:[],
        published:[],
        search:'',
        isOpen: false,
        preview:null,
        search:''
    }
    componentDidMount(){
        const {event,}=this.props
        if(event.list_event_all===null){
            this.props.getEventWithoutPaging('/0/0')
        }
    }
    
    searchByType=debounce(async(value)=>{
        let {active_tab}=this.state
        const {event,}=this.props

        console.log('act',active_tab)
        this.props.getImageEvent(`/0/${event.active_tab==='drafted'?0:1}/1/10/${value.length>0?value:'*'}`,event.active_tab)

        // this.getEvent(`/0/${event.active_tab==='drafted'?0:1}/1/10`,active_tab,value.length>0?value:'*','loading-table')
    },1000)
    searchToggle=async(e)=>{
        let {value}=e.target
        let {active_tab,search}=this.state
        const {event}=this.props
        await this.props.setSearch(value)
        if(event.search.length>0){
            this.searchByType(value)
        }
       
        
    }
    tabToggle=(key)=>{
        this.props.tabToggle(key)
    }
    addImage=()=>{
        this.props.clearState()
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "Add new image",
            modal_component: "image",
            modal_size:500,
            modal_type:'add',
            modal_data:null
        })
    }
    editImage=(data)=>{
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "Edit image",
            modal_component: "image",
            modal_size:500,
            modal_type:'edit',
            modal_data:data
        })
    }
    
    deleteImage=(data)=>{
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "list image",
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:data.imageName,
                id:data.id,
                msg:`<p>Are you sure delete <b>${data.imageName}</b></p>`
            },
            modal_action:'delete_image'
        })
    
    }
    unPublish=(data)=>{
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "list image",
            modal_component: "confirm_unpublish",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:data.imageName,
                ...data
            },
            modal_action:'unpublish_image'
        })
    }
    onPreviewClick=(data)=>{
        this.setState({isOpen:!this.state.isOpen,preview:data})
    }
    
    render() {
        let {active_tab,drafted,published,preview,isOpen}=this.state
        let {tabToggle,event,general}=this.props
        return (
            <div>
                {/* <Modal/> */}
                {isOpen && (
                    <Lightbox
                        mainSrc={preview.imageURL}
                        imageCaption={<div style={{textAlign:'center',width:1200}}><p>{preview.caption}</p></div>}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                    />
                )}
                <div className='head-section'>
                    <div>
                        <h3>Image</h3>
                    </div>
                    <div>
                        <Button onClick={this.addImage} size='small' color='secondary' variant='contained' className='head-section__btn'>Add new image</Button>
                    </div>
                </div>
                <div className='card-content'>
                    <div className='card-list'>
                    <div className='card-tab-cms-list'>
                       <li onClick={()=>this.tabToggle('drafted')} className={event.active_tab==='drafted'?'card-tab-cms__list card-tab-cms__list-active':'card-tab-cms__list'}>Drafted</li>
                       <li onClick={()=>this.tabToggle('published')} className={event.active_tab==='published'?'card-tab-cms__list card-tab-cms__list-active':'card-tab-cms__list'}>Published</li>
                   </div>
                   
                   <div className='card-table'>
                       <div className='card-table__head'>
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="input-with-icon-textfield">Search</InputLabel>
                                <OutlinedInput
                                    size='small'
                                    style={{height:30,width:200,}}
                                    id="input-with-icon-textfield"
                                    name='search'
                                    onChange={this.searchToggle}
                                    required
                                    startAdornment={
                                    <InputAdornment position="start">
                                       <img alt="search" src={SearchImg} style={{width:15}}/>
                                    </InputAdornment>
                                    }
                                    labelWidth={50}
                                />
                            </FormControl>
                            <div>
                                {/* <button className='card-table__head_btn'>Filter</button>
                                <button className='card-table__head_btn'>Sort</button> */}
                                
                            </div>
                       </div>
                       <div className='card-table__content'>
                            {event.active_tab==='drafted'&&<Drafted isLoadingTable={general.isLoadingTable} onPreviewClick={this.onPreviewClick} images={drafted} editImage={this.editImage}  deleteImage={this.deleteImage}/> }
                            {event.active_tab==='published'&&<Published isLoadingTable={general.isLoadingTable} onPreviewClick={this.onPreviewClick} images={published} unPublish={this.unPublish} />}
                       </div>
                       
                   </div>
                </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    general:state.general,
    event:state.event
    // careers:state.careers
})
const mapDispatchToProps={
    modalToggle,
    tabToggle,
    addImage,
    getImageEvent,
    getEventWithoutPaging,
    clearState,
    setSearch
}
export default connect(mapStateToProps,mapDispatchToProps)(index)