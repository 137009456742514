import React ,{useState,useEffect}from 'react'
import { TextField,Button } from '@material-ui/core'
import InputMask from 'react-input-mask'
import AutoCompleteSelect from 'components/Select'
import { useSelector,useDispatch } from 'react-redux'
import { postExpert,getExpert,putExpert } from 'redux/actions/cdhx'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,
      },
      width:'100%',
      marginBottom:15 
  }
  
}));
export default function Alumni(props) {
    const cdhx = useSelector(state => state.cdhx)
    const dispatch = useDispatch()
    const [state, setstate] = useState({
        id:0,
        name:'',
        email:'',
        eventId:null
    })    
    const {modal_data}=props
    useEffect(() => {
        if(modal_data!==null){
            setstate({
                id:modal_data.id,
                name:modal_data.name,
                email:modal_data.email,
                eventId:modal_data.eventId
            })
        }
    }, [])
    const classes=useStyles()
    const onChange=(e)=>{
        let {name,value}=e.target
        setstate({...state,[name]:value})
    }
    const save=async ()=>{
        let data={
            id:state.id,
            name:state.name,
            email:state.email,
            eventId:cdhx.course_id.id
        }
        if(props.modal_action==='add_forum_expert'){
            let res=await dispatch(postExpert(data))
            if(res){
                dispatch(getExpert(`/${cdhx.course_id.id}/1/10/*`))
            }
        }else{
            let res=await dispatch(putExpert(data))
            if(res){
                dispatch(getExpert(`/${cdhx.course_id.id}/1/10/*`))
            }
        }
        
        
    }
    const handleDisable=()=>{
        if(state.name===''||state.email===''){
            return true
        }else{
            return false
        }
    }
    return (
        <div>
            {/* <p className='bold'>Please fill out the form below to join the course</p>
            <br/> */}
            <TextField
                label={<>Name </>}
                variant='outlined'
                value={state.name}
                size='small'
                // disabled={handleDisable()}
                name="name"
                className={classes.textField}
                onChange={(e)=>onChange(e)}

            />
            
            <TextField
                label={<>Email </>}
                variant='outlined'
                value={state.email}
                size='small'
                // disabled={handleDisable()}
                name="email"
                className={classes.textField}
                onChange={(e)=>onChange(e)}

            />
            <div style={{textAlign:'right'}}>
                <Button disabled={handleDisable()} onClick={save}   size='small' color='primary' variant='contained' className='btn-remove-capital btn-rounded'>Registration</Button>
            </div>
            <br/>
        </div>
    )
}
