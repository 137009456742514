import {apiCall} from 'service/apiCall'
import {setLoading,setError} from './general'
import Cookie from 'universal-cookie'
import {get} from 'lodash'
import { URL, USER,PASS } from 'service/base_url'

const cookie=new Cookie()
export const login=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:'/auth/login',
            method:'POST',
            data:{data:data,auth:{username:USER,password:PASS}}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            cookie.set('login_cookie',res.data.accessToken.token)
            cookie.set('refresh_cookie',res.data.refreshToken)  
            let profile={
                email:res.data.email,
                name:res.data.userName,
                id:res.data.id,
                tribeId:res.data.tribeId,
                segmentId:res.data.segmentId,
                branchId:res.data.branchId,
                roleId:res.data.roleId
            }
            cookie.set('profile_cookie',profile)  
            dispatch(setError(null))
            
            window.location.assign('/dashboard')
        }else{
            dispatch(setLoading(false))
            // dispatch(setError('The Email or password is incorrect. Please try again '))
        }
}


export const refreshToken=(token,refreshtoken)=>{
    return async dispatch=>{
        dispatch(setLoading(true))
        let dataReq={
            url:'/auth/refreshtoken',
            method:'POST',
            data:{
                auth:{username:USER,password:PASS},
                data:{accessToken:token,refreshToken:refreshtoken}
            }
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            cookie.set('login_cookie',res.data.accessToken.token)
            cookie.set('refresh_cookie',res.data.refreshToken)  
            window.location.assign('/dashboard')
        }else{
            dispatch(setLoading(false))
            dispatch(logout())
            // console.log('d',res.data)
        }
    }
}
export const logout=()=>{
    cookie.remove('login_cookie')
    cookie.remove('refresh_cookie')
    cookie.remove('profile_cookie')
    window.location.assign('/')
}