import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
  Redirect,
} from "react-router-dom";
import React, { useEffect } from "react";
import Dashboard from "views/dashboard";
import Login from "views/login";
import Event_management from "views/event_management";
import Careers from "views/careers";
import Home from "views/home";
import Image from "views/images";
import DetailPreview from "views/event_management/preview_new/detail";
import Cookie from "universal-cookie";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "redux/actions/auth";

import Register from "views/cdhx/regis";
import HomeCdhx from "views/cdhx";

import Speakers from "views/speakers";
const cookie = new Cookie();
const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout());
  }, []);
  return <Redirect to="/" />;
};
const indexRouter = (props) => {
  const token = cookie.get("login_cookie");
  const profile = cookie.get("profile_cookie");
  const isHaveToken = () => {
    if (token !== undefined && profile !== undefined) {
      return (
        <>
          <Route
            path="/dashboard"
            exact
            render={() => <Dashboard token={token} profile={profile} />}
          />
          <Route
            path="/eventmanagement/:key/:id"
            exact
            render={() => <Event_management profile={profile} token={token} />}
          />
          <Route
            path="/eventmanagement"
            exact
            render={() => <Event_management profile={profile} token={token} />}
          />
          <Route
            path="/page/careers"
            exact
            render={() => <Careers profile={profile} token={token} />}
          />
          <Route
            path="/page/home"
            exact
            render={() => <Home profile={profile} token={token} />}
          />
          <Route
            path="/images"
            exact
            render={() => <Image profile={profile} token={token} />}
          />
          <Route
            path="/cdhx/registration"
            exact
            render={() => <Register profile={profile} token={token} />}
          />
          <Route
            path="/cdhx/home"
            exact
            render={() => <HomeCdhx profile={profile} token={token} />}
          />
          <Route
            path="/speakers"
            exact
            render={() => <Speakers profile={profile} token={token} />}
          />
        </>
      );
    } else {
      return <Logout />;
    }
  };
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/previewdetail" exact component={DetailPreview} />
        {isHaveToken()}
      </Switch>
    </Router>
  );
};
export default indexRouter;
