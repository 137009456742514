import React, { Component } from 'react'
import Dummy from 'assets/image/dummy.jpg'
import Chevron from 'assets/icon/chevron-white.png'
import './style.css'
import {Button} from '@material-ui/core'
import Download from 'assets/icon/Download.svg'
import NoImage from 'assets/image/noimage.png'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
import moment from 'moment'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#FFFFFF',
        },
    } 
})
export default class banner1 extends Component {
    renderCategory=(id)=>{
        switch (id) {
            case '1':
                return 'Mega Seminar'
                // break;
            case '2':
                return 'Public Workshop'
            case '3':
                return 'Webinar'
            case '4':
                return 'Blended learning'
                case '5':
                return 'Hybrid Class'
            default:
                break;
        }
    }
    render() {
        const {event}=this.props
        const renderThumbnail=event.thumbnail!==null?event.thumbnail.file_url!==undefined?event.thumbnail.file_url:event.thumbnail.thumbnail_url:NoImage
        let renderDate
        if(event.start_date===event.end_date){
            renderDate=`${moment(event.start_date).format('DD MMM')} ${moment(event.start_date).format('YYYY')}`
        }else{
            renderDate=`${moment(event.start_date).format('DD MMM')}-${moment(event.end_date).format('DD MMM')} ${moment(event.start_date).format('YYYY')}`
        }
        return (
            <div>
                <div className='banner1-wrapper-desktop'>
                    <img src={Dummy}/>
                    <div className='banner-content'>
                        <div className='event-breadchrum'>
                            <p>Event & Public Workshop</p>
                            &nbsp;
                            <img src={Chevron} className='breadchrum-chevron'/>
                            &nbsp;
                            <p>{event.title.substring(0,100)}</p>
                            
                        </div>
                        <div className='banner-content-thumbnail'>
                            <img src={renderThumbnail}/>
                        </div>
                        <div className='banner-content-description'>
                            <h1>{event.title}</h1>
                            <p>{this.renderCategory(event.category.toString())} &nbsp;&nbsp; |&nbsp;&nbsp; Date {renderDate} {event.address}</p>
                        </div>
                        <div className='banner-content-audience'>
                            <b>Target Audience</b>
                            <p>{event.target}</p>
                        </div>
                    </div>
                   
                </div>
                <div>
                <div className='banner1-wrapper-mobile'>
                    <img src={Dummy}/>
                    <div className='banner-content-mobile'>
                        <div className='event-breadchrum-mobile'>
                            <p>Event & Public Workshop</p>
                            &nbsp;
                            <img src={Chevron} className='breadchrum-chevron'/>
                            &nbsp;
                            <p>{event.title.substring(0,30)}</p>
                            
                        </div>
                        <h1>{event.title}</h1>
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                        <p>{this.renderCategory(event.category.toString())} {renderDate} {event.address}</p>
                            <MuiThemeProvider theme={themeButton}>
                                <Button color='primary' className='btn-remove-capital' size='small' variant='text' startIcon={<img src={Download} style={{width:15}}/>}>Brochure</Button>
                            </MuiThemeProvider>
                        </div>
                    </div>
                </div>
                <div className='audience-mobile'>
                    <h3>Target Audience</h3>
                    <p>{event.target}</p>
                </div>
                </div>
            </div>
        )
    }
}
