import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from 'ckeditor5-build-classic-with-upload-image'
import { URL as newUrl } from "service/base_url";
import Cookie from "universal-cookie";
import { Button, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { modalToggle } from "redux/actions/general";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { postEmail, setAlumniEmail } from "redux/actions/cdhx";
import Editor from "ckeditor5-custom-build/build/ckeditor";

const cookie = new Cookie();
const token = cookie.get("login_cookie");
// ClassicEditor.defaultConfig = {
// 	toolbar: {
// 		items: [
// 			'heading',
// 			'|',
// 			'bold',
// 			'italic',
// 			'alignment',
// 			'link',
// 			'bulletedList',
// 			'numberedList',
// 			'|',
// 			'indent',
// 			'outdent',
// 			'|',
// 			'imageUpload',
// 			'mediaEmbed',
// 			'blockQuote',
// 			'insertTable',
// 			'undo',
// 			'redo',

// 		]
// 	},
// 	alignment: {
// 		options: [ 'left', 'right','center' ]
// 	},
// 	image: {
// 		toolbar: [
// 			'imageStyle:full',
// 			'imageStyle:side',
// 			'imageStyle:alignLeft',
// 			'imageStyle:alignCenter',
// 			'imageStyle:alignRight',
// 			'|',
// 			'imageTextAlternative'
// 		],
// 		styles:[
// 			'full',
// 			'side',
// 			'alignLeft',
// 			'alignCenter',
// 			'alignRight'
// 		]
// 	},
// 	table: {
// 		contentToolbar: [
// 			'tableColumn',
// 			'tableRow',
// 			'mergeTableCells'
// 		]
// 	},
// 	// This value must be kept in sync with the language defined in webpack.config.js.
// 	language: 'en',
// 	simpleUpload: {
// 		// The URL that the images are uploaded to.
// 		uploadUrl: `${URL}/upload/ckeditor`,

// 		// Headers sent along with the XMLHttpRequest to the upload server.
// 		headers: {
// 			'X-CSRF-TOKEN': 'CSFR-Token',
// 			Authorization: `Bearer ${token}`
// 		}
// 	},
// 	mediaEmbed: {previewsInData: true}

// };
const editorConfiguration = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "alignment",
    "link",
    "bulletedList",
    "numberedList",
    "|",
    "indent",
    "outdent",
    "|",
    "imageUpload",
    "mediaEmbed",
    "blockQuote",
    "insertTable",
    "undo",
    "redo",
  ],
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "imageStyle:alignLeft",
      "imageStyle:alignCenter",
      "imageStyle:alignRight",
      "|",
      "imageTextAlternative",
    ],
    styles: ["full", "side", "alignLeft", "alignCenter", "alignRight"],
  },
  simpleUpload: {
    // The URL that the images are uploaded to.
    uploadUrl: `${URL}/upload/ckeditor`,

    // Headers sent along with the XMLHttpRequest to the upload server.
    headers: {
      "X-CSRF-TOKEN": "CSFR-Token",
      Authorization: `Bearer ${token}`,
    },
  },
  mediaEmbed: { previewsInData: true },
};
const useStyles = makeStyles((theme) => ({
  textField: {
    [`& fieldset`]: {
      borderRadius: 8,
    },
    width: "100%",
    marginBottom: 15,
  },
}));
export default function Email_template(props) {
  const dispatch = useDispatch();
  const cdhx = useSelector((state) => state.cdhx);
  let { email, emailSubject, alumni } = cdhx.alumni_email;
  const classes = useStyles();

  const onClickSimpan = async () => {
    let data = cdhx.alumni_email;
    dispatch(postEmail(data));
  };
  return (
    <div style={{ maxHeight: 400, overflowY: "scroll", overflowX: "hidden" }}>
      <div className="div-flex">
        <div style={{ width: "80%" }}>
          <TextField
            label={<>Subject email</>}
            // variant='outlined'
            value={emailSubject}
            size="small"
            // disabled={handleDisable()}
            className={classes.textField}
            onChange={(e) =>
              dispatch(setAlumniEmail({ emailSubject: e.target.value }))
            }
          />
          <CKEditor
            data={email}
            editor={Editor}
            config={editorConfiguration}
            onChange={(event, editor) => {
              const data = editor.getData();
              dispatch(setAlumniEmail({ email: data }));
            }}
          />
          <p>Berikut merupakan tampilan yang akan peserta terima di emailnya</p>
        </div>
        &nbsp;&nbsp;
        <div style={{ width: "20%" }}>
          <div className="template-email-keterangan-header">
            <p>
              <b>Keterangan</b>
            </p>
          </div>
          <div className="template-email-keterangan-body">
            <p>
              <b>[%NAME%]</b>
            </p>
            <p>Gunakan variabel [%NAME%] untuk merujuk ke nama peserta.</p>
            <p>
              <b>[%EMAIL%]</b>
            </p>
            <p>
              Gunakan variabel [%EMAIL%] untuk merujuk ke email peserta yang
              digunakan untuk akses assessment.
            </p>
            <p>
              <b>[%PASSWORD%]</b>
            </p>
            <p>
              Gunakan variabel [%PASSWORD%] untuk merujuk ke password peserta
              yang digunakan untuk akses assessment.
            </p>
            <p>
              <b>[%LINK%]</b>
            </p>
            <p>
              Gunakan variabel [%LINK%] untuk merujuk alamat link assessment
              dilaksanakan
            </p>
            <p>
              <b>[%DARI%] [%SAMPAI%]</b>
            </p>
            <p>
              Variabel [%DARI%] digunakan untuk merujuk awal mulai waktu akses
              dan [%SAMPAI%] untuk akhir waktu akses assessment
            </p>
            <p>
              <b>[%OTOMATIS%]</b>
            </p>
            <p>
              Digunakan untuk fitur direct url. peserta dapat akses assessment
              melalui email
            </p>
            <p>
              <b>[%MAILTO%]</b>
            </p>
            <p>Digunakan untuk merujuk contact person</p>
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          onClick={onClickSimpan}
          color="primary"
          variant="contained"
          className="btn-remove-capital btn-rounded"
        >
          Simpan
        </Button>
      </div>
    </div>
  );
}
