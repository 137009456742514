import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
} from "@material-ui/core";
import image_line from "assets/icon/image_line.svg";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from 'ckeditor5-build-classic-with-upload-image';
import { connect } from "react-redux";
import MomentUtils from "@date-io/moment";
import {
  TimePicker,
  DatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { setAgenda } from "redux/actions/event";
import { modalToggleReset } from "redux/actions/general";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Editor from "ckeditor5-custom-build/build/ckeditor";

const config = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "blockQuote",
    "link",
    "numberedList",
    "bulletedList",
    "imageUpload",
    "insertTable",
    "|",
    "undo",
    "redo",
  ],
  placeholder: "Type here",
  height: "100px",
};

const themeField = createMuiTheme({
  palette: {
    primary: {
      main: "#00acc1",
    },
  },
});
const useStyles = makeStyles((theme) => ({
  textField: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
    width: "80%",
    marginBottom: 15,
  },
}));
const Agenda = (props) => {
  const [state, setState] = useState({
    date: moment(),
    start_time: moment(),
    end_time: moment(),
    description: "",
    disable: false,
  });
  const dispatch = useDispatch();
  const classes = useStyles();
  const event = useSelector((state) => state.event);
  const general = useSelector((state) => state.general);
  const { modal_data } = general;
  useEffect(() => {
    setState({ ...state, date: moment(event.start_date) });
    if (modal_data !== null) {
      setState({
        ...state,
        date: moment(modal_data.date),
        start_time: moment(modal_data.startTime, "hh:mm"),
        end_time: moment(modal_data.endTime, "hh:mm"),
        description: modal_data.description,
      });
    }
  }, [modal_data]);
  const onChange = (name, value) => {
    setState({ ...state, [name]: value }, () => {
      renderDisable();
    });
  };
  const renderDisable = () => {
    let day_validation = state.date.isBetween(
      event.start_date,
      event.end_date,
      "days",
      "[]"
    );
    if (day_validation) {
      setState({ ...state, disable: false });
    } else {
      setState({ ...state, disable: true });
    }
  };
  const addAgenda = () => {
    let agenda_state = {
      id: 0,
      date: moment(state.date).format("YYYY-MM-DD"),
      startTime: state.start_time.format("HH:mm"),
      endTime: state.end_time.format("HH:mm"),
      description: state.description,
    };
    let existing = [...event.agenda, agenda_state];
    dispatch(setAgenda(existing));
    dispatch(modalToggleReset());
  };
  const updateAgenda = () => {
    const { agenda } = event;
    const { modal_data } = general;
    console.log("state", state.date);
    agenda[modal_data.index].date = moment(state.date).format("YYYY-MM-DD");
    agenda[modal_data.index].startTime = moment(state.start_time).format(
      "HH:mm"
    );
    agenda[modal_data.index].endTime = moment(state.end_time).format("HH:mm");
    agenda[modal_data.index].description = state.description;
    dispatch(modalToggleReset());
  };
  return (
    <div>
      <div className="agenda-form">
        <MuiThemeProvider theme={themeField}>
          &nbsp;&nbsp;
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              className={classes.textField}
              format="MMMM DD YYYY"
              autoOk
              label="Date"
              value={state.date}
              size="small"
              inputVariant="outlined"
              onChange={(date) => onChange("date", date)}
            />
            &nbsp;
            <TimePicker
              className={classes.textField}
              invalidDateMessage={null}
              autoOk
              label="Start Time"
              clearable={true}
              size="small"
              inputVariant="outlined"
              value={state.start_time}
              onChange={(date) => setState({ ...state, start_time: date })}
            />
            &nbsp;
            <TimePicker
              className={classes.textField}
              invalidDateMessage={null}
              autoOk
              label="End Time"
              clearable={true}
              size="small"
              inputVariant="outlined"
              value={state.end_time}
              onChange={(date) => setState({ ...state, end_time: date })}
            />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </div>
      <br />
      <div className="agenda-description">
        <p className="bold">Description</p>
        <br />
        <CKEditor
          data={modal_data !== null && modal_data.description}
          editor={Editor}
          config={config}
          onChange={(event, editor) => {
            const data = editor.getData();
            setState({ ...state, description: data });
          }}
        />
      </div>
      <div className="card-footer">
        {general.modal_action === "add_agenda" ? (
          <Button
            disabled={state.disable}
            onClick={addAgenda}
            size="small"
            color="primary"
            variant="contained"
            className="head-add-section__btn"
          >
            Add
          </Button>
        ) : (
          <Button
            disabled={state.disable}
            onClick={updateAgenda}
            size="small"
            color="primary"
            variant="contained"
            className="head-add-section__btn"
          >
            Update
          </Button>
        )}
      </div>
    </div>
  );
};
export default Agenda;
// class agenda extends Component {
//     state={
//         date:moment(),
//         start_time:moment(),
//         end_time:moment(),
//         description:'',
//         disable:false
//     }
//     componentWillMount(){
//         const {modal_data}=this.props.general
//         const {event}=this.props
//         this.setState({date:moment(event.start_date)})
//         if(modal_data!==null){
//             // console.log(modal_data)
//             this.setState({
//                 date:moment(modal_data.date),
//                 start_time:moment(modal_data.startTime,'hh:mm'),
//                 end_time:moment(modal_data.endTime,'hh:mm'),
//                 description:modal_data.description
//             })
//         }
//     }
//     onChange=(name,value)=>{
//         this.setState({[name]:value},()=>{
//             this.renderDisable()
//         })
//     }
//     renderDisable=()=>{
//         const {modal_action,event}=this.props
//         const {date}=this.state
//         let day_validation=date.isBetween(event.start_date,event.end_date,'days', '[]')
//         if(day_validation){
//             this.setState({disable:false})
//         }else{
//             this.setState({disable:true})

//         }
//     }
//     addAgenda=()=>{
//         const {date,start_time,end_time,description}=this.state
//         const {agenda}=this.props.event
//         let agenda_state={id:agenda.length,date:moment(date).format('YYYY-MM-DD'),startTime:start_time.format('HH:mm'),endTime:end_time.format('HH:mm'),description:description}
//         let existing=[
//             ...agenda,
//             agenda_state,
//         ]
//         console.log('agenda',agenda_state)
//         this.props.setAgenda(existing)
//         this.props.modalToggleReset()
//     }
//     updateAgenda=()=>{
//         const {date,start_time,end_time,description}=this.state
//         const {agenda}=this.props.event
//         const {modal_data}=this.props
//         agenda[modal_data.id].date=moment(date).format('YYYY-MM-DD')
//         agenda[modal_data.id].startTime=moment(start_time).format('HH:mm')
//         agenda[modal_data.id].endTime=moment(end_time).format('HH:mm')
//         agenda[modal_data.id].description=description
//         this.props.modalToggleReset()

//     }
//     render() {
//         const {date,start_time,end_time,description,disable}=this.state
//         const {modal_action,event}=this.props
//         console.log(start_time)
//         return (
//             <div>
//                 <div className='agenda-form'>
//                     <MuiThemeProvider theme={themeField}>

//                     &nbsp;&nbsp;
//                     <MuiPickersUtilsProvider utils={MomentUtils}>
//                         <DatePicker
//                             autoOk
//                             label="Date"
//                             value={date}
//                             size='small'
//                             inputVariant='outlined'
//                             onChange={(date)=>this.onChange('date',date)}
//                         />
//                         &nbsp;
//                         <TimePicker invalidDateMessage={null} autoOk  label='Start Time' clearable={true} size='small' inputVariant='outlined' value={start_time} onChange={(date)=>this.setState({start_time:date})} />
//                             &nbsp;
//                         <TimePicker invalidDateMessage={null} autoOk  label='End Time' clearable={true} size='small' inputVariant='outlined' value={end_time} onChange={(date)=>this.setState({end_time:date})} />
//                     </MuiPickersUtilsProvider>

//                     </MuiThemeProvider>
//                 </div>
//                 <br/>
//                 <div className='agenda-description'>
//                     <p className='bold'>Description</p>
//                     <br/>
//                     <CKEditor
//                         data={description}
//                         editor={ ClassicEditor }
//                         config={config}
//                         onChange={(event,editor)=>{
//                             const data=editor.getData()
//                             this.setState({description:data})
//                         }}
//                     />
//                 </div>
//                 <div className='card-footer'>
//                     {modal_action==='add_agenda'?
//                     <Button disabled={disable} onClick={this.addAgenda}  size='small' color='primary' variant='contained' className='head-add-section__btn'>Add</Button>
//                     :
//                     <Button disabled={disable} onClick={this.updateAgenda}  size='small' color='primary' variant='contained' className='head-add-section__btn'>Update</Button>
//                     }
//                 </div>
//             </div>
//         )
//     }
// }
// const mapStateToProps=(state)=>({
//     general:state.general,
//     event:state.event
// })
// const mapDispatchToProps={
//     setAgenda,
//     modalToggleReset
// }
// export default connect(mapStateToProps,mapDispatchToProps)(agenda)
