import React, { Component } from 'react'
import {FormControl,InputLabel,OutlinedInput,Button} from '@material-ui/core'
import image_line from 'assets/icon/image_line.svg'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
import {handleFile} from 'components/handleFile'
import {connect} from 'react-redux'
import {modalToggleReset} from 'redux/actions/general'
import {setFramework} from 'redux/actions/event'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#4CAF50',
        },
        secondary:{
            main:'#f44336'
        }
    } 
})
class framework extends Component {
    state={
        framework_error:null,
        framework_url:null,
        frameWorkImages:null,
        caption:'',
        file_name:''
    }
    componentWillMount(){
        const {modal_data}=this.props.general
        if(modal_data!==null){
            this.setState({
                framework_url:modal_data.framework_url,
                frameWorkImages:modal_data.frameWorkImages,
                caption:modal_data.caption,
                file_name:modal_data.file_name
            })
        }
    }
    handleFramework=(evt)=>{
        let handle= handleFile(evt)
        if(handle.file_error===null){
            let framework_url=URL.createObjectURL(handle.file)
            this.setState({framework_url,frameWorkImages:handle.file,framework_error:null,file_name:handle.file_name})
        }else{
            console.log('err',handle)
            this.setState({framework_error:handle.file_error})
        }
      
    }
    deleteImage=()=>{
        this.setState({framework_error:null,framework_url:null,frameWorkImages:null,file_name:''})
    }
    addFramework=()=>{
        const {frameWorkImages,caption,file_name,framework_url}=this.state
        const {framework}=this.props.event
        let framework_state={id:framework.length,caption,frameWorkImages,file_name,framework_url}
        let existing=[
            ...framework,
            framework_state,
        ]
        this.props.setFramework(existing)
        this.props.modalToggleReset()
    }
    updateFramework=()=>{
        const {frameWorkImages,caption,file_name,framework_url}=this.state
        const {framework}=this.props.event
        const {modal_data}=this.props.general
        framework[modal_data.id].frameWorkImages=frameWorkImages
        framework[modal_data.id].caption=caption
        framework[modal_data.id].file_name=file_name
        framework[modal_data.id].framework_url=framework_url
        this.props.modalToggleReset()


    }
    render() {
        const {framework_url,framework_error,caption}=this.state
        const {modal_action}=this.props.general
        return (
            <div>
                <div className='card-container'>
                    <div className='framework-image'>
                        <p className='bold'>Image</p>
                        {framework_url!==null?
                        <img src={framework_url} style={{width:150,height:100,borderRadius:10,objectFit:'cover'}}/>
                        :
                        <div className='square-image'>
                            <MuiThemeProvider theme={themeButton}>
                                <Button
                                    className='btn-remove-capital'
                                    variant='text'
                                    startIcon={<img src={image_line}/>}
                                    color='primary'
                                    component="label"
                                >
                                    Add image here
                                    <input
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={this.handleFramework}
                                    />
                                </Button>
                            </MuiThemeProvider>
                        </div>
                        }
                        {framework_error!==null&&<p style={{color:'red',fontSize:10}}>{framework_error}</p>}
                    </div>
                   
                    <div className='framework-caption'>
                      
                       <FormControl variant="outlined" size="small" >
                            <InputLabel  htmlFor="Caption">Caption</InputLabel>
                            <OutlinedInput
                                
                                id="Caption"
                                type='textarea'
                                name='Caption'
                                value={caption}
                                onChange={(e)=>this.setState({caption:e.target.value})}
                                required
                                rows={5}
                                multiline
                                labelWidth={50}
                                className='field-radius'
                            />
                        </FormControl>
                    </div>
                </div>
                <div className='card-event-footer'>
                    <MuiThemeProvider theme={themeButton}>
                        <Button
                            className='btn-remove-capital'
                            variant='text'
                            color='secondary'
                            onClick={this.deleteImage}
                        >
                            Delete image
                        </Button>
                    </MuiThemeProvider>
                    {modal_action==='add_framework'?
                   <Button onClick={this.addFramework} size='small' color='primary' variant='contained' className='head-add-section__btn '>Add</Button>
                    :
                    <Button onClick={this.updateFramework}  size='small' color='primary' variant='contained' className='head-add-section__btn'>Update</Button>
                    }
                    
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    event:state.event,
    general:state.general
})
const mapDispatchToProps={
    setFramework,
    modalToggleReset
}
export default connect(mapStateToProps,mapDispatchToProps)(framework)