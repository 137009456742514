import React, { Component } from 'react'
import {Button,FormControl,InputLabel,OutlinedInput,InputAdornment} from '@material-ui/core'
import Layout from 'components/Layouts'
import List from './list'
export default class index extends Component {
    render() {
        return (
            <Layout>
                <List token={this.props.token}/>
            </Layout>
        )
    }
}
