import * as actionTypes from '../constants/home'

import {apiCall} from 'service/apiCall'
import {setLoading,setLoadingTable,setError,modalToggle} from './general'
import {get} from 'lodash'
import { URL, USER,PASS } from 'service/base_url'
export const setWelcomeWord=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_WELCOME_WORD,
        payload:data
    })
}
export const setAdditionalWord=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_ADDITIONAL_WORD,
        payload:data
    })
}
export const setBannerImage=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_BANNER_IMAGE,
        payload:data
    })
}

export const postCaption=(token,data)=>async dispatch=>{
    dispatch(setLoading(true))
    let dataReq={
        url:`/event/caption`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==204){
        dispatch(setLoading(false))
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Update Home page success",
            modal_component: "post_caption",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Home page gmlperformance.com successfully updated</p> `,
            },
            modal_action:'success',
        }))
    }
}
export const postBanner=(token,data)=>async dispatch=>{
    dispatch(setLoading(true))
    let dataReq={
        url:`/event/banner`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data'},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(getBanner())
        dispatch(setLoading(false))
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Banner image",
            modal_component: "post_caption",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Banner image ${res.data.filename} successfully saved</p> `,
            },
            modal_action:'success',
        }))
    }
}
export const putBanner=(token,data)=>async dispatch=>{
    dispatch(setLoading(true))
    let dataReq={
        url:`/event/banner`,
        method:'PUT',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data'},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==204){
        dispatch(getBanner())
        dispatch(setLoading(false))
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Banner image",
            modal_component: "post_caption",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Banner image successfully updated</p> `,
            },
            modal_action:'success',
        }))

    }
}

export const getBanner=(slug='/1/5')=>async dispatch=>{
    dispatch(setLoading(true))
    let dataReq={
        url:`/Event/banner/2${slug}`,
        method:'GET',
        data:{
            auth:{username:USER,password:PASS}
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        let new_banner=[]
        res.data.banners.map((data)=>{
            new_banner.push({...data,id:`banner-${data.bannerId}`})
        })
        dispatch(setLoading(false))
        dispatch(setBannerImage(new_banner))
        dispatch({
            type:actionTypes.SET_BANNER_PAGINATION,
            payload:res.data.info
        })
    }
}
export const getCaption=(token)=>async dispatch=>{
    dispatch(setLoading(true))
    let dataReq={
        url:`/event/caption`,
        method:'GET',
        data:{
            auth:{username:USER,password:PASS}
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading(false))
        dispatch(setWelcomeWord(res.data.welcome))
        dispatch(setAdditionalWord(res.data.welcomeNote))
    }
}

export const deleteBanner=(token,slug)=>async dispatch=>{
    dispatch(setLoading(true))
    let dataReq={
        url:`/event/banner${slug}`,
        method:'DELETE',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data'},
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(getBanner())
        dispatch(setLoading(false))
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Banner image",
            modal_component: "post_caption",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Banner image ${res.data.filename} successfully deleted in our system</p> `,
            },
            modal_action:'success',
        }))
    }
}
export const moveBanner=(token,slug)=>async dispatch=>{
    dispatch(setLoading(true))
    let dataReq={
        url:`/Event/banner/up${slug}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==204){
        dispatch(getBanner())
        dispatch(setLoading(false))
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Banner image",
            modal_component: "post_caption",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Banner image successfully updated in our system</p> `,
            },
            modal_action:'success',
        }))
    }
}
export const publishBanner=(token,slug)=>async dispatch=>{
    dispatch(setLoading(true))
    let dataReq={
        url:`/Event/banner/publish${slug}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(getBanner())
        dispatch(setLoading(false))
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Banner image",
            modal_component: "post_caption",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Banner image successfully updated in our system</p> `,
            },
            modal_action:'success',
        }))
    }else{
        dispatch(setLoading(false))

    }
}