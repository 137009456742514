export const PREVIEW_SET_TITLE='PREVIEW_SET_TITLE'
export const PREVIEW_SET_DATE='PREVIEW_SET_DATE'
export const PREVIEW_SET_START_DATE='PREVIEW_SET_START_DATE'
export const PREVIEW_SET_END_DATE='PREVIEW_SET_END_DATE'
export const PREVIEW_SET_START_TIME='PREVIEW_SET_START_TIME'
export const PREVIEW_SET_END_TIME='PREVIEW_SET_END_TIME'
export const PREVIEW_SET_ADDRESS='PREVIEW_SET_ADDRESS'
export const PREVIEW_SET_CATEGORY='PREVIEW_SET_CATEGORY'
export const PREVIEW_SET_STATUS='PREVIEW_SET_STATUS'
export const PREVIEW_SET_TARGET='PREVIEW_SET_TARGET'
export const PREVIEW_SET_THUMBNAIL='PREVIEW_SET_THUMBNAIL'
export const PREVIEW_SET_BROCHURE='PREVIEW_SET_BROCHURE'
export const PREVIEW_SET_DESCRIPTION='PREVIEW_SET_DESCRIPTION'
export const PREVIEW_SET_ADDITIONAL='PREVIEW_SET_ADDITIONAL'
export const PREVIEW_SET_FRAMEWORK='PREVIEW_SET_FRAMEWORK'
export const PREVIEW_SET_TESTIMONY='PREVIEW_SET_TESTIMONY'
export const PREVIEW_SET_AGENDA='PREVIEW_SET_AGENDA'
export const PREVIEW_SET_INVESTMENT='PREVIEW_SET_INVESTMENT'
export const PREVIEW_SET_SPEAKER='PREVIEW_SET_SPEAKER'
export const PREVIEW_SET_SHORT_DESC='PREVIEW_SET_SHORT_DESC'
export const PREVIEW_SET_ID='PREVIEW_SET_ID'
export const GET_EVENT='GET_EVENT'