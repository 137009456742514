import React, { Component } from 'react'
import {Button,FormControl,InputLabel,OutlinedInput,InputAdornment} from '@material-ui/core'
import Drafted from './list_drafted'
import Published from './list_published'
import SearchImg from 'assets/icon/Search.png'
import {connect} from 'react-redux'
import {modalToggle} from 'redux/actions/general'
import Skeleton  from 'react-loading-skeleton';
import Loading from 'components/Loading'
import {getCarrers,clearState,
    setTitle,
    setPlacement,
    setDescription,
    setJobCode,
    setStatus,
    postCareer,
    publishCareer,
    updateCareer
} from 'redux/actions/careers'
class index extends Component {
    state={
        active_tab:'drafted',
        drafted:[],
        published:[],
        search:''
    }
    componentDidMount(){
        this.props.getCarrers(this.props.token)
    }
    componentWillReceiveProps(newProps){
        let careers=newProps.careers.careers
       
        this.spliceCarrer(careers)
    }
    spliceCarrer=(careers)=>{
        let drafted=[]
        let published=[]
        if(careers.length>0){
            careers.map((data,i)=>{
                if(data.publish==1){
                    published.push(data)
                }else{
                    drafted.push(data)
                }
            })
            this.setState({drafted,published})
        }
    }
    onChange=(e)=>{
        let {drafted,published,search}=this.state
        let {careers}=this.props.careers
        const { value } = e.target
        this.setState({search:value})
        var draft_filter = drafted.filter(item=>item.title.toLowerCase().includes(value));
        var publish_filter = published.filter(item=>item.title.toLowerCase().includes(value));
        
        if(value!==''){
            this.setState({ drafted: draft_filter,published:publish_filter })

        }else{
            this.spliceCarrer(careers)
        }
    }
    tabToggle=(key)=>{
        this.setState({active_tab:key})
    }
    addCareers=()=>{
        this.props.clearState()
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "Add new career",
            modal_component: "career",
            modal_size:600,
            modal_type:'add',
            modal_data:null
        })
    }
    editCareers=(data)=>{
        let {title,location,publish,description}=data

        this.props.setTitle(title)
        this.props.setPlacement(location)
        this.props.setJobCode(title)
        this.props.setStatus(publish)
        this.props.setDescription(description)
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "Edit career",
            modal_component: "career",
            modal_size:600,
            modal_type:'edit',
            modal_data:data
        })
    }
    detailCareers=(data)=>{
        let {title,location,publish,description}=data
        this.props.setTitle(title)
        this.props.setPlacement(location)
        this.props.setJobCode(title)
        this.props.setStatus(publish)
        this.props.setDescription(description)
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "Detail career",
            modal_component: "career",
            modal_size:600,
            modal_type:'readonly',
            modal_data:data,
        })
    }
    deleteCareers=(data)=>{
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "list career",
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                ...data,
                msg:`<p>Are you sure delete <b>${data.title}</b></p>`
            },
            modal_action:'delete_career'
        })
    
    }
    unPublish=(data)=>{
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "list career",
            modal_component: "confirm_unpublish",
            modal_size:400,
            modal_type:'confirm',
            modal_data:data,
            modal_action:'unpublish_career'
        })
    }
    render() {
        let {active_tab,drafted,published}=this.state
        let {tabToggle,general}=this.props
        //test
        return (
            <div>
                {/* <Modal/> */}
                <div className='head-section'>
                    <div>
                        <h3>Careers</h3>
                    </div>
                    <div>
                        <Button onClick={this.addCareers} size='small' color='secondary' variant='contained' className='head-section__btn'>Add new careers</Button>
                    </div>
                </div>
                <div className='card-content'>
                <div className='card-list'>
                    <div className='card-tab-cms-list'>
                       <li onClick={()=>this.tabToggle('drafted')} className={active_tab==='drafted'?'card-tab-cms__list card-tab-cms__list-active':'card-tab-cms__list'}>Drafted</li>
                       <li onClick={()=>this.tabToggle('published')} className={active_tab==='published'?'card-tab-cms__list card-tab-cms__list-active':'card-tab-cms__list'}>Published</li>
                   </div>
                   
                   <div className='card-table'>
                       <div className='card-table__head'>
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="input-with-icon-textfield">Search</InputLabel>
                                <OutlinedInput
                                    size='small'
                                    style={{height:30,width:200,}}
                                    id="input-with-icon-textfield"
                                    name='search'
                                    onChange={this.onChange}
                                    required
                                    startAdornment={
                                    <InputAdornment position="start">
                                       <img alt="search" src={SearchImg} style={{width:15}}/>
                                    </InputAdornment>
                                    }
                                    labelWidth={50}
                                />
                            </FormControl>
                            <div>
                                {/* <button className='card-table__head_btn'>Filter</button>
                                <button className='card-table__head_btn'>Sort</button> */}
                                
                            </div>
                       </div>
                       <div className='card-table__content'>
                            {active_tab==='drafted'&&<Drafted isLoadingTable={general.isLoadingTable} careers={drafted} editCareers={this.editCareers} detailCareers={this.detailCareers} deleteCareers={this.deleteCareers}/> }
                            {active_tab==='published'&&<Published isLoadingTable={general.isLoadingTable} careers={published} unPublish={this.unPublish} detailCareers={this.detailCareers}/>}
                       </div>
                   </div>
                </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    general:state.general,
    careers:state.careers
})
const mapDispatchToProps={
    modalToggle,getCarrers,clearState,
    setTitle,
    setPlacement,
    setDescription,
    setJobCode,
    setStatus,
    postCareer,
    publishCareer,
    updateCareer
}
export default connect(mapStateToProps,mapDispatchToProps)(index)