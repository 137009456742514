import React,{useState} from 'react'
import AutoCompleteSelect from 'components/Select'
import Edit from 'assets/icon/edit.svg'
import Close from 'assets/icon/close.svg'
import { useDispatch,useSelector } from 'react-redux'
import { modalToggle } from 'redux/actions/general'
import {getCourse,getCourseSearch,getAlumni, setAlumniUpload,uploadAlumni,setCourseId,getEmail,setAlumniEmail } from 'redux/actions/cdhx'
import { handleFile,getBase64 } from 'components/handleFile'
import {Button ,Table,TableHead,TableRow,TableCell,TableBody} from '@material-ui/core'
import {debounce, isEmpty} from 'lodash'

export default function Upload_alumni(props) {
    const cdhx = useSelector(state => state.cdhx)
    const {course_list,alumni_upload}=cdhx
    const dispatch = useDispatch()
    const [loading,setLoading]=useState(false)
    const [search, setsearch] = useState('')

    const [textSelect,setTextSelect]=useState('Type company name')
    const addEpert=()=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Add Forum Expert",
            modal_component: "add_facilitator",
            modal_size:350,
            modal_data:null,
            modal_type:'multi',
            modal_action:'add_expert'

        }))
    }
    const editExpert=(data,i)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Edit Forum Expert",
            modal_component: "add_facilitator",
            modal_size:350,
            modal_data:{data,i},
            modal_type:'multi',
            modal_action:'edit_expert'
        }))
    }
    const deleteExp=(index)=>{
        alumni_upload.experts.splice(index,1)
        dispatch(setAlumniUpload({experts:alumni_upload.experts}))
    }
    const onchangeFile=(evt)=>{
        let handle= handleFile(evt,'alumni')
        if(handle.file_error===null){
            getBase64(handle.file,(result)=>{
                var base64result = result.split(',')[1];
                dispatch(setAlumniUpload({Filename:handle.file_name}))
                dispatch(setAlumniUpload({FileBase64:base64result}))
            })
        }
    }
    const onSave=async ()=>{
        let {alumni_upload}=cdhx
        let data={
            eventId:alumni_upload.eventId.id,
            Filename:alumni_upload.Filename,
            FileBase64:alumni_upload.FileBase64,
            experts:alumni_upload.experts,
            userId:props.profile.id
        }
        let res=await dispatch(uploadAlumni(data))
        if(res){
            dispatch(getEmail(`/${alumni_upload.eventId.id}/${props.profile.id}`))
            dispatch(setAlumniEmail({alumni:res.data}))
            dispatch(setCourseId(alumni_upload.eventId))
            dispatch(getAlumni(`/${alumni_upload.eventId.id}/1/10/*`))

            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Template Email",
                modal_component: "email_template",
                modal_size:1050,
                modal_data:null,
            }))
        }
        console.log(`data`, data)
    }
    const handleDisable=()=>{
        if(alumni_upload.eventId===null||alumni_upload.FileBase64===''){
            return true
        }else{
            return false
        }
    }
    const onChangeCourse=debounce(async (value)=>{
        let findCourse=course_list.filter((data)=>{
            return data.label.toLowerCase().includes(value.toLowerCase())
        })
        if(isEmpty(findCourse)){
            setsearch(value)

            if(value!==search&&value.length>0){
                setLoading(true)
                let res=await dispatch(getCourseSearch(value))
                if(isEmpty(res)){
                    setTextSelect('No options')
                }
                setLoading(false)
            }else{
                dispatch(getCourse())
            }
        }
        
    },1000)
    // console.log(`alumni_upload`, alumni_upload)
    return (
        <div>
            <AutoCompleteSelect
                color='secondary'
                noOptionsText={textSelect}
                loading={loading}
                value={alumni_upload.eventId}
                disableClearable={true}
                onChange={(event,value)=>dispatch(setAlumniUpload({eventId:value}))}
                options={course_list}
                groupBy={(option)=>{
                    if(search===''){
                        return '5 Course terbaru'
                    }else{
                        return null
                    }
                }}
                getOptionLabel={(option) => option.label}
                onInputChange={(event,e)=>onChangeCourse(e)}

                label="Choose Course Name First"
            />
            <p className='bold'>Upload File Alumni(csv,xls,xlsx)</p>
            <div className='div-flex div-space-between div-align-center'>
                <p className='semi-bold'>{alumni_upload.Filename}</p>
                <Button  component="label" style={{fontWeight:'bold'}} size='small' color='primary' variant='text' className='btn-remove-capital'>
                    Upload File
                    <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e)=>onchangeFile(e)}
                    />
                </Button>
            </div>
            <br/>
            <div className='div-flex div-space-between div-align-center'>
                <p className='bold'>Forum Expert</p>
                <Button onClick={addEpert} style={{fontWeight:'bold'}} size="small" variant="text" color="primary" className="btn-remove-capital">Add Facilitator</Button>
            </div>
            <Table  size="small" aria-label="a dense table">
                <TableHead>
                <TableRow style={{backgroundColor:'white'}}>
                    <TableCell>Name</TableCell>
                    <TableCell >Email</TableCell>
                    <TableCell align="right">Action</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {alumni_upload.experts.map((data,i)=>(
                        <TableRow >

                            <TableCell>{data.name}</TableCell>
                            <TableCell>{data.address}</TableCell>
                            <TableCell align="right">
                                <img src={Edit} onClick={()=>editExpert(data,i)} className='icon-action'/>
                                <img src={Close} className='icon-action' onClick={()=>deleteExp(i)}/>
                            </TableCell>
                        </TableRow>
                    ))}
                   
                </TableBody>
            </Table>
            <br/>
            <div style={{textAlign:'right'}}>
                <Button disabled={handleDisable()} onClick={onSave}   size='small' color='primary' variant='contained' className='btn-remove-capital btn-rounded'>Submit</Button>
            </div>
        <br/>
        </div>
    )
}
