import React, { useState,useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";

import {FormControl,InputLabel,OutlinedInput,Button
,Select,MenuItem,Switch,FormControlLabel,InputAdornment ,TextField} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import {connect} from 'react-redux'
import {modalToggleReset} from 'redux/actions/general'
import {setInvestment} from 'redux/actions/event'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
const themeField = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00acc1',
        },
    } 
})
const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,
      },
      width:'100%',
      marginBottom:15
  }
  
}));
const Investmen=(props)=>{
    const [state,setState]=useState({
        added_tax:false,
        title:'',
        type:'',
        nominal:0,
        ppnpercent:11
    })
    const dispatch=useDispatch()
    const classes=useStyles()
    const event=useSelector(state=>state.event)
    const general=useSelector(state=>state.general)
    const {modal_data}=general
    useEffect(()=>{
        if(modal_data!==null){
            setState({
                ...state,
                added_tax:modal_data.ppn===1?true:false,
                title:modal_data.title,
                type:modal_data.type,
                nominal:modal_data.nominal,
                ppnpercent:modal_data.ppnpercent,
            })
        }
    },[modal_data])
    const onChange=(e)=>{
        setState({...state,[e.target.name]:e.target.value})
    }
    const switchToggle=()=>{
        setState({...state,added_tax:!state.added_tax})
    }
    const addInvestment=()=>{
        const {added_tax,title,type,nominal,ppnpercent}=state
        let investment_state={id:0,ppn:added_tax?1:0,title:title,type:type,nominal:nominal,ppnpercent:added_tax?ppnpercent:0}
        let existing=[
            ...event.investment,
            investment_state,
        ]
        dispatch(setInvestment(existing))
        dispatch(modalToggleReset())
    }
    const updateInvestment=()=>{
        const {added_tax,title,type,nominal,ppnpercent}=state
       
        const {modal_data}=general
        const {investment}=event
        investment[modal_data.index].ppn=added_tax?1:0
        investment[modal_data.index].title=title
        investment[modal_data.index].type=type
        investment[modal_data.index].nominal=nominal
        investment[modal_data.index].ppnpercent=added_tax?ppnpercent:0
        dispatch(modalToggleReset())

    }
    const {added_tax,title,type,nominal,ppnpercent}=state
    
    return(
        <div>
        <MuiThemeProvider theme={themeField}>
            <FormControl variant="outlined" size="small" className='add-investment__field'>
                <InputLabel  htmlFor="title">Investment title</InputLabel>
                <OutlinedInput
                    
                    id="title"
                    type='text'
                    name='title'
                    value={title}
                    onChange={onChange}
                    required
                    labelWidth={120}
                    className='field-radius'
                />
            </FormControl>
            <FormControl variant="outlined" size="small" className='add-investment__field' >
                <InputLabel  htmlFor="investment_type">Investment type</InputLabel>
                <Select name='type' onChange={onChange} value={type}  labelId="investment_type" id="investment_type"  labelWidth={120} className='field-radius'>
                    <MenuItem value="Normal Price">Normal Price</MenuItem>
                    <MenuItem value="Discount Price">Discount Price</MenuItem>
                    <MenuItem value="Early Bird">Early Bird</MenuItem>
                    <MenuItem value="Group">Group</MenuItem>
                    <MenuItem value="Free">Free</MenuItem>
                </Select>
            </FormControl>
            
            <CurrencyTextField
                className={classes.textField}
                label="Nominal"
                variant="outlined"
                value={nominal}
                currencySymbol="Rp"
                size='small'
                disabled={type==='Free'?true:false}
                outputFormat="string"
                decimalCharacter="."
                digitGroupSeparator=","
                onChange={(event, value)=>setState({...state,nominal:value})}
            />
            <div className='add-investment__field add-tax'>
                <p className='bold'>Value Added tax</p>
                <FormControlLabel
                    control={
                    <Switch
                        checked={added_tax}
                        onChange={switchToggle}
                        name="checkedB"
                        color="primary"
                    />
                    }
                    label={<InputLabel  htmlFor="nominal_investment">Using Tax</InputLabel>}
                />
            </div>
            {added_tax&&
                <FormControl variant="outlined" size="small" className='add-investment__field'>
                    <InputLabel  htmlFor="percentage_tax">Percentage Tax</InputLabel>
                    <OutlinedInput
                        
                        id="percentage_tax"
                        type='number'
                        name='ppnpercent'
                        value={ppnpercent}
                        onChange={onChange}
                        required
                        labelWidth={120}
                        className='field-radius'
                        endAdornment={<InputAdornment position="end">%</InputAdornment>}
                        // aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                        'aria-label': 'weight',
                        }}
                    />
                </FormControl>
            }
        </MuiThemeProvider>
        <div className='card-footer'>
            {general.modal_action==='add_investment'?
            <Button onClick={addInvestment}  size='small' color='primary' variant='contained' className='head-add-section__btn'>Add</Button>
            : 
            <Button onClick={updateInvestment}  size='small' color='primary' variant='contained' className='head-add-section__btn'>Update</Button>
            } 
        </div>
    </div>
    )
}
export default Investmen
// class investment extends Component {
//     state={
//         added_tax:false,
//         title:'',
//         type:'',
//         nominal:0,
//         ppnpercent:10
//     }
//     componentWillMount(){
//         const {modal_data}=this.props.general
//         if(modal_data!==null){
//             this.setState({
//                 added_tax:modal_data.ppn===1?true:false,
//                 title:modal_data.title,
//                 type:modal_data.type,
//                 nominal:modal_data.nominal,
//                 ppnpercent:modal_data.ppnpercent,
//             })
//         }
//     }
//     onChange=(e)=>{
//         this.setState({[e.target.name]:e.target.value})
//     }
//     switchToggle=()=>{
//         this.setState({added_tax:!this.state.added_tax})
//     }
//     addInvestment=()=>{
//         const {added_tax,title,type,nominal,ppnpercent}=this.state
//         let investment_state={id:this.props.event.investment.length,ppn:added_tax?1:0,title:title,type:type,nominal:nominal,ppnpercent:added_tax?ppnpercent:0}
//         let existing=[
//             ...this.props.event.investment,
//             investment_state,
//         ]
//         this.props.setInvestment(existing)
//         this.props.modalToggleReset()
//     }
//     updateInvestment=()=>{
//         const {added_tax,title,type,nominal,ppnpercent}=this.state
       
//         const {modal_data}=this.props.general
//         this.props.event.investment[modal_data.id].ppn=added_tax?1:0
//         this.props.event.investment[modal_data.id].title=title
//         this.props.event.investment[modal_data.id].type=type
//         this.props.event.investment[modal_data.id].nominal=nominal
//         this.props.event.investment[modal_data.id].ppnpercent=ppnpercent
//         this.props.modalToggleReset()
//         // console.log('this.props.event', this.props.event)

//     }
//     render() {
//         const {added_tax,title,type,nominal,ppnpercent}=this.state
//         const {modal_action}=this.props.general

//         return (
//             <div>
//                 <MuiThemeProvider theme={themeField}>
//                     <FormControl variant="outlined" size="small" className='add-investment__field'>
//                         <InputLabel  htmlFor="title">Investment title</InputLabel>
//                         <OutlinedInput
                            
//                             id="title"
//                             type='text'
//                             name='title'
//                             value={title}
//                             onChange={this.onChange}
//                             required
//                             labelWidth={120}
//                             className='field-radius'
//                         />
//                     </FormControl>
//                     <FormControl variant="outlined" size="small" className='add-investment__field' >
//                         <InputLabel  htmlFor="investment_type">Investment type</InputLabel>
//                         <Select name='type' onChange={this.onChange} value={type}  labelId="investment_type" id="investment_type"  labelWidth={120} className='field-radius'>
//                             <MenuItem value="Normal Price">Normal Price</MenuItem>
//                             <MenuItem value="Discount Price">Discount Price</MenuItem>
//                             <MenuItem value="Early Bird">Early Bird</MenuItem>
//                             <MenuItem value="Free">Free</MenuItem>
//                         </Select>
//                     </FormControl>
//                     {/* <FormControl variant="outlined" size="small" className='add-investment__field'>
//                         <InputLabel  htmlFor="nominal_investment">Nominal Investment</InputLabel>
//                         <OutlinedInput
                            
//                             id="nominal_investment"
//                             type='number'
//                             name='nominal'
//                             disabled={type==='Free'?true:false}
//                             value={nominal}
//                             onChange={this.onChange}
//                             required
//                             labelWidth={140}
//                             className='field-radius'
//                         />
//                     </FormControl> */}
//                     <CurrencyTextField
//                     className='add-investment__field'
//                         label="Nominal"
//                         variant="outlined"
//                         value={nominal}
//                         currencySymbol="Rp"
//                         size='small'
//                         disabled={type==='Free'?true:false}

//                         //minimumValue="0"
//                         outputFormat="string"
//                         decimalCharacter="."
//                         digitGroupSeparator=","
//                         onChange={(event, value)=> this.setState({nominal:value})}
//                     />
//                     <div className='add-investment__field add-tax'>
//                         <p className='bold'>Value Added tax</p>
//                         <FormControlLabel
//                             control={
//                             <Switch
//                                 checked={added_tax}
//                                 onChange={this.switchToggle}
//                                 name="checkedB"
//                                 color="primary"
//                             />
//                             }
//                             label={<InputLabel  htmlFor="nominal_investment">Using Tax</InputLabel>}
//                         />
//                     </div>
//                     {added_tax&&
//                         <FormControl variant="outlined" size="small" className='add-investment__field'>
//                             <InputLabel  htmlFor="percentage_tax">Percentage Tax</InputLabel>
//                             <OutlinedInput
                                
//                                 id="percentage_tax"
//                                 type='number'
//                                 name='ppnpercent'
//                                 value={ppnpercent}
//                                 onChange={this.onChange}
//                                 required
//                                 labelWidth={120}
//                                 className='field-radius'
//                                 endAdornment={<InputAdornment position="end">%</InputAdornment>}
//                                 // aria-describedby="outlined-weight-helper-text"
//                                 inputProps={{
//                                 'aria-label': 'weight',
//                                 }}
//                             />
//                         </FormControl>
//                     }
//                 </MuiThemeProvider>
//                 <div className='card-footer'>
//                     {modal_action==='add_investment'?
//                     <Button onClick={this.addInvestment}  size='small' color='primary' variant='contained' className='head-add-section__btn'>Add</Button>
//                     : 
//                     <Button onClick={this.updateInvestment}  size='small' color='primary' variant='contained' className='head-add-section__btn'>Update</Button>
//                     } 
//                 </div>
//             </div>
//         )
//     }
// }
// const mapStateToProps=(state)=>({
//     event:state.event,
//     general:state.general
// })
// const mapDispatchToProps={
//     setInvestment,
//     modalToggleReset
// }
// export default connect(mapStateToProps,mapDispatchToProps)(investment)