import * as actionTypes from "../constants/cdhx";

import { apiCall } from "service/apiCall";
import {
  setLoading,
  setLoadingTable,
  setError,
  modalToggle,
  setLoadingTable2,
} from "./general";
import { get } from "lodash";
import { URL, USER, PASS, REVALIDATE_URL } from "service/base_url";
import Cookie from "universal-cookie";
import moment from "moment";
const cookie = new Cookie();
const token = cookie.get("login_cookie");
const profile = cookie.get("profile_cookie");
export const setAlumniEmail = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ALUMNI_EMAIL,
    payload: data,
  });
};
export const resetAlumniUpload = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.RESET_ALUMNI_UPLOAD,
    payload: data,
  });
};
export const setAlumniDetail = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ALUMNI_DETAIL,
    payload: data,
  });
};
export const setAlumniUpload = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ALUMNI_UPLOAD,
    payload: data,
  });
};
export const setCourseId = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_COURSE_ID,
    payload: data,
  });
};
export const setParticipant = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_PARTICIPANT,
    payload: data,
  });
};
export const setBannerImage = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_BANNER_IMAGE,
    payload: data,
  });
};

export const postCounter = (token, data) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/counter`,
    method: "POST",
    data: {
      headers: { Authorization: `Bearer ${token}` },
      data,
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 204) {
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Update Home page success",
        modal_component: "post_caption",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Home page cdhx.gmlperformance.com successfully updated</p> `,
        },
        modal_action: "success",
      })
    );
  }
};
export const getCounter = (token, data) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/Event/counter`,
    method: "GET",
    data: {
      auth: { username: USER, password: PASS },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoading(false));
    dispatch(setParticipant(res.data));
  }
};
export const revalidateInsight = () => async (dispatch) => {
  let res = await fetch(REVALIDATE_URL);
  //   let res = await dispatch(apiCall(dataReq));
  //   return res;
  if (res) {
    return;
  }
  //   return;
};
export const postBanner = (token, data) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/banner`,
    method: "POST",
    data: {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data,
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    await dispatch(revalidateInsight());
    dispatch(getBanner());
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Banner image",
        modal_component: "post_caption",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Banner image ${res.data.filename} successfully saved</p> `,
        },
        modal_action: "success",
      })
    );
  }
};
export const putBanner = (token, data) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/banner`,
    method: "PUT",
    data: {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data,
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 204) {
    await dispatch(revalidateInsight());
    dispatch(getBanner());
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Banner image",
        modal_component: "post_caption",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Banner image successfully updated</p> `,
        },
        modal_action: "success",
      })
    );
  }
};

export const getBanner =
  (slug = "/1/5") =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let dataReq = {
      url: `/Event/banner/cdhx/2${slug}`,
      method: "GET",
      data: {
        auth: { username: USER, password: PASS },
      },
    };
    let res = await dispatch(apiCall(dataReq));
    if (get(res, "status") == 200) {
      let new_banner = [];
      res.data.banners.map((data) => {
        new_banner.push({ ...data, id: `banner-${data.bannerId}` });
      });
      dispatch(setLoading(false));
      dispatch(setBannerImage(new_banner));
      dispatch({
        type: actionTypes.SET_BANNER_PAGINATION,
        payload: res.data.info,
      });
    }
  };

export const deleteBanner = (token, slug) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/banner${slug}`,
    method: "DELETE",
    data: {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    await dispatch(revalidateInsight());
    dispatch(getBanner());
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Banner image",
        modal_component: "post_caption",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Banner image ${res.data.filename} successfully deleted in our system</p> `,
        },
        modal_action: "success",
      })
    );
  }
};
export const moveBanner = (token, slug) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/Event/banner/up${slug}`,
    method: "GET",
    data: {
      headers: { Authorization: `Bearer ${token}` },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 204) {
    await dispatch(revalidateInsight());
    dispatch(getBanner());
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Banner image",
        modal_component: "post_caption",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Banner image successfully updated in our system</p> `,
        },
        modal_action: "success",
      })
    );
  }
};
export const publishBanner = (token, slug) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/Event/banner/publish${slug}`,
    method: "GET",
    data: {
      headers: { Authorization: `Bearer ${token}` },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    await dispatch(revalidateInsight());
    dispatch(getBanner());
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Banner image",
        modal_component: "post_caption",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Banner image successfully updated in our system</p> `,
        },
        modal_action: "success",
      })
    );
  } else {
    dispatch(setLoading(false));
  }
};
export const getCourse =
  (slug = "/1/5") =>
  async (dispatch) => {
    dispatch(setLoading(true));
    let dataReq = {
      url: `/Event/intropage/0/1/1/5/* `,
      method: "GET",
      data: {
        auth: { username: USER, password: PASS },
      },
    };
    let res = await dispatch(apiCall(dataReq));
    if (get(res, "status") == 200) {
      dispatch(setLoading(false));
      let new_course = [];
      res.data.events.map((data) => {
        new_course.push({
          createdDate: data.webEvent.createdDate,
          id: data.webEvent.id,
          label: `${data.webEvent.title} (Periode ${moment(
            data.webEvent.fromDate
          ).format("DD MMM YYYY")})`,
        });
      });
      dispatch({
        type: actionTypes.GET_COURSE,
        payload: new_course,
      });
    }
  };
export const getCourseSearch = (slug) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/Event/intropage/0/1/1/100/${slug} `,
    method: "GET",
    data: {
      auth: { username: USER, password: PASS },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoading(false));
    let new_course = [];
    res.data.events.map((data) => {
      new_course.push({
        createdDate: data.webEvent.createdDate,
        id: data.webEvent.id,
        label: `${data.webEvent.title} (Periode ${moment(
          data.webEvent.fromDate
        ).format("DD MMM YYYY")})`,
      });
    });
    dispatch({
      type: actionTypes.GET_COURSE,
      payload: new_course,
    });
  }
};
export const getAlumni = (slug) => async (dispatch) => {
  dispatch(setLoadingTable(true));
  let dataReq = {
    url: `/event/list/alumni${slug}`,
    method: "GET",
    data: {
      headers: { Authorization: `Bearer ${token}` },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoadingTable(false));
    dispatch({
      type: actionTypes.GET_ALUMN,
      payload: {
        alumni: res.data.items,
        pagination: res.data.info,
      },
    });
  }
};
export const getExpert = (slug) => async (dispatch) => {
  dispatch(setLoadingTable2(true));
  let dataReq = {
    url: `/event/list/expert${slug}`,
    method: "GET",
    data: {
      headers: { Authorization: `Bearer ${token}` },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoadingTable2(false));

    dispatch({
      type: actionTypes.GET_EXPERT,
      payload: {
        expert: res.data.items,
        pagination: res.data.info,
      },
    });
  }
};

export const postAlumni = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/alumni`,
    method: "POST",
    data: {
      headers: { Authorization: `Bearer ${token}` },
      data,
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Alumni",
        modal_component: "post_alumni",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Alummni succesfully added</p> `,
        },
        modal_action: "success",
      })
    );
    return res;
  }
};
export const putAlumni = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/alumni/${data.id}`,
    method: "PUT",
    data: {
      headers: { Authorization: `Bearer ${token}` },
      data,
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Alumni",
        modal_component: "put_alumni",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Alummni succesfully updated</p> `,
        },
        modal_action: "success",
      })
    );
    return res;
  }
};
export const deleteAlumni = (slug) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/alumni${slug}`,
    method: "DELETE",
    data: {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 204) {
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Alumni",
        modal_component: "delete_alumni",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Alumni successfully deleted in our system</p> `,
        },
        modal_action: "success",
      })
    );
    return res;
  }
};
export const deleteCourse = (slug) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/alumni${slug}`,
    method: "DELETE",
    data: {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 204) {
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Course",
        modal_component: "delete_course",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Course successfully deleted in our system</p> `,
        },
        modal_action: "success",
      })
    );
    return res;
  }
};
export const getClient = (slug) => async (dispatch) => {
  // dispatch(setLoading(true))
  let dataReq = {
    url: `/clients/search/${slug}`,
    method: "GET",
    data: {
      headers: { Authorization: `Bearer ${token}` },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    // dispatch(setLoading(false))
    dispatch({
      type: actionTypes.GET_CLIENT,
      payload: res.data,
    });
    return res.data;
  }
};
export const getEmail = (slug) => async (dispatch) => {
  // dispatch(setLoading(true))
  let dataReq = {
    url: `/event/email${slug}`,
    method: "GET",
    data: {
      headers: { Authorization: `Bearer ${token}` },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    // dispatch(setLoading(false))
    dispatch(setAlumniEmail({ email: res.data.text }));
    dispatch(setAlumniEmail({ emailSubject: res.data.subject }));
    return res.data;
  }
};

export const postExpert = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/expert`,
    method: "POST",
    data: {
      headers: { Authorization: `Bearer ${token}` },
      data,
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Expert",
        modal_component: "post_expert",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Expert succesfully added</p> `,
        },
        modal_action: "success",
      })
    );
    return res;
  }
};
export const putExpert = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/expert/${data.id}`,
    method: "PUT",
    data: {
      headers: { Authorization: `Bearer ${token}` },
      data,
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Expert",
        modal_component: "update_expert",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Expert succesfully updated</p> `,
        },
        modal_action: "success",
      })
    );
    return res;
  }
};

export const deleteExpert = (slug) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/expert${slug}`,
    method: "DELETE",
    data: {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 204) {
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Expert",
        modal_component: "delete_expert",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Expert successfully deleted in our system</p> `,
        },
        modal_action: "success",
      })
    );
    return res;
  }
};

export const uploadAlumni = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/upload`,
    method: "POST",
    data: {
      headers: { Authorization: `Bearer ${token}` },
      data,
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(resetAlumniUpload());
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Alumni",
        modal_component: "upload_alumni",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Alumni succesfully added</p> `,
        },
        modal_action: "success",
      })
    );
    return res;
  }
};

export const getDetailAlumni = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/alumni/${id}`,
    method: "GET",
    data: {
      headers: { Authorization: `Bearer ${token}` },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoading(false));
    dispatch(setAlumniDetail({ id: res.data.id }));
    dispatch(setAlumniDetail({ clientId: res.data.company }));
    dispatch(setAlumniDetail({ name: res.data.name }));
    dispatch(setAlumniDetail({ email: res.data.email }));
    dispatch(setAlumniDetail({ phone: res.data.phone }));
    dispatch(setAlumniDetail({ department: res.data.department }));
    dispatch(setAlumniDetail({ jobTitle: res.data.jobTitle }));
    dispatch(setAlumniDetail({ events: res.data.events }));
    return res;
  }
};

export const postEmail = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/email`,
    method: "POST",
    data: {
      headers: { Authorization: `Bearer ${token}` },
      data,
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 204) {
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Email",
        modal_component: "post_email",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Email succesfully updated</p> `,
        },
        modal_action: "success",
      })
    );
    return res;
  }
};
