import React, { Component } from 'react'
import {Button,Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
import Edit from 'assets/icon/edit.svg'
import Close from 'assets/icon/close.svg'
import {connect} from 'react-redux'
import {modalToggle} from 'redux/actions/general'
import NumberFormat from 'react-number-format';
class investment extends Component {
    addInvestment=()=>{
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "Investment",
            modal_component: "investment",
            modal_data:null ,
            modal_size:350,
            modal_action:'add_investment'
        })
    }
    editInvestment=(data,i)=>{
        console.log('data',data)
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "Investment",
            modal_component: "investment",
            modal_data:{...data,index:i} ,
            modal_size:350,
            modal_action:'edit_investment'

        })

    }
    deleteInvestment=(id,i,plus1)=>{
        const {event}=this.props
        let new_investment=[]

        // let investment=event.investment.filter((data)=>{
        //     return data.id!==id
        // })
        // investment.map((data,i)=>{
        //     new_investment.push({id:i,ppn:data.ppn?1:0,title:data.title,type:data.type,nominal:data.nominal,ppnpercent:data.ppnpercent})
        // })
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: `Investment`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:`investment ${plus1}`,
                // investment:new_investment,
                index:i,
                msg:`<p>Are you sure delete <b>investment ${plus1}</b></p>`

            },
            modal_action:'delete_investment'
        })
       
        // this.props.setAgenda(new_agenda)
    }
    render() {
        const {event}=this.props
        return (
            <div>
                <div className='subcontent-container'>
                    <p className='bold'>Investment</p>
                    <Button onClick={this.addInvestment} disabled={this.props.handleDisable()}  size='small' color='secondary' variant='contained' className='head-section__btn'>
                        Add new investment
                    </Button>
                </div>
                <br/>
                <Table  size="small" aria-label="a dense table" >
                    <TableHead>
                    <TableRow>
                        <TableCell align="left">Investment Title</TableCell>
                        <TableCell align="left">Investment type</TableCell>
                        <TableCell align="left">Nominal investment</TableCell>
                        <TableCell align="left">Value added Tax</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {event.investment.length>0&&event.investment.map((data,i)=>(
                            <TableRow>
                            <TableCell align="left">{data.title}</TableCell>
                                <TableCell align="left">{data.type}</TableCell>
                                <TableCell align="left"><NumberFormat value={data.nominal} displayType={'text'} thousandSeparator={true} prefix={'Rp'} /></TableCell>
                                <TableCell align="left">{data.ppnpercent}%</TableCell>
                                <TableCell align="right">
                                    <img src={Edit} onClick={()=>this.props.handleDisable()?null:this.editInvestment(data,i)} className='icon-action'/>
                                    <img src={Close} onClick={()=>this.props.handleDisable()?null:this.deleteInvestment(data.id,i,i+1)} className='icon-action'/>
                                </TableCell>
                            </TableRow>
                        ))}
                        {event.investment.length<1&&
                        <TableRow>
                            <TableCell colSpan={5} style={{textAlign:'center'}}>
                            <Button disabled={this.props.handleDisable()} color='secondary' size='small' className='btn-remove-capital' onClick={this.addInvestment}>Add investment</Button>
                            </TableCell>
                        </TableRow>
                        }
                        {/* <TableRow>
                            <TableCell style={{maxWidth:255,minHeight:100,}}>Harnessing the Power of HR Analytics: Boosting Organization Performance with Data Analytics</TableCell>
                            <TableCell>2-3 June 2020</TableCell>
                            <TableCell>Mega Seminar</TableCell>
                            <TableCell align="right">
                                <img src={Edit} className='icon-action'/>
                                <img src={Close} className='icon-action'/>
                            </TableCell>
                        </TableRow> */}
                    </TableBody>
                </Table>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    event:state.event,
    general:state.general
})
const mapDispatchToProps={
    modalToggle
}
export default connect(mapStateToProps,mapDispatchToProps)(investment)