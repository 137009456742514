import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import { connect } from "react-redux";
import { modalToggleReset } from "redux/actions/general";
import { setSpeaker } from "redux/actions/event";
import { handleFile, getBase64 } from "components/handleFile";
import image_line from "assets/icon/image_line.svg";
import { useSelector, useDispatch } from "react-redux";
import { postSpeaker, putSpeaker } from "redux/actions/speaker";
const themeField = createMuiTheme({
  palette: {
    primary: {
      main: "#00acc1",
    },
  },
});
export default function Speaker_add(props) {
  const general = useSelector((state) => state.general);
  const { profile } = props;
  const { modal_action, modal_data } = general;
  const dispatch = useDispatch();
  const [state, setstate] = useState({
    id: 0,
    name: "",
    title: "",
    company: "",
    profile: "",
    profileFilename: "",
    file_error: "",
  });
  useEffect(() => {
    if (modal_data.data) {
      setstate(modal_data.data);
    }
  }, []);
  // console.log("state", state);
  const onChange = (e) => {
    const { value, name } = e.target;
    setstate({ ...state, [name]: value });
  };
  const { id, name, title, company, file_error, profileFilename } = state;
  let data = {
    id,
    name,
    title,
    company,
    userId: profile.id,
    profile: state.profile,
    profileFilename,
  };
  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const handleProfile = (evt) => {
    let handle = handleFile(evt);
    if (handle.file_error === null) {
      let file_url = URL.createObjectURL(handle.file);
      getBase64(handle.file, (result) => {
        setstate({
          ...state,
          profile: result,
          profileFilename: handle.file_name,
        });
        // this.setState({ profile: result, profileFilename: handle.file_name });
      });
    } else {
      console.log("err", handle);
      setstate({
        ...state,
        file_error: handle.file_error,
      });
      // this.setState({ file_error: handle.file_error });
    }
  };
  const handleDisabled = () => {
    if (
      name !== "" &&
      title !== "" &&
      company !== ""
      // profileFilename !== ""
    ) {
      return false;
    } else {
      return true;
    }
  };
  const onSave = async () => {
    await dispatch(postSpeaker(data));
    modal_data.callback();
  };
  const onUpdate = async () => {
    await dispatch(putSpeaker(data));
    modal_data.callback();
  };
  return (
    <div>
      {" "}
      <MuiThemeProvider theme={themeField}>
        <FormControl
          variant="outlined"
          size="small"
          className="add-investment__field"
        >
          <InputLabel htmlFor="speaker_name">Speaker name</InputLabel>
          <OutlinedInput
            id="speaker_name"
            type="text"
            name="name"
            value={name}
            onChange={onChange}
            required
            labelWidth={110}
            className="field-radius"
          />
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          className="add-investment__field"
        >
          <InputLabel htmlFor="speaker_job_title">Speaker job title</InputLabel>
          <OutlinedInput
            id="speaker_job_title"
            type="text"
            name="title"
            value={title}
            onChange={onChange}
            required
            labelWidth={120}
            className="field-radius"
          />
        </FormControl>
        <FormControl
          variant="outlined"
          size="small"
          className="add-investment__field"
        >
          <InputLabel htmlFor="company">Company</InputLabel>
          <OutlinedInput
            id="company"
            type="text"
            name="company"
            value={company}
            onChange={onChange}
            required
            labelWidth={80}
            className="field-radius"
          />
        </FormControl>
        {profileFilename === "" || profileFilename === null ? (
          <Button
            className="btn-remove-capital"
            variant="text"
            startIcon={<img src={image_line} />}
            color="primary"
            component="label"
          >
            Add photo here
            <input
              type="file"
              style={{ display: "none" }}
              onChange={handleProfile}
            />
          </Button>
        ) : (
          <p style={{ overflowWrap: "break-word" }}>
            {profileFilename}&nbsp;&nbsp;
            <Button
              component="label"
              color="primary"
              className="btn-remove-capital"
              variant="text"
            >
              Change
              <input
                type="file"
                style={{ display: "none" }}
                onChange={handleProfile}
              />
            </Button>
          </p>
        )}
      </MuiThemeProvider>
      <div className="card-footer">
        {modal_action === "add_speaker" ? (
          <Button
            onClick={onSave}
            size="small"
            color="primary"
            variant="contained"
            className="head-add-section__btn"
            disabled={handleDisabled()}
          >
            Add
          </Button>
        ) : (
          <Button
            onClick={onUpdate}
            size="small"
            color="primary"
            variant="contained"
            className="head-add-section__btn"
            disabled={handleDisabled()}
          >
            Update
          </Button>
        )}
      </div>
    </div>
  );
}
