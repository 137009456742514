import React, { Component } from 'react'
import '../style.css'
import {Button,FormControl,InputLabel,OutlinedInput,InputAdornment} from '@material-ui/core'
import Drafted from './list_drafted'
import Published from './list_published'
import SearchImg from 'assets/icon/Search.png'
import {getEvent,getDetailEvent,setAction,clearState,tabToggle,setSearch,getCategory,getType,getLocation} from 'redux/actions/event'
import {connect} from 'react-redux'
import {debounce ,isEmpty} from 'lodash'

import {modalToggle} from 'redux/actions/general'
class list_event extends Component {
    state={
        active_tab:'drafted',
        drafted:[],
        published:[],
        search:''
    }
    componentDidMount(){
        this.props.getCategory()
        this.props.getType()
        this.props.getLocation()
    }
    getEvent=(slug,key,search='*')=>{
        console.log('adudu',slug,key,search)
        const {event}=this.props
        // let {search}=this.state
        
        this.props.getEvent(`${slug}/${search}`,key)
    }
    searchByType=debounce(async(value)=>{
        let {search}=this.state
        let {active_tab}=this.props.event
        this.getEvent(`/0/${active_tab==='drafted'?0:1}/1/10`,active_tab,value.length>0?value:'*','loading-table')
    },1000)
    searchToggle=async(e)=>{
        let {value}=e.target
        let {active_tab,search}=this.state
        const {event}=this.props
        
        await this.props.setSearch(value)
        if(event.search.length>0){
            this.searchByType(value)
        }
        
    }
    tabToggle=(key)=>{
        this.setState({active_tab:key})
        this.props.tabToggle(key)
    }
    detailEvent=async (id)=>{
        let res=await this.props.getDetailEvent(id)
        if(res){
            this.props.tabsToggle('add_event')
            this.props.setAction('add_event')
        }
    }
    seeEventOnly=async(id)=>{
        let res=await this.props.getDetailEvent(id)
        if(res){
            this.props.tabsToggle('add_event')
            this.props.setAction('see_event')
        }
    }
    
    onClickAdd=()=>{
        this.props.tabsToggle('add_event')
        this.props.setAction('add_event')
        this.props.clearState()
    }
    deleteEvent=(data)=>{
        console.log('data',data)
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "list event",
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                ...data.webEvent,
                msg:`<p>Are you sure delete <b>${data.webEvent.title}</b></p>`
            },
            modal_action:'delete_event'
        })
    
    }
    unPublish=(data)=>{
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "list event",
            modal_component: "confirm_unpublish",
            modal_size:400,
            modal_type:'confirm',
            modal_data:data.webEvent,
            modal_action:'unpublish_event',
            success_msg:'See event list'
        })
    }
    render() {
        let {drafted,published,search}=this.state
        let {tabToggle,event,general}=this.props

        return (
            <div>
                <div className='head-section'>
                    <div>
                        <h3>Event Management</h3>
                    </div>
                    <div>
                        <Button onClick={this.onClickAdd} size='small' color='secondary' variant='contained' className='head-section__btn'>Create new event</Button>
                    </div>
                </div>
                <div className='card-content'>
                    <div className='card-list'>
                   <div className='card-tab-cms-list'>
                       <li onClick={()=>this.tabToggle('drafted')} className={event.active_tab==='drafted'?'card-tab-cms__list card-tab-cms__list-active':'card-tab-cms__list'}>Drafted</li>
                       <li onClick={()=>this.tabToggle('published')} className={event.active_tab==='published'?'card-tab-cms__list card-tab-cms__list-active':'card-tab-cms__list'}>Published</li>
                   </div>
                   
                   <div className='card-table'>
                       <div className='card-table__head'>
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="input-with-icon-textfield">Search</InputLabel>
                                <OutlinedInput
                                    size='small'
                                    style={{height:30,width:200,}}
                                    id="input-with-icon-textfield"
                                    name='password'
                                    onChange={this.searchToggle}
                                    required
                                    value={event.search}
                                    startAdornment={
                                    <InputAdornment position="start">
                                       <img alt="search" src={SearchImg} style={{width:15}}/>
                                    </InputAdornment>
                                    }
                                    labelWidth={50}
                                />
                            </FormControl>
                            <div>
                                {/* <button className='card-table__head_btn'>Filter</button>
                                <button className='card-table__head_btn'>Sort</button>
                                 */}
                            </div>
                       </div>
                       <div className='card-table__content'>
                            {event.active_tab==='drafted'&&<Drafted isLoadingTable={general.isLoadingTable}  getEvent={this.getEvent} seeEventOnly={this.seeEventOnly} deleteEvent={this.deleteEvent} unPublish={this.unPublish} detailEvent={this.detailEvent} event={event}/>}
                            {event.active_tab==='published'&&<Published isLoadingTable={general.isLoadingTable} getEvent={this.getEvent} seeEventOnly={this.seeEventOnly} deleteEvent={this.deleteEvent} unPublish={this.unPublish} detailEvent={this.detailEvent} event={event}/>}
                       </div>
                       
                   </div>
                </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps=state=>({
    event:state.event,
    general:state.general
})
const mapDispatchToProps={
    getEvent,
    getDetailEvent,
    setAction,
    modalToggle,
    clearState,
    tabToggle,
    setSearch,
    getCategory,getType,getLocation
}
export default connect(mapStateToProps,mapDispatchToProps)(list_event)