export const handleFile=(evt,key)=>{
    const file = evt.target.files[0];
        const fileTypesImage = ['png','jpg','jpeg'];
        const fileTypesWithPdf = ['png','jpg','jpeg','pdf'];
        const onlyxlx=['xlxs','xls','csv']
        console.log(file)
        if (evt.target.files && file) {
            const extension = evt.target.files[0].name.split('.').pop().toLowerCase();
            const isSuccess = key==='brochure'?fileTypesWithPdf:key==='alumni'?onlyxlx:fileTypesImage.indexOf(extension) > -1;
            console.log('is',isSuccess)
            if (isSuccess) {
                let data={
                    file:file,
                    file_name:file.name,
                    file_error:null
                }
                
                return data
            }
            else {
                let data={
                    file:null,
                    file_name:null,
                    file_error:key==='brochure'?'File type not allowed. File type to be must *.jpg *.jpeg *.png *.pdf':'File type not allowed. File type to be must *.jpg *.jpeg *.png'
                }
                return data
            }
        }
}
export const getBase64=(file, cb)=> {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

export const decodeBase64=async(url,file_name,cb)=>{
        
    let file=fetch(url)
        .then(res => res.blob())
        .then(blob => {
            const file = new File([blob], file_name,{ type: "image/png" })
            
            return file
        })
    return file
}