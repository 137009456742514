const config = {
  // URL:'https://cors-anywhere.herokuapp.com/api.onegml.com/v1',
  // URL: "http://testapi.onegml.com/v1",
  URL: "https://api.onegml.com/v1",
  USER: "onegmlapi",
  PASS: "O1n6e0G4M7L",
  REVALIDATE_URL: "https://cdhx.gmlperformance.com/api/revalidate",
};

export const { URL, REVALIDATE_URL, USER, PASS } = config;
