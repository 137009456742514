import * as actionTypes from '../constants/preview'

export const previewSetTitle=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_TITLE,
        payload:data
    })
}

export const previewSetStartDate=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_START_DATE,
        payload:data
    })
}
export const previewSetEndDate=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_END_DATE,
        payload:data
    })
}
export const previewSetStartTime=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_START_TIME,
        payload:data
    })
}
export const previewSetEndTime=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_END_TIME,
        payload:data
    })
}
export const previewSetAddress=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_ADDRESS,
        payload:data
    })
}
export const previewSetCategory=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_CATEGORY,
        payload:data
    })
}
export const previewSetStatus=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_STATUS,
        payload:data
    })
}
export const previewSetTarget=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_TARGET,
        payload:data
    })
}
export const previewSetShortDesc=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_SHORT_DESC,
        payload:data
    })
}
export const previewSetThumbnail=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_THUMBNAIL,
        payload:data
    })
}
export const previewSetBrochure=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_BROCHURE,
        payload:data
    })
}
export const previewSetDescription=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_DESCRIPTION,
        payload:data
    })
}
export const previewSetAdditional=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_ADDITIONAL,
        payload:data
    })
}
export const previewSetFramework=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_FRAMEWORK,
        payload:data
    })
}
export const previewSetTestimony=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_TESTIMONY,
        payload:data
    })
}
export const previewSetAgenda=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_AGENDA,
        payload:data
    })
}
export const previewSetInvestment=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_INVESTMENT,
        payload:data
    })
}
export const previewSetSpeaker=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_SPEAKER,
        payload:data
    })
}
export const previewSetIdEvent=(data)=>dispatch=>{
    dispatch({
        type:actionTypes.PREVIEW_SET_ID,
        payload:data
    })
}