import * as actionTypes from "../constants/speaker";

import { apiCall } from "service/apiCall";
import { setLoading, setLoadingTable, setError, modalToggle } from "./general";
import { get } from "lodash";
import { URL, USER, PASS } from "service/base_url";
import Cookies from "universal-cookie";
const cookie = new Cookies();
const token = cookie.get("login_cookie");
export const getSpeakers = (slug) => async (dispatch) => {
  dispatch(setLoadingTable(true));
  let dataReq = {
    url: `/event/list/speaker/${slug}`,
    method: "GET",
    data: {
      headers: { Authorization: `Bearer ${token}` },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoadingTable(false));
    dispatch({
      type: actionTypes.GET_SPEAKERS,
      payload: res.data,
    });
  }
};
export const postSpeaker = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/speaker`,
    method: "POST",
    data: {
      headers: { Authorization: `Bearer ${token}` },
      data,
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Add Speaker",
        modal_component: "",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Speaker successfully added</p> `,
        },
        modal_action: "success",
      })
    );
    return;
  }
};

export const putSpeaker = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/speaker/${data.id}`,
    method: "PUT",
    data: {
      headers: { Authorization: `Bearer ${token}` },
      data,
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Update Speaker",
        modal_component: "",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Speaker successfully updated</p> `,
        },
        modal_action: "success",
      })
    );
    return;
  }
};

export const deleteSpeaker = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/speaker/${data.id}/${data.userId}`,
    method: "DELETE",
    data: {
      headers: { Authorization: `Bearer ${token}` },
      data,
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Delete Speaker",
        modal_component: "",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Speaker successfully deleted</p> `,
        },
        modal_action: "success",
      })
    );
    return;
  }
};

export const getDetailSpeakers = (id) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/event/speaker/${id}`,
    method: "GET",
    data: {
      headers: { Authorization: `Bearer ${token}` },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoading(false));
    return res.data;
  }
};
