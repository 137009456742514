import React, { Profiler, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import Layout from "components/Layouts";
import SearchImg from "assets/icon/Search.png";
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import Eye from "assets/icon/eye.svg";
import Edit from "assets/icon/edit.svg";
import Close from "assets/icon/close.svg";
import Skeleton from "components/Skeleton";

import { useDispatch, useSelector } from "react-redux";
import { modalToggle } from "redux/actions/general";
import { getSpeakers, deleteSpeaker } from "redux/actions/speaker";
import { debounce } from "lodash";
export default function Index(props) {
  const dispatch = useDispatch();
  const speaker = useSelector((state) => state.speaker);
  const general = useSelector((state) => state.general);
  const { profile } = props;
  const [search, setSearch] = useState("");
  useEffect(() => {
    dispatch(getSpeakers("*"));
  }, []);
  const searchToggle = debounce(async (value) => {
    setSearch(value);
    await dispatch(getSpeakers(value !== "" ? value : "*"));
  }, 1000);

  const addSpeaker = () => {
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Speaker",
        modal_component: "speaker_add",
        modal_data: null,
        modal_size: 350,
        modal_action: "add_speaker",
        modal_data: {
          callback: () => dispatch(getSpeakers("*")),
        },
      })
    );
  };
  const editSpeaker = (data) => {
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Speaker",
        modal_component: "speaker_add",
        modal_data: null,
        modal_size: 350,
        modal_action: "edit_speaker",
        modal_data: {
          callback: () => dispatch(getSpeakers("*")),
          data,
        },
      })
    );
  };
  const actionDelete = async (data) => {
    await dispatch(deleteSpeaker({ id: data.id, userId: profile.id }));
    dispatch(getSpeakers("*"));
  };
  const deleteSpeakers = (data) => {
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: `list`,
        modal_component: "confirm_delete",
        modal_size: 400,
        modal_type: "confirm",
        modal_data: {
          ...data,
          msg: `<p>Are you sure delete <b>${data.name}</b></p>`,
          action: () => actionDelete(data),
        },
        modal_action: "",
      })
    );
  };
  return (
    <div>
      <Layout>
        <div className="head-section">
          <div>
            <h3>Speakers Management</h3>
          </div>
          <div>
            <Button
              onClick={addSpeaker}
              size="small"
              color="secondary"
              variant="contained"
              className="head-section__btn"
            >
              Create Speaker
            </Button>
          </div>
        </div>
        <div className="card-content">
          <div className="card-list">
            <div className="card-table">
              <div className="card-table__head">
                <FormControl variant="outlined">
                  <InputLabel htmlFor="input-with-icon-textfield">
                    Search
                  </InputLabel>
                  <OutlinedInput
                    size="small"
                    style={{ height: 30, width: 200 }}
                    id="input-with-icon-textfield"
                    name="password"
                    onChange={(e) => searchToggle(e.target.value)}
                    // value={event.search}
                    startAdornment={
                      <InputAdornment position="start">
                        <img
                          alt="search"
                          src={SearchImg}
                          style={{ width: 15 }}
                        />
                      </InputAdornment>
                    }
                    labelWidth={50}
                  />
                </FormControl>
                <div>
                  {/* <button className='card-table__head_btn'>Filter</button>
                                <button className='card-table__head_btn'>Sort</button>
                                 */}
                </div>
              </div>
              <div className="card-table__content">
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Name </TableCell>
                      <TableCell align="left">Job Title</TableCell>
                      <TableCell align="left">Company</TableCell>
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {general.isLoadingTable !== true ? (
                      speaker.speakers.map((data, i) => (
                        <TableRow key={i}>
                          <TableCell style={{ maxWidth: 255, minHeight: 100 }}>
                            {data.name}
                          </TableCell>
                          <TableCell>{data.title}</TableCell>
                          <TableCell>{data.company}</TableCell>
                          <TableCell align="right">
                            <img
                              src={Edit}
                              className="icon-action"
                              onClick={() => editSpeaker(data)}
                            />
                            <img
                              src={Close}
                              onClick={() => deleteSpeakers(data)}
                              className="icon-action"
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <Skeleton count={4} />
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
