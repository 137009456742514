import React ,{useState}from 'react'
import { TextField,Button } from '@material-ui/core'
import InputMask from 'react-input-mask'
import AutoCompleteSelect from 'components/Select'
import { modalToggle } from 'redux/actions/general'
import { useDispatch,useSelector } from 'react-redux'
import {setAlumniEmail,getEmail,getCourseSearch,getCourse, getClient,setAlumniDetail,postAlumni,getAlumni,setCourseId } from 'redux/actions/cdhx'
import {debounce,isEmpty} from 'lodash'

import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,
      },
      width:'100%',
      marginBottom:15 
  }
  
}));
export default function Alumni(props) {
    const dispatch = useDispatch()
    const cdhx = useSelector(state => state.cdhx)
    const [textSelect,setTextSelect]=useState('Type company name')
    const [textSelect2,setTextSelect2]=useState('Type course name')
    const [loading,setLoading]=useState(false)
    const [search, setsearch] = useState('')
    const [loading2,setLoading2]=useState(false)
    const [search2, setsearch2] = useState('')
    const {alumni_detail}=cdhx
    const classes=useStyles()
    const onChange=()=>{

    }
    const selectClient=(data)=>{
        dispatch(setAlumniDetail({clientId:data}))
    }
    const searchToggle=debounce(async (value)=>{
        setsearch(value)
        setLoading(true)
        if(value!==search&&value.length>0){
            setLoading(true)
            let res=await dispatch(getClient(value))
            if(isEmpty(res)){
                setTextSelect('No options')
            }
            setLoading(false)
        }
        
    },1000)
    let {clientId,eventId,name,email,phone,department,jobTitle}=alumni_detail
    const handleDisable=()=>{
        if(clientId===null||eventId===null||name===''||email===''||phone===''||department===''||jobTitle===''){
            return true
        }else{
            return false
        }
    }
    const save=async ()=>{

        let data={
            id:0,
            clientId:clientId.id,
            name,
            email,
            phone,
            department,
            eventId:eventId.id,
            jobTitle,
            userId:props.profile.id
        }
        let res=await dispatch(postAlumni(data))
        if(res){
            dispatch(getEmail(`/${eventId.id}/${props.profile.id}`))
            dispatch(setAlumniEmail({alumni:res.data}))
            dispatch(setCourseId(eventId))
            dispatch(getAlumni(`/${eventId.id}/1/10/*`))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Template Email",
                modal_component: "email_template",
                modal_size:1050,
                modal_data:null,
            }))
        }
        
    }
    const onChangeCourse=debounce(async (value)=>{
        let findCourse=cdhx.course_list.filter((data)=>{
            return data.label.toLowerCase().includes(value.toLowerCase())
        })
        if(isEmpty(findCourse)){
            setsearch2(value)

            if(value!==search2&&value.length>0){
                setLoading2(true)
                let res=await dispatch(getCourseSearch(value))
                if(isEmpty(res)){
                    setTextSelect2('No options')
                }
                setLoading2(false)
            }else{
                dispatch(getCourse())
            }
        }
        
    },1000)
    return (
        <div>
            {/* <p className='bold'>Please fill out the form below to join the course</p>
            <br/> */}
            <TextField
                label={<>Name <span style={{color:'red'}}>*</span></>}
                variant='outlined'
                // value={event.title}
                size='small'
                // disabled={handleDisable()}
                name="name"
                className={classes.textField}
                onChange={(e)=>dispatch(setAlumniDetail({name:e.target.value}))}

            />
             <AutoCompleteSelect
                color='secondary'
                noOptionsText={textSelect} 
                loading={loading}
                disableClearable={true}
                onChange={(event,value)=>selectClient(value)}
                options={cdhx.client}
                getOptionLabel={(option) => option.text}
                onInputChange={(event,e)=>searchToggle(e)}
                label={<>Company Name <span style={{color:'red'}}>*</span></>}
            />
            <TextField
                label={<>Department <span style={{color:'red'}}>*</span></>}
                variant='outlined'
                // value={event.title}
                size='small'
                // disabled={handleDisable()}
                name="department"
                className={classes.textField}
                onChange={(e)=>dispatch(setAlumniDetail({department:e.target.value}))}

            />
            <TextField
                label={<>Job Title <span style={{color:'red'}}>*</span></>}
                variant='outlined'
                // value={event.title}
                size='small'
                // disabled={handleDisable()}
                name="jobTitle"
                className={classes.textField}
                onChange={(e)=>dispatch(setAlumniDetail({jobTitle:e.target.value}))}
            />
            <TextField
                label={<>Email <span style={{color:'red'}}>*</span></>}
                variant='outlined'
                // value={event.title}
                size='small'
                // disabled={handleDisable()}
                name="email"
                className={classes.textField}
                onChange={(e)=>dispatch(setAlumniDetail({email:e.target.value}))}

            />
            <InputMask
                mask="9999-9999-9999-9999"
                maskChar={null}
                onChange={(e)=>dispatch(setAlumniDetail({phone:e.target.value}))}
                name="phoneNumber"
                id="phoneNumber"

                // value={emp.phone}
            >
            {() =>
                <TextField
                    label={<>Phone No. <span style={{color:'red'}}>*</span></>}
                    variant='outlined'
                    // value={event.title}
                    size='small'
                    // disabled={handleDisable()}
                    name="phoneNumber"
                    className={classes.textField}
    
                />
            }
        </InputMask>
         {/* <AutoCompleteSelect
            color='secondary'
            disableClearable={true}
            onChange={(event,value)=>dispatch(setAlumniDetail({eventId:value}))}
            options={cdhx.course_list}
            getOptionLabel={(option) => option.label}
            label={<>Course Name <span style={{color:'red'}}>*</span></>}
        /> */}
         <AutoCompleteSelect
                color='secondary'
                noOptionsText={textSelect2}
                loading={loading2}
                value={alumni_detail.eventId}
                disableClearable={true}
                onChange={(event,value)=>dispatch(setAlumniDetail({eventId:value}))}
                options={cdhx.course_list}
                groupBy={(option)=>{
                    if(search===''){
                        return '5 Course terbaru'
                    }else{
                        return null
                    }
                }}
                getOptionLabel={(option) => option.label}
                onInputChange={(event,e)=>onChangeCourse(e)}

                label="Choose Course Name"
            />
        <div style={{textAlign:'right'}}>
            <Button disabled={handleDisable()} onClick={save}   size='small' color='primary' variant='contained' className='btn-remove-capital btn-rounded'>Registration</Button>
        </div>
        <br/>
        </div>
    )
}
