import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from 'ckeditor5-build-classic-with-upload-image'
import { Button, TextField, Switch } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Cookie from "universal-cookie";
import Editor from "ckeditor5-custom-build/build/ckeditor";

import {
  setEmailNotification,
  setEmailSubject,
  setEmail,
} from "redux/actions/event";
const cookie = new Cookie();
const token = cookie.get("login_cookie");
// ClassicEditor.defaultConfig = {
// 	toolbar: {
// 		items: [
// 			'heading',
// 			'|',
// 			'bold',
// 			'italic',
// 			'alignment',
// 			'link',
// 			'bulletedList',
// 			'numberedList',
// 			'|',
// 			'indent',
// 			'outdent',
// 			'|',
// 			'imageUpload',
// 			'mediaEmbed',
// 			'blockQuote',
// 			'insertTable',
// 			'undo',
// 			'redo',

// 		]
// 	},
// 	alignment: {
// 		options: [ 'left', 'right','center' ]
// 	},
// image: {
// 	toolbar: [
// 		'imageStyle:full',
// 		'imageStyle:side',
// 		'imageStyle:alignLeft',
// 		'imageStyle:alignCenter',
// 		'imageStyle:alignRight',
// 		'|',
// 		'imageTextAlternative'
// 	],
// 	styles:[
// 		'full',
// 		'side',
// 		'alignLeft',
// 		'alignCenter',
// 		'alignRight'
// 	]
// },
// 	table: {
// 		contentToolbar: [
// 			'tableColumn',
// 			'tableRow',
// 			'mergeTableCells'
// 		]
// 	},
// 	// This value must be kept in sync with the language defined in webpack.config.js.
// 	language: 'en',
// 	simpleUpload: {
// 		// The URL that the images are uploaded to.
// 		uploadUrl: `${URL}/upload/ckeditor`,

// 		// Headers sent along with the XMLHttpRequest to the upload server.
// 		headers: {
// 			'X-CSRF-TOKEN': 'CSFR-Token',
// 			Authorization: `Bearer ${token}`
// 		}
// 	},
// 	mediaEmbed: {previewsInData: true}

// };
const editorConfiguration = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "alignment",
    "link",
    "bulletedList",
    "numberedList",
    "|",
    "indent",
    "outdent",
    "|",
    "imageUpload",
    "mediaEmbed",
    "blockQuote",
    "insertTable",
    "undo",
    "redo",
  ],
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "imageStyle:alignLeft",
      "imageStyle:alignCenter",
      "imageStyle:alignRight",
      "|",
      "imageTextAlternative",
    ],
    styles: ["full", "side", "alignLeft", "alignCenter", "alignRight"],
  },
  simpleUpload: {
    // The URL that the images are uploaded to.
    uploadUrl: `${URL}/upload/ckeditor`,

    // Headers sent along with the XMLHttpRequest to the upload server.
    headers: {
      "X-CSRF-TOKEN": "CSFR-Token",
      Authorization: `Bearer ${token}`,
    },
  },
  mediaEmbed: { previewsInData: true },
};
const useStyles = makeStyles((theme) => ({
  textField: {
    [`& fieldset`]: {
      borderRadius: 8,
    },
    width: "100%",
    marginBottom: 15,
  },
}));
const label = { inputProps: { "aria-label": "Switch demo" } };
export default function Email(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const event = useSelector((state) => state.event);
  const [help, sethelp] = useState(false);
  let { handleDisable } = props;
  return (
    <div>
      <div className="div-flex">
        <div style={{ width: "70%" }}>
          <TextField
            label={<>Subject email</>}
            // variant='outlined'
            value={event.emailSubject}
            size="small"
            disabled={handleDisable()}
            className={classes.textField}
            onChange={(e) => dispatch(setEmailSubject(e.target.value))}
          />
          <CKEditor
            data={event.email}
            editor={Editor}
            config={editorConfiguration}
            disabled={handleDisable()}
            onChange={(event, editor) => {
              const data = editor.getData();
              dispatch(setEmail(data));
            }}
          />
          <p>Berikut merupakan tampilan yang akan peserta terima di emailnya</p>
        </div>
        &nbsp;&nbsp;
        <div style={{ width: "30%" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              className="div-flex div-align-center"
              style={{ position: "relative" }}
            >
              <p style={{ margin: 0, fontSize: 18 }}>Aktifkan Email </p>
              &nbsp;&nbsp;&nbsp;
              <div
                onMouseEnter={() => sethelp(true)}
                onMouseLeave={() => sethelp(false)}
                style={{
                  cursor: "pointer",
                  width: 15,
                  height: 15,
                  fontSize: 12,
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1.4px solid #e91e63",
                  color: "#e91e63",
                  borderRadius: "100%",
                }}
              >
                ?
              </div>
              {help && (
                <div
                  style={{
                    position: "absolute",
                    top: 40,
                    boxShadow: "0 3px 6px 0 rgb(119 119 119 / 35%)",
                    padding: 10,
                    width: 200,
                    borderRadius: 10,
                    backgroundColor: "white",
                  }}
                >
                  <p style={{ margin: 0, fontsize: 11 }}>
                    Jika fitur ini aktif maka pendaftar akan menerima email,
                    Konten email akan menyesuaikan isi konten pada bagian ini.
                  </p>
                </div>
              )}
            </div>
            <Switch
              disabled={handleDisable()}
              {...label}
              checked={event.emailNotification}
              onChange={() =>
                dispatch(setEmailNotification(!event.emailNotification))
              }
            />
          </div>
          <div className="template-email-keterangan-header">
            <p>
              <b>Keterangan</b>
            </p>
          </div>
          <div className="template-email-keterangan-body">
            <p>
              <b>[%NAME%]</b>
            </p>
            <p>Gunakan variabel [%NAME%] untuk merujuk ke nama peserta.</p>
            <p>
              <b>[%EMAIL%]</b>
            </p>
            <p>
              Gunakan variabel [%EMAIL%] untuk merujuk ke email peserta yang
              digunakan untuk akses assessment.
            </p>
            <p>
              <b>[%EVENTNAME%]</b>
            </p>
            <p>Gunakan variabel [%EVENTNAME%] untuk merujuk ke nama event.</p>
            <p>
              <b>[%DARI%] [%SAMPAI%]</b>
            </p>
            <p>
              Variabel [%DARI%] digunakan untuk merujuk awal mulai waktu akses
              dan [%SAMPAI%] untuk akhir waktu akses event
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
