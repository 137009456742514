import React, { Component } from 'react'
import {Table,TableHead,TableCell,TableRow,TableBody,Button} from '@material-ui/core'
import Eye from 'assets/icon/eye.svg'
import Edit from 'assets/icon/edit.svg'
import Close from 'assets/icon/close.svg'
import {
    
    addImage,
    getImageEvent
} from 'redux/actions/event'
import {modalToggle} from 'redux/actions/general'
import {connect} from 'react-redux'
import Skeleton from 'components/Skeleton'
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
class list_published extends Component {
    state={
        published:[],
        page:1,
        pageLength:10
    }
    componentDidMount(){
        let {event}=this.props
        let {page,pageLength}=this.state
       if(event.list_image_event_published!==null){
            this.props.getImageEvent(`/0/1/${event.published_pagination.page}/${event.published_pagination.perPage}/${event.search.length>0?event.search:'*'}`,'published')
        }else{
            this.props.getImageEvent(`/0/1/${page}/${pageLength}/*`,'published')
        }

    }
    // componentWillReceiveProps(newProps){
    //     let list_image_event=newProps.event.list_image_event
    //     this.spliceImages(list_image_event)
    // }
    // spliceImages=(list_image_event)=>{
    //     let published=[]
    //     if(list_image_event!==null){
    //         list_image_event.map((data,i)=>{
    //             if(data.publish===1){
    //                 published.push(data)
    //             }
    //         })
    //         this.setState({published})
    //     }
    // }
    togglePagination=(page,pageLength)=>{
        let {event}=this.props

        this.setState({page,pageLength},()=>{
            this.props.getImageEvent(`/0/1/${page}/${pageLength}/${event.search.length>0?event.search:'*'}`,'published')

        })

    }
    render() {
        let {published}=this.state
        let {isLoadingTable,event}=this.props
        return (
            <div>
                <Table  size="small" aria-label="a dense table">
                    <TableHead>
                    <TableRow>
                    <TableCell align="left" >Image name</TableCell>
                        <TableCell align="left">Event name</TableCell>
                        <TableCell align="left">Caption</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                         {isLoadingTable!==true&&event.list_image_event_published?event.list_image_event_published.map((data,i)=>(
                            <TableRow key={i}>
                                <TableCell style={{maxWidth:255,minHeight:100,}}>{data.imageName}</TableCell>
                                <TableCell style={{maxWidth:255,minHeight:100,}}>{data.event}</TableCell>
                                <TableCell>{data.caption.substring(0,30)}</TableCell>
                                <TableCell align="right">
                                    <button className='btn-unpublish'><img src={Eye} onClick={()=>this.props.onPreviewClick(data)}/></button>
                                    <button className='btn-unpublish' onClick={()=>this.props.unPublish(data)}>Unpublish</button>
                                </TableCell>
                            </TableRow>
                        )):<Skeleton count={4}/>}
                    </TableBody>
                </Table>
                <div className='card-table__pagination'>
                {event.published_pagination!==null&&
                <TablePagination
                        className="card-pagination"
                        type="reduced"
                        page={event.published_pagination.page}
                        pageLength={event.published_pagination.perPage}
                        totalRecords={event.published_pagination.total}
                        onPageChange={({ page, pageLength }) => {
                            this.togglePagination( page, pageLength )
                        }}
                        prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                        nextPageRenderer={() => <img src={CevronRight}/>}
                    />
                }
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    general:state.general,
    event:state.event
    // careers:state.careers
})
const mapDispatchToProps={
    modalToggle,
    
    addImage,
    getImageEvent
}
export default connect(mapStateToProps,mapDispatchToProps)(list_published)