import React,{useEffect,useState} from 'react'
import './style.css'
import Layout from 'components/Layouts'
import {Button,TextField,FormControl,InputLabel,OutlinedInput,Select,MenuItem,
    FormHelperText,Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from "react-redux";
import {modalToggle} from 'redux/actions/general'
import {setAdditionalWord,setWelcomeWord,postCaption,getCaption,getBanner,moveBanner} from 'redux/actions/home'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import Edit from 'assets/icon/edit.svg'
import Close from 'assets/icon/close.svg'
const themeField = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00acc1', 
        },
    } 
})
const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,
      },
      width:'80%',
      marginBottom:15
  }
  
}));
export default function Index(props) {
    const classes=useStyles()
    const dispatch=useDispatch()
    const home=useSelector(state=>state.home)
    const [pagination,setPagination]=useState({
        page:1,
        pageSize:5
    })
    const [action,setAction]=useState('read')
    const {welcome_word,banner_pagination,additional_word,banner_image}=home
    useEffect(()=>{
        dispatch(getCaption(props.token))
        dispatch(getBanner(`/${pagination.page}/${pagination.pageSize}`))
    },[])
    const addBanner=()=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Home banner",
            modal_component: "banner",
            modal_data:null ,
            modal_size:450,
            modal_action:'add_banner'

        }))
    }
    const onDragEnd=(result)=>{
        const {draggableId, source, destination, type} = result;
        let filter=banner_image.filter((data)=>{
            return data.id===draggableId
        })
        console.log('result', (source.index+1)-(destination.index+1),source.index+1,destination.index+1)

        banner_image.splice(source.index,1)

        banner_image.splice(destination.index,0,filter[0])
        dispatch(moveBanner(props.token,`/${filter[0].bannerId}/${(source.index+1)-(destination.index+1)}/${props.profile.id}`))
    }
    const addCaption=()=>{
        let data={
            welcome:welcome_word,
            welcomeNote:additional_word,
            userId:props.profile.id
        }
        if(action==='read'){
            setAction('edit')
        }else{
            dispatch(postCaption(props.token,data))

        }
    }
    const togglePagination=(page,pageLength)=>{
        setPagination({
            page:page,
            pageSize:pageLength
        })
        dispatch(getBanner(`/${page}/${pageLength}`))
    }
   const publishUnpublish=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: `${data.publish?'Unpublish':'Publish'} banner`,
            modal_component: "confirm_pub_un",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                ...data,
                subtitle:`<p>Are you sure ${data.publish?'unpublish':'publish'} <b>${data.filename}</b></p>`,
                no_btn:'No, back to Home banner'
            },
            modal_action:'pubun_banner'
        }))
   }
   const deleteBanner=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: `Home detail`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                ...data,
                msg:`<p>Are you sure delete <b>${data.filename}</b></p>`
            },
            modal_action:'delete_banner'
        }))
   }
   const editBanner=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Home banner",
            modal_component: "banner",
            modal_data:data ,
            modal_size:450,
            modal_action:'edit_banner'

        }))
    }
    const handleDisable=()=>{
        if(action==='read'){
            return true
        }else{
            return false
        }
    }
    console.log('action', action)
    return (
        <div>
            <Layout>
            <div className='head-section'>
                    <div>
                        <h3>Home content management</h3>
                    </div>
                    <div>
                        <Button  onClick={addCaption} size='small' color='primary' variant='contained' className='head-section__btn'>{action==='read'?'Edit':'Save'}</Button>
                    </div>
                </div>
                <div className='card-content'>
                    <div className='div-flex' style={{padding:20}}>
                        <div style={{width:'50%'}}>
                            <p className='bold'>Welcome section</p>
                            <br/>
                            <MuiThemeProvider theme={themeField}>
                            <TextField 
                                label="Welcome word"
                                multiline
                                variant='outlined'
                                className={classes.textField}
                                size='small'
                                value={welcome_word}
                                onChange={(e)=>dispatch(setWelcomeWord(e.target.value))}
                                inputProps={{maxLength: 40}}
                                disabled={handleDisable()}
                                helperText={<div style={{display:'flex',justifyContent:'flex-end'}}>{`${welcome_word.length}/40`}</div>}
                             />
                            <TextField 
                                label="Additional word"
                                multiline
                                variant='outlined'
                                className={classes.textField}
                                size='small'
                                value={additional_word}
                                onChange={(e)=>dispatch(setAdditionalWord(e.target.value))}
                                inputProps={{maxLength: 200}}
                                disabled={handleDisable()}
                                helperText={<div style={{display:'flex',justifyContent:'flex-end'}}>{`${additional_word.length}/200`}</div>}
                             />
                             </MuiThemeProvider>
                        </div>
                        <div style={{width:'50%'}}>
                            <div className='div-flex div-space-between' >
                                <p className='bold'>Banner image</p>
                                <Button disabled={handleDisable()} onClick={addBanner} size='small' color='secondary' variant='contained' className='head-section__btn'>Add new image</Button>
                            </div>
                            <div className='table-section'>
                                <Table  size="small" aria-label="a dense table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Image Desktop</TableCell>
                                        <TableCell align="left">Image Mobile</TableCell>
                                        <TableCell align="left">Link page</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                    </TableHead>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <TableBody
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                >
                                                {banner_image.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                                    {(provided, snapshot) => (
                                                        <TableRow 
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        >
                                                        <TableCell onClick={()=>window.open(item.url,'_blank')}  align='left'  className='card-content-item-jurnal-text-without-weight' style={{color:'#00acc1',fontWeight:'bold',cursor:'pointer',maxWidth:80}} title={item.filename}>{item.filename.length>13?`${item.filename.substring(0,13)}...`:item.filename}</TableCell>
                                                        <TableCell onClick={()=>window.open(item.url,'_blank')}  align='left'  className='card-content-item-jurnal-text-without-weight' style={{color:'#00acc1',fontWeight:'bold',cursor:'pointer',maxWidth:80}} title={item.mobileFilename}>{item.mobileFilename!==null&&item.mobileFilename.length>13?`${item.mobileFilename.substring(0,13)}...`:item.mobileFilename}</TableCell>
                                                        <TableCell onClick={()=>window.open(item.link,'_blank')} align='left' className='card-content-item-jurnal-text-without-weight' style={{color:'#00acc1',cursor:'pointer',maxWidth:80}} title={item.link}>{item.link!==null&&item.link.length>13?`${item.link.substring(0,13)}...`:item.link}</TableCell>
                                                        <TableCell align='right' className='card-content-item-jurnal-text-without-weight' >
                                                            {/* <p style={{color:'#ff9800',margin:0,fontWeight:600,cursor:'pointer'}}>Unpublish</p> */}
                                                            <button className='btn-unpublish' disabled={handleDisable()} onClick={()=>publishUnpublish(item)}>{item.publish?'Unpublish':'Publish'}</button>
                                                            <button className='btn-unpublish' disabled={handleDisable()}onClick={()=>editBanner(item)}><img src={Edit} style={{width:15}}/></button>
                                                            <button className='btn-unpublish' disabled={handleDisable()}onClick={()=>deleteBanner(item)}><img src={Close} style={{width:14}}/></button>
                                                        </TableCell>
                                                    </TableRow>
                                                    )}
                                                    </Draggable>
                                                ))}
                                                {banner_image.length<1&&
                                                 <TableRow>
                                                    <TableCell colSpan={3} style={{textAlign:'center'}}>
                                                        <p className='bold'>No image,<Button disabled={handleDisable()}  onClick={addBanner} color='secondary' size='small' className='btn-remove-capital'>Add new banner image</Button></p>
                                                    </TableCell>
                                                </TableRow>
                                                }
                                                {provided.placeholder}
                                                </TableBody>
                                            )}
                                            </Droppable>
                                        </DragDropContext>
                                        
                                        
                                </Table>
                                {banner_pagination!==null&&
                                <div className='card-table__pagination'>
                                <TablePagination
                                    className="card-pagination"
                                    type="reduced"
                                    page={pagination.page}
                                    pageLength={pagination.pageSize}
                                    totalRecords={banner_pagination.total}
                                    onPageChange={({ page, pageLength }) => {
                                        togglePagination( page, pageLength )
                                    }}
                                    prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                                    nextPageRenderer={() => <img src={CevronRight}/>}
                                />
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}
