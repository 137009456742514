import React,{useState} from 'react'
import Layout from 'components/Layouts'
import List from './list'
import Detail from './detail'
import Preview from 'views/event_management/preview_new'
import Add from 'views/event_management/add'
const Index=(props)=> {
    const [tab_active,tabToggle]=useState('list_event')
    const {profile,token}=props
    return (
        <Layout>
            {tab_active==='list_event'&&<List tabsToggle={tabToggle} profile={profile} token={token}/>}
            {tab_active==='add_event'&&<Add tabsToggle={tabToggle} profile={profile} token={token}/>}
            {tab_active==='preview'&&<Preview tabsToggle={tabToggle} profile={profile} token={token}/>}
        </Layout>
    )
}
export default Index