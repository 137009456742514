import React, { Component } from 'react'
import Dummy from 'assets/image/dummy.jpg'
import Chevron from 'assets/icon/chevron-white.png'
import './style.css'
import {Button} from '@material-ui/core'
import Download from 'assets/icon/Download.svg'
import NoImage from 'assets/image/noimage.png'
import moment from 'moment'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#FFFFFF',
        },
    } 
})
export default class banner2 extends Component {
    renderCategory=(id)=>{
        switch (id) {
            case '1':
                return 'Mega Seminar'
            case '2':
                return 'Public Workshop'
            case '3':
                return 'Webinar'
            case '4':
                return 'Blended learning'
                case '5':
                    return 'Hybrid Class'
            default:
                break;
        }
    }
    render() {
        const {event}=this.props
        const renderThumbnail=event.thumbnail!==null?event.thumbnail.file_url!==undefined?event.thumbnail.file_url:event.thumbnail.thumbnail_url:NoImage
        let renderDate
        if(event.start_date===event.end_date){
            renderDate=`${moment(event.start_date).format('DD MMM')} ${moment(event.start_date).format('YYYY')} ${moment(event.start_time).format('HH.mm')} - ${moment(event.end_time).format('HH.mm')} WIB`
        }else{
            renderDate=`${moment(event.start_date).format('DD MMM')}-${moment(event.end_date).format('DD MMM')} ${moment(event.start_date).format('YYYY')}`
        }
        return (
            <div>
                <div className='banner2-wrapper-desktop'>
                    <img src={Dummy}/>
                    <div className='banner-content'>
                        <div className='event-breadchrum'>
                            <p>Event & Public Workshop</p>
                            &nbsp;
                            <img src={Chevron} className='breadchrum-chevron'/>
                            &nbsp;
                            <p>{event.title.substring(0,110)}</p>
                            
                        </div>
                        <div className='banner2-content-thumbnail'>
                            <img src={renderThumbnail}/>
                        </div>
                        <div className='banner2-content-description'>
                            <h1>{event.title.length>115?`${event.title.substring(0,115)}...`:event.title}</h1>
                            <p>{this.renderCategory(event.category.toString())}&nbsp;&nbsp; |&nbsp;&nbsp; Date {renderDate}  {event.address}</p>
                        </div>
                        
                    </div>
                   
                </div>
                {event.speaker.length>0&&
                <div className='banner2-content-speaker'>
                    <h3 >Speaker</h3>
                    {/* <br/> */}
                    <div style={{display:'grid',gridTemplateColumns:'repeat( auto-fit,minmax(15rem,1fr))',gridRowGap:20}}>
                    {event.speaker.map((data,i)=>(
                        <> 
                        <div className='speaker'>
                            <div className='speaker-bullets'></div>
                            &nbsp;&nbsp;
                            <p>{data.name}<br/>{data.title} {data.company}</p>
                        </div>
                        
                        </>
                    ))}
                    </div>
                </div>
                }
                <div>
                <div className='banner1-wrapper-mobile'>
                    <img src={renderThumbnail}/>
                    <div className='banner-content-mobile'>
                        <div className='event-breadchrum-mobile'>
                            <p>Event & Public Workshop</p>
                            &nbsp;
                            <img src={Chevron} className='breadchrum-chevron'/>
                            &nbsp;
                            <p>{event.title.substring(0,30)}.....</p>
                            
                        </div>
                        <h1>{event.title.length>110?`${event.title.substring(0,110)}...`:event.title}</h1>
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <p>{this.renderCategory(event.category.toString())} {renderDate}<br/>{event.address}</p>
                            <MuiThemeProvider theme={themeButton}>
                                <Button color='primary' className='btn-remove-capital' size='small' variant='text' startIcon={<img src={Download} style={{width:15}}/>}>Brochure</Button>
                            </MuiThemeProvider>
                        </div>
                    </div>
                </div>
                {event.speaker.length>0&&
                <div className='banner2-content-speaker-mobile'>
                        <h3 >Speaker</h3>
                        {/* <br/> */}
                        <div style={{display:'grid'}}>
                        {event.speaker.map((data,i)=>(
                            <> 
                            <div className='speaker-mobile'>
                                <div className='speaker-bullets-mobile'></div>
                                &nbsp;&nbsp;
                                <p>{data.name}<br/>{data.title} {data.company}</p>
                            </div>
                            
                            <br/>
                            </> 
                        ))}
                        </div>
                    </div>
                    }
                </div>
            </div>
        )
    }
}
