import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#E91E63',
    main:'#E91E63',
    light: '#E91E63'
  },
  secondary:{
    contrastText: white,
    main:'#4CAF50',
    dark: '#4CAF50',
    light: '#4CAF50'
  },
  primary2:{
    contrastText: white,
    main:'#FF9800',
    dark: '#FF9800',
    light: '#FF9800'
  }
};
