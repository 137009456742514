import React, { Component } from 'react'
import {connect} from 'react-redux'
import {modalToggle,modalToggleReset} from 'redux/actions/general'
import {unPublishCareer} from 'redux/actions/careers'
import {
    publishBanner
} from 'redux/actions/home'
import {
    publishBanner as publishBanner2
} from 'redux/actions/cdhx'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
import {Button } from '@material-ui/core'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00ACC1',
        },
        secondary: {
            main:'#E91E63',
            contrastText: '#FFFFFF',
        }
    } 
})
class confirm_unpublish extends Component {
    pubUnBanner=(modal_data)=>{
        this.props.publishBanner(this.props.token,`/${modal_data.bannerId}/${modal_data.publish?0:1}/${this.props.profile.id}`)
    } 
    pubUnBanner2=(modal_data)=>{
        this.props.publishBanner2(this.props.token,`/${modal_data.bannerId}/${modal_data.publish?0:1}/${this.props.profile.id}`)
    } 
    renderAction=()=>{
        let {modal_data,modal_action,profile,token}=this.props
        switch (modal_action) {
            case 'pubun_banner':
               return this.pubUnBanner(modal_data)
            case 'pubun_banner2':
               return this.pubUnBanner2(modal_data)
            default:
                break;
        }
        // case
    }
    render() {
        const {modal_data,modal_title}=this.props
        return (
            <div className='confirm-container'>
                <h4>{modal_title}</h4>
                <div dangerouslySetInnerHTML={{ __html: modal_data.subtitle }}></div>
                <div className='card-footer'>
                    <MuiThemeProvider theme={themeButton}>
                        <Button onClick={()=>this.props.modalToggleReset()}   size='small' color='primary' className='btn-remove-capital'>{modal_data.no_btn}</Button>
                        <Button onClick={()=>this.renderAction()}   size='small' color='secondary' variant='contained' className='head-add-section__btn '>Yes</Button>
                    </MuiThemeProvider>
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    general:state.general,
    event:state.event
})
const mapDispatchToProps={
    modalToggleReset,
    modalToggle,
   publishBanner,
   publishBanner2,
}
export default connect(mapStateToProps,mapDispatchToProps)(confirm_unpublish)