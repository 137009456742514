import React, { Component } from 'react'
import {Table,TableHead,TableCell,TableRow,TableBody,Button} from '@material-ui/core'
import Eye from 'assets/icon/eye.svg'
import Edit from 'assets/icon/edit.svg'
import Close from 'assets/icon/close.svg'
import Skeleton from 'components/Skeleton'

export default class list_published extends Component {
    render() {
        let {careers,isLoadingTable}=this.props
        return (
            <div>
                <Table  size="small" aria-label="a dense table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="left">Job Title</TableCell>
                        <TableCell align="left">Placement</TableCell>
                        <TableCell align="left">Job code</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        
                        {isLoadingTable!==true?careers.map((data,i)=>(
                            <TableRow key={i}>
                                <TableCell style={{maxWidth:255,minHeight:100,}}>{data.title}</TableCell>
                                <TableCell>{data.location}</TableCell>
                                <TableCell>{data.title}</TableCell>
                                <TableCell align="right">
                                    <button className='btn-unpublish'><img src={Eye} onClick={()=>this.props.detailCareers(data)}/></button>
                                    <button className='btn-unpublish' onClick={()=>this.props.unPublish(data)}>Unpublish</button>
                                </TableCell>
                            </TableRow>
                        )):<Skeleton count={4}/>}
                    </TableBody>
                </Table>
            </div>
        )
    }
}
