import React, { Component } from 'react'
import './style.css'
// import Framework from 'assets/icon/framework.png'
import testi1 from 'assets/image/dummy.jpg'
import Slider from 'infinite-react-carousel';
import Dummy from 'assets/image/noimage.png'

export default class information extends Component {
    render() {
        const {event}=this.props
        console.log('testi',event.testimony)
        
        return (
            <div >
                <h1 className='desc-title'>Description</h1>
                <div className='event-information' dangerouslySetInnerHTML={{__html: event.description}}/>
                <br/>
                {event.testimony.length>0?
                    
                    <div>
                    <h1  style={{color:'#464646'}}>Testimoni</h1>
                    <br/>
           
                   
                    <Slider dots arrows={false} rows={2} slidesPerRow={1}>
                        {event.testimony.map((data,i)=>(
                                <div key={i} >
                            <div className='testimoni' >
                                    <p><i>"{data.testimony}"</i></p>
                                    <div className='testimoni-person'>
                                        <img src={data.file_url!==null?data.file_url:Dummy}/>
                                        &nbsp;&nbsp;&nbsp;
                                        <div className='testi-name'>
                                            <i>{data.name}</i><br/>
                                            <i> {data.title} {data.company}</i>
                                        </div>
                                    </div>

                                </div>
                            
                                </div>
                            ))}
                    
                    </Slider>
                    </div>
                :null}
                {/* {event.testimony.length>0&&(
                    
                    <div>
                    <h1  style={{color:'#464646'}}>Testimony</h1>
                    <br/>
                    <Slider dots arrows={false}>
                        {event.testimony.map((data,i)=>(
                            <div className='testimoni' key={i}>
                                <p><i>{data.testimony}</i></p>
                                <div className='testimoni-person'>
                                    <img src={data.file_url}/>
                                    &nbsp;
                                    <p>{data.name}</p>
                                </div>
                            </div>
                        ))}
                       
                    </Slider>
                    </div>
                )} */}
                {/* <h1>Framework</h1>
                <br/>
                <img src={Framework} style={{width:'100%'}}/> */}
                <br/><br/>
                {/* {event.testimony.length>0&&
                <>
                <h1>Testimoni</h1>
                <br/>
                
                <Slider dots arrows={false} rows={2} slidesPerRow={1}>
                    {event.testimony.map((data,i)=>(
                            <div key={i} >
                           <div className='testimoni' >
                                <p><i>"{data.testimony}"</i></p>
                                <div className='testimoni-person'>
                                    <img src={data.file_url!==null?data.file_url:Dummy}/>
                                    &nbsp;&nbsp;&nbsp;
                                    <div className='testi-name'>
                                        <i>{data.name}</i><br/>
                                        <i> {data.title} {data.company}</i>
                                    </div>
                                </div>

                            </div>
                            
                            </div>
                        ))}
                   
                </Slider>
                </>
                } */}
               
            </div>
        )
    }
}
