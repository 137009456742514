import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
  makeStyles,
} from "@material-ui/core/styles";
import moment from "moment";
import NoImage from "assets/image/noimage.png";
import Agenda from "./agenda";
import Information from "./information";
import Investment from "./investment";
import Speaker from "./speaker";
import Testimony from "./testimony";
import Modal from "components/Modal";
import { connect } from "react-redux";
import ChipInput from "material-ui-chip-input";

import { handleFile, getBase64, decodeBase64 } from "components/handleFile";
import {
  setBrochure,
  setThumbnail,
  setTitle,
  setStartTime,
  setEndTime,
  setStartDate,
  setEndDate,
  setAddress,
  setCategory,
  setStatus,
  setTarget,
  addEvent,
  setShortDesc,
  updateEvent,
  setAction,
  setMetaTitle,
  setSlug,
  setMetaDesc,
  setKeyword,
  setRegistrationUrl,
  setEventType,
  setEventLocation,
  setFlyer,
  setTakeAways,
  setLinkZoom,
  setvideoURL,
  getCDHXCategory,
  setCdhxCategory,
  setTribe,
  getTribes,
} from "redux/actions/event";
import {
  previewSetTitle,
  previewSetStartDate,
  previewSetEndDate,
  previewSetStartTime,
  previewSetEndTime,
  previewSetAddress,
  previewSetCategory,
  previewSetStatus,
  previewSetTarget,
  previewSetShortDesc,
  previewSetThumbnail,
  previewSetBrochure,
  previewSetDescription,
  previewSetAdditional,
  previewSetFramework,
  previewSetTestimony,
  previewSetAgenda,
  previewSetInvestment,
  previewSetSpeaker,
  previewSetIdEvent,
} from "redux/actions/preview";
import { modalToggle } from "redux/actions/general";
import close from "assets/icon/close.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import DateFnsUtils from "@date-io/date-fns";
import MomentUtils from "@date-io/moment";
import {
  KeyboardTimePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Email from "./email";
import { getSpeakers } from "redux/actions/speaker";
const themeButton = createMuiTheme({
  palette: {
    primary: {
      main: "#E91E63",
    },
    secondary: {
      main: "#FF9800",
      contrastText: "#FFFFFF",
    },
  },
});
const themeField = createMuiTheme({
  palette: {
    primary: {
      main: "#00acc1",
    },
  },
});
const useStyles = makeStyles((theme) => ({
  textField: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
    width: "80%",
    marginBottom: 15,
  },
  textField2: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
    width: "100%",
    marginBottom: 15,
  },
}));

const App = (props) => {
  const [state, setState] = useState({
    thumbnail_url: null,
    thumbnail_error: null,
    brochure_error: null,
    flyer_error: null,
    focusedInput: null,
    active_tab: "information",
  });
  const [focusedInput, setFocusedInput] = useState(null);
  const dispatch = useDispatch();
  const event = useSelector((state) => state.event);

  const classes = useStyles();
  useEffect(() => {
    dispatch(getCDHXCategory());
    dispatch(getSpeakers("*"));
    dispatch(getTribes());
  }, []);
  const handleDisable = () => {
    if (event.action === "see_event") {
      return true;
    } else {
      return false;
    }
  };
  // console.log("event", event);
  const handleHeaderBtnDisable = () => {
    if (
      event.title === "" ||
      event.category === "" ||
      event.type === "" ||
      event.tribes === "" ||
      event.location === "" ||
      event.cdhxCategory === "" ||
      event.start_time === null ||
      event.end_time === null ||
      event.link_zoom === null ||
      event.short_desc === "" ||
      event.thumbnail === null ||
      event.meta_title === "" ||
      event.slug === "" ||
      event.meta_desc === "" ||
      event.keyword.length === 0 ||
      event.brochure === null ||
      event.flyer === null
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleDateChange = ({ startDate, endDate }) => {
    // console.log('startDate,endDate', startDate,endDate)
    dispatch(setStartDate(startDate));
    dispatch(setEndDate(endDate));
  };
  const handleFiles = async (evt, name) => {
    let handle = await handleFile(evt, name);
    if (handle.file_error === null) {
      let url = URL.createObjectURL(handle.file);
      setState({ ...state, [`${name}_error`]: null });
      getBase64(handle.file, (result) => {
        let data = {
          file_url: url,
          file: result,
          file_name: handle.file_name,
        };
        // console.log('result', result)
        if (name === "thumbnail") {
          dispatch(setThumbnail(data));
        } else if (name === "brochure") {
          dispatch(setBrochure(data));
        } else {
          dispatch(setFlyer(data));
        }
      });
    } else {
      setState({ ...state, [`${name}_error`]: handle.file_error });
    }
  };
  const removeBrochure = (name) => {
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: `detail`,
        modal_component: "confirm_delete",
        modal_size: 400,
        modal_type: "confirm",
        modal_data: {
          title: `Brochure`,
          msg: "<p>Are you sure delete this brochure</p>",
        },
        modal_action: "delete_brochure",
      })
    );
  };
  const removeFlyer = (name) => {
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: `detail`,
        modal_component: "confirm_delete",
        modal_size: 400,
        modal_type: "confirm",
        modal_data: {
          title: `Flyer`,
          msg: "<p>Are you sure delete this Flyer</p>",
        },
        modal_action: "delete_flyer",
      })
    );
  };
  const onSave = (key) => {
    let new_testimoni = [];
    if (event.testimony.length > 0) {
      event.testimony.map(async (data, i) => {
        // let testimoni_file=await decodeBase64(data.file,data.file_name)
        new_testimoni.push({
          id: data.id,
          name: data.name,
          title: data.title,
          company: data.company,
          testimony: data.testimony,
          testimonyPhotos: data.file,
          photoFilename: data.file_name,
        });
      });
    }
    let data = {
      profileId: props.profile.id,
      slug: event.slug,
      metaTitle: event.meta_title,
      metaDescription: event.meta_desc,
      keyword: event.keyword.toString(),
      title: event.title,
      intro: event.short_desc,
      fromDate: moment(event.start_date).format("YYYY-MM-DD"),
      toDate: moment(event.end_date).format("YYYY-MM-DD"),
      startTime:
        event.start_time === null
          ? ""
          : moment(event.start_time).format("HH:mm"),
      endTime:
        event.end_time === null ? "" : moment(event.end_time).format("HH:mm"),
      linkZoom: event.link_zoom,
      address: event.address,
      categoryId: event.category,
      locationId: event.location,
      topicId: event.type,
      registrationURL: event.registration_url,
      publish: key === "publish" ? 1 : 0,
      audience: event.target,
      description: event.description,
      thumbnails: event.thumbnail !== null ? event.thumbnail.file : null,
      thumbnailFilename:
        event.thumbnail !== null ? event.thumbnail.file_name : null,
      brochures: event.brochure !== null ? event.brochure.file : null,
      brochuleFilename:
        event.brochure !== null ? event.brochure.file_name : null,
      agenda: event.agenda,
      testimonies: new_testimoni,
      investments: event.investment,
      speakers: event.speaker,
      id: event.id_event === "" ? 0 : event.id_event,
      flyerFileName: event.flyer !== null ? event.flyer.file_name : "",
      flyer: event.flyer !== null ? event.flyer.file : "",
      takeaways: event.take_aways,
      videoURL: event.videoURL,
      emailNotification: event.emailNotification,
      emailSubject: event.emailSubject,
      email: event.email,
      cdhxCategory: event.cdhxCategory,
      tribeId: event.tribeId,
    };
    console.log("data", data);
    if (key === "publish") {
      dispatch(setStatus(1));
    }
    if (event.action !== "see_event") {
      if (event.id_event === "") {
        // console.log('data', data)
        dispatch(
          addEvent(
            props.token,
            data,
            `/0/${key === "draft" ? 0 : 1}/1/10/*`,
            event.active_tab
          )
        );
      } else {
        dispatch(
          updateEvent(
            props.token,
            data,
            event.id_event,
            `/0/${key === "draft" ? 0 : 1}/1/10/*`,
            key === "draft" ? "drafted" : "published"
          )
        );
      }
    } else {
      // console.log('update_event',event.action)
      // alert('asdf')
      if (key === "publish") {
        dispatch(
          updateEvent(
            props.token,
            data,
            event.id_event,
            `/0/${key === "draft" ? 0 : 1}/1/10/*`,
            key === "draft" ? "drafted" : "published"
          )
        );
      } else {
        dispatch(setAction("add_event"));
      }
    }
  };
  const unPublish = (data) => {
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "detail",
        modal_component: "confirm_unpublish",
        modal_size: 400,
        modal_type: "confirm",
        modal_data: { title: data.title, id: data.id_event },
        modal_action: "unpublish_event",
        success_msg: "See detail event",
      })
    );
  };
  const preview = () => {
    props.tabsToggle("preview");
    console.log(event.testimony);
    dispatch(previewSetTitle(event.title));
    dispatch(previewSetStartDate(event.start_date));
    dispatch(previewSetEndDate(event.end_date));
    dispatch(previewSetStartTime(event.start_time));
    dispatch(previewSetEndTime(event.end_time));
    dispatch(previewSetAddress(event.address));
    dispatch(previewSetCategory(event.category));
    dispatch(previewSetStatus(event.status));
    dispatch(previewSetTarget(event.target));
    dispatch(previewSetShortDesc(event.short_desc));
    dispatch(previewSetThumbnail(event.thumbnail));
    dispatch(previewSetBrochure(event.brochure));
    dispatch(previewSetDescription(event.description));
    dispatch(previewSetAdditional(event.additional));
    dispatch(previewSetTestimony(event.testimony));
    dispatch(previewSetAgenda(event.agenda));
    dispatch(previewSetInvestment(event.investment));
    dispatch(previewSetSpeaker(event.speaker));
    dispatch(previewSetIdEvent(event.id_event));
  };
  const goBack = () => {
    const { from_dashboard } = props;
    if (from_dashboard) {
      window.location.assign("/dashboard");
    } else {
      props.tabsToggle("list_event");
    }
  };
  const deleteKeyword = (chip, index) => {
    let filter = event.keyword.filter((data) => {
      return data !== chip;
    });
    dispatch(setKeyword([...filter]));
  };
  const deleteTakeAways = (chip, index) => {
    let filter = event.take_aways.filter((data) => {
      return data !== chip;
    });
    dispatch(setTakeAways([...filter]));
  };
  const onChangeSlug = (slug) => {
    // if(slug===' '){

    // }
    // console.log('slug', slug)
    let result = slug.replace(/\s/g, "-").toLowerCase();
    dispatch(setSlug(result));
  };
  const cdhxCategory = (data) => {
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "CDHX Category",
        modal_component: "cdhx_category",
        modal_size: 400,
        modal_action: "cdhx_category",
        success_msg: "See detail event",
      })
    );
  };
  return (
    <div>
      <div className="head-section">
        <div>
          <h3>{event.id_event !== "" ? "Detail event" : "Create new event"}</h3>
        </div>
        <div>
          <MuiThemeProvider theme={themeButton}>
            {event.action !== "see_event" ||
            event.active_tab !== "published" ? (
              <>
                <Button
                  onClick={() => goBack()}
                  size="small"
                  color="primary"
                  variant="outlined"
                  className="head-add-section__btn back"
                >
                  Back
                </Button>
                <Button
                  disabled={handleHeaderBtnDisable()}
                  onClick={() => preview()}
                  size="small"
                  color="primary"
                  variant="outlined"
                  className="head-add-section__btn preview"
                >
                  Preview
                </Button>
                <Button
                  disabled={
                    event.action === "see_event"
                      ? false
                      : handleHeaderBtnDisable()
                  }
                  onClick={() => onSave("draft")}
                  size="small"
                  color="primary"
                  variant="contained"
                  className="head-add-section__btn save"
                >
                  {event.action === "update_event" ||
                  event.action === "see_event"
                    ? "Edit"
                    : "Save"}
                </Button>
                <Button
                  disabled={handleHeaderBtnDisable()}
                  onClick={() => onSave("publish")}
                  size="small"
                  color="secondary"
                  variant="contained"
                  className="head-add-section__btn "
                >
                  Publish
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={() => goBack()}
                  size="small"
                  color="primary"
                  variant="outlined"
                  className="head-add-section__btn back"
                >
                  Back
                </Button>
                <Button
                  onClick={() => preview()}
                  size="small"
                  color="primary"
                  variant="outlined"
                  className="head-add-section__btn preview"
                >
                  Preview
                </Button>
                <Button
                  onClick={() => unPublish(event)}
                  size="small"
                  color="secondary"
                  variant="contained"
                  className="head-add-section__btn "
                >
                  Unpublish
                </Button>
              </>
            )}
          </MuiThemeProvider>
        </div>
      </div>
      <div className="card-content">
        <div className="add-event">
          <div className="card-content__information">
            
            <MuiThemeProvider theme={themeField}>
              <p className="bold" style={{ fontSize: 14 }}>
                Content ID
              </p>
              <br />
              <TextField
                label={
                  <>
                    Content ID
                  </>
                }
                variant="outlined"
                value={event.id_event}
                size="small"
                disabled={true}
                className={classes.textField}
              />
              <p className="bold" style={{ fontSize: 14 }}>
                General Information
              </p>
              <br />
              <TextField
                label={
                  <>
                    Event Title <span style={{ color: "red" }}>*</span>
                  </>
                }
                variant="outlined"
                value={event.title}
                size="small"
                disabled={handleDisable()}
                className={classes.textField}
                onChange={(e) => dispatch(setTitle(e.target.value))}
              />
              <p className="bold">
                Date<span style={{ color: "red" }}>*</span>
              </p>
              <DateRangePicker
                minimumNights={0}
                showDefaultInputIcon
                isOutsideRange={() => false}
                small
                startDate={moment(event.start_date)}
                startDateId="startDate"
                endDate={moment(event.end_date)}
                endDateId="endDate"
                onDatesChange={handleDateChange}
                focusedInput={focusedInput}
                disabled={handleDisable()}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
              />
              <FormControl
                variant="outlined"
                size="small"
                className="add-event__field"
              >
                <InputLabel htmlFor="category">
                  Event Category <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Select
                  disabled={handleDisable()}
                  value={event.category}
                  onChange={(e) => dispatch(setCategory(e.target.value))}
                  labelId="label"
                  id="select"
                  labelWidth={110}
                  className="field-radius"
                >
                  {event.category_list.map((data, i) => (
                    <MenuItem value={data.id}>{data.text}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                size="small"
                className="add-event__field"
              >
                <InputLabel htmlFor="category">
                  Event Topic <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Select
                  disabled={handleDisable()}
                  value={event.type}
                  onChange={(e) => dispatch(setEventType(e.target.value))}
                  labelId="label"
                  id="select"
                  labelWidth={90}
                  className="field-radius"
                >
                  {event.type_list.map((data, i) => (
                    <MenuItem value={data.id}>{data.text}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                size="small"
                className="add-event__field"
              >
                <InputLabel htmlFor="category">
                  Tribes<span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Select
                  disabled={handleDisable()}
                  value={event.tribeId}
                  onChange={(e) => dispatch(setTribe(e.target.value))}
                  labelId="label"
                  id="select"
                  labelWidth={50}
                  className="field-radius"
                >
                  {event.tribes.map((data, i) => (
                    <MenuItem key={i} value={data.id}>
                      {data.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="outlined"
                size="small"
                className="add-event__field"
              >
                <InputLabel htmlFor="category">
                  Location <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Select
                  disabled={handleDisable()}
                  value={event.location}
                  onChange={(e) => dispatch(setEventLocation(e.target.value))}
                  labelId="label"
                  id="select"
                  labelWidth={70}
                  className="field-radius"
                >
                  {event.location_list.map((data, i) => (
                    <MenuItem value={data.id}>{data.text}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                <div style={{ width: "60%" }}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    className="add-event__field"
                  >
                    <InputLabel htmlFor="category">
                      CDHX Category <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      disabled={handleDisable()}
                      value={event.cdhxCategory}
                      onChange={(e) =>
                        dispatch(setCdhxCategory(e.target.value))
                      }
                      labelId="label"
                      id="select"
                      labelWidth={120}
                      className="field-radius"
                    >
                      {event.cdhx_categorys.map((data, i) => (
                        <MenuItem value={data.id}>{data.text}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <p
                  onClick={cdhxCategory}
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    margin: 0,
                    color: "red",
                    fontSize: 16,
                    marginBottom: 15,
                  }}
                >
                  Tambah
                </p>
              </div>
              &nbsp;
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80%",
                }}
              >
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <TimePicker
                    className={classes.textField}
                    disabled={handleDisable()}
                    label={
                      <>
                        Start Time <span style={{ color: "red" }}>*</span>
                      </>
                    }
                    clearable={true}
                    size="small"
                    inputVariant="outlined"
                    value={event.start_time}
                    onChange={(e) => dispatch(setStartTime(e))}
                  />
                  &nbsp;
                  <TimePicker
                    className={classes.textField}
                    disabled={handleDisable()}
                    label={
                      <>
                        End Time <span style={{ color: "red" }}>*</span>
                      </>
                    }
                    clearable={true}
                    size="small"
                    inputVariant="outlined"
                    value={event.end_time}
                    onChange={(e) => dispatch(setEndTime(e))}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <TextField
                label={
                  <>
                    Link Zoom <span style={{ color: "red" }}>*</span>
                  </>
                }
                value={event.link_zoom}
                onChange={(e) => dispatch(setLinkZoom(e.target.value))}
                variant="outlined"
                size="small"
                className={classes.textField}
                disabled={handleDisable()}
              />
              <TextField
                label={<>Address</>}
                value={event.address}
                onChange={(e) => dispatch(setAddress(e.target.value))}
                variant="outlined"
                size="small"
                className={classes.textField}
                disabled={handleDisable()}
              />
              <TextField
                label={<>Registration URL</>}
                value={event.registration_url}
                onChange={(e) => dispatch(setRegistrationUrl(e.target.value))}
                variant="outlined"
                size="small"
                className={classes.textField}
                disabled={handleDisable()}
              />
              <TextField
                label="Status"
                value={event.status ? "Published" : "Drafted"}
                // onChange={(e)=>dispatch(setAddress(e.target.value))}
                variant="outlined"
                size="small"
                className={classes.textField}
                disabled
              />
              <TextField
                label={
                  <>
                    Short Description <span style={{ color: "red" }}>*</span>
                  </>
                }
                multiline
                variant="outlined"
                className={classes.textField}
                size="small"
                value={event.short_desc}
                onChange={(e) => dispatch(setShortDesc(e.target.value))}
                inputProps={{ maxLength: 300 }}
                disabled={handleDisable()}
                helperText={
                  <div
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >{`${event.short_desc.length}/300`}</div>
                }
              />
              <TextField
                className={classes.textField}
                label={<>Youtube Video URL</>}
                variant="outlined"
                multiline
                value={event.videoURL}
                size="small"
                onChange={(e) => dispatch(setvideoURL(e.target.value))}
                disabled={handleDisable()}
              />
              <TextField
                className={classes.textField}
                label={<>Target</>}
                variant="outlined"
                multiline
                value={event.target}
                size="small"
                onChange={(e) => dispatch(setTarget(e.target.value))}
                disabled={handleDisable()}
              />
              <ChipInput
                label={<>Take Aways</>}
                value={event.take_aways}
                onAdd={(chip) =>
                  dispatch(setTakeAways([...event.take_aways, chip]))
                }
                // onAdd={(chip) =>console.log('chip', chip)}
                onDelete={(chip, index) => deleteTakeAways(chip, index)}
                variant="outlined"
                className={classes.textField}
                disabled={handleDisable()}
              />
            </MuiThemeProvider>
            <br />
            <br />
          </div>
          <div className="card-content__doc">
            <p className="bold" style={{ fontSize: 14 }}>
              Thumbnail <span style={{ color: "red" }}>*</span>
            </p>
            <div className="image-container">
              <img
                src={
                  event.thumbnail !== null
                    ? event.thumbnail.file_url !== undefined
                      ? event.thumbnail.file_url
                      : event.thumbnail.thumbnail_url
                    : NoImage
                }
                style={{
                  width: "60%",
                  height: 200,
                  objectFit: "cover",
                  borderRadius: 20,
                }}
              />
              <br />
              {state.thumbnail_error !== null && (
                <p style={{ color: "red" }}>{state.thumbnail_error}</p>
              )}
              <Button
                disabled={handleDisable()}
                component="label"
                onClick={() => props.tabsToggle("add_event")}
                size="small"
                color="secondary"
                variant="contained"
                className="head-section__btn"
              >
                Add thumbnail
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleFiles(e, "thumbnail")}
                />
              </Button>
            </div>
            <br />
            <p className="bold" style={{ fontSize: 14 }}>
              SEO Setting
            </p>
            <br />
            <div>
              <MuiThemeProvider theme={themeField}>
                <TextField
                  label={
                    <>
                      Meta Title <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  multiline
                  variant="outlined"
                  className={classes.textField2}
                  size="small"
                  value={event.meta_title}
                  onChange={(e) => dispatch(setMetaTitle(e.target.value))}
                  inputProps={{ maxLength: 100 }}
                  disabled={handleDisable()}
                  helperText={
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >{`${event.meta_title.length}/100`}</div>
                  }
                />
                <TextField
                  label={
                    <>
                      Slug <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  multiline
                  variant="outlined"
                  className={classes.textField2}
                  size="small"
                  value={event.slug}
                  onChange={(e) => onChangeSlug(e.target.value)}
                  // inputProps={{maxLength: 100}}
                  disabled={handleDisable()}
                  // helperText={<div style={{display:'flex',justifyContent:'flex-end'}}>{`${event.short_desc.length}/100`}</div>}
                />
                <TextField
                  label={
                    <>
                      Meta Description <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  multiline
                  variant="outlined"
                  className={classes.textField2}
                  size="small"
                  value={event.meta_desc}
                  onChange={(e) => dispatch(setMetaDesc(e.target.value))}
                  inputProps={{ maxLength: 160 }}
                  disabled={handleDisable()}
                  helperText={
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >{`${event.meta_desc.length}/160`}</div>
                  }
                />
                <ChipInput
                  label={
                    <>
                      Keyword <span style={{ color: "red" }}>*</span>
                    </>
                  }
                  value={event.keyword}
                  onAdd={(chip) =>
                    dispatch(setKeyword([...event.keyword, chip]))
                  }
                  // onAdd={(chip) =>console.log('chip', chip)}
                  onDelete={(chip, index) => deleteKeyword(chip, index)}
                  variant="outlined"
                  className={classes.textField2}
                  disabled={handleDisable()}
                />
              </MuiThemeProvider>
            </div>
            <br />
            <div>
              <div className="subcontent-container">
                <p className="bold" style={{ fontSize: 14 }}>
                  Brochure <span style={{ color: "red" }}>*</span>
                </p>
                {event.brochure === null && (
                  <Button
                    disabled={handleDisable()}
                    component="label"
                    size="small"
                    color="secondary"
                    variant="contained"
                    className="head-section__btn"
                  >
                    Add Brochure
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => handleFiles(e, "brochure")}
                    />
                  </Button>
                )}
              </div>
              <br />

              {state.brochure_error !== null && (
                <p style={{ color: "red" }}>{state.brochure_error}</p>
              )}
              {event.brochure !== null && (
                <div className="subcontent-container">
                  <p className="bold">{event.brochure.file_name}</p>
                  <img
                    src={close}
                    style={{ width: 15, cursor: "pointer" }}
                    onClick={
                      handleDisable()
                        ? null
                        : () => removeBrochure(event.brochure.file_name)
                    }
                  />
                </div>
              )}
            </div>
            <br />
            <div>
              <div className="subcontent-container">
                <p className="bold" style={{ fontSize: 14 }}>
                  Flyer <span style={{ color: "red" }}>*</span>
                </p>
                {event.flyer === null && (
                  <Button
                    disabled={handleDisable()}
                    component="label"
                    size="small"
                    color="secondary"
                    variant="contained"
                    className="head-section__btn"
                  >
                    Add Flyer
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => handleFiles(e, "flyer")}
                    />
                  </Button>
                )}
              </div>
              <br />

              {state.flyer_error !== null && (
                <p style={{ color: "red" }}>{state.flyer_error}</p>
              )}
              {event.flyer !== null && (
                <div className="subcontent-container">
                  <p className="bold">{event.flyer.file_name}</p>
                  <img
                    src={close}
                    style={{ width: 15, cursor: "pointer" }}
                    onClick={
                      handleDisable()
                        ? null
                        : () => removeFlyer(event.flyer.file_name)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <br />
        <div className="subcontent-tab">
          <div className="card-tab-cms">
            <li
              onClick={() => setState({ ...state, active_tab: "information" })}
              className={
                state.active_tab === "information"
                  ? "card-tab-cms__list card-tab-cms__list-active"
                  : "card-tab-cms__list"
              }
            >
              Workshop information
            </li>
            <li
              onClick={() => setState({ ...state, active_tab: "agenda" })}
              className={
                state.active_tab === "agenda"
                  ? "card-tab-cms__list card-tab-cms__list-active"
                  : "card-tab-cms__list"
              }
            >
              Agenda
            </li>
            <li
              onClick={() => setState({ ...state, active_tab: "investment" })}
              className={
                state.active_tab === "investment"
                  ? "card-tab-cms__list card-tab-cms__list-active"
                  : "card-tab-cms__list"
              }
            >
              Investment
            </li>
            <li
              onClick={() => setState({ ...state, active_tab: "speaker" })}
              className={
                state.active_tab === "speaker"
                  ? "card-tab-cms__list card-tab-cms__list-active"
                  : "card-tab-cms__list"
              }
            >
              Speaker
            </li>
            <li
              onClick={() => setState({ ...state, active_tab: "testimony" })}
              className={
                state.active_tab === "testimony"
                  ? "card-tab-cms__list card-tab-cms__list-active"
                  : "card-tab-cms__list"
              }
            >
              Testimony
            </li>
            <li
              onClick={() => setState({ ...state, active_tab: "email" })}
              className={
                state.active_tab === "email"
                  ? "card-tab-cms__list card-tab-cms__list-active"
                  : "card-tab-cms__list"
              }
            >
              Custom Email
            </li>
          </div>
          <div className="subcontent-content">
            {state.active_tab === "information" && (
              <Information handleDisable={handleDisable} token={props.token} />
            )}
            {state.active_tab === "agenda" && (
              <Agenda handleDisable={handleDisable} />
            )}
            {state.active_tab === "investment" && (
              <Investment handleDisable={handleDisable} />
            )}
            {state.active_tab === "speaker" && (
              <Speaker handleDisable={handleDisable} />
            )}
            {state.active_tab === "testimony" && (
              <Testimony handleDisable={handleDisable} />
            )}
            {state.active_tab === "email" && (
              <Email handleDisable={handleDisable} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
