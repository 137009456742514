import React, { Component } from 'react'
import './style.css'

// import event1 from 'assets/i/event1.jpg'
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
// import gallery1 from 'assets/image/gallery1.jpg'
// import gallery2 from 'assets/image/gallery2.jpg'
// import gallery3 from 'assets/image/insight3.jpg'
import no_image from 'assets/image/noimage.png'

// import Gallery from 'components/grid/gallery'
import {connect} from 'react-redux'
import {getImageEventList} from 'redux/actions/event'
import Skeleton  from 'react-loading-skeleton';
class documentation extends Component {
    state={
        photoIndex: 0,
        isOpen: false,
    }
    // componentDidMount(){
    //     const {preview}=this.props
    //     // if(event.list_image_event){}
    //     this.props.getImageEventList(preview.event_id)
    // }
    lightBoxToggle=(i)=>{
        this.setState({photoIndex:i,isOpen:!this.state.isOpen})
    }
    render() {
        const {photoIndex,isOpen}=this.state
        const {event}=this.props
        console.log('doc', event)
        return (
            <div>
                <br/>
               <div className='grid-documentation'>
                    {event.list_image_event_all!==null?event.list_image_event_all.map((img,i)=>(
                        <div className='image-documentation-overlay' onClick={()=>this.lightBoxToggle(i)}>
                            <img src={img.imageURL}/>
                        </div>
                       
                    )):
                        <div>
                        <Skeleton count={1} height={90}/> 
                        </div>
                    } 
                </div>
                     {/* <Gallery image={data_gallery}/> */}
                {/* <div className='grid-documentation'>
                    {event.doc.map((img,i)=>(
                        <div className='image-documentation-overlay' onClick={()=>this.lightBoxToggle(i)}>
                            <img src={img.img}/>
                        </div>
                    ))}
                </div> */}
                {isOpen && (
                    <Lightbox
                        mainSrc={event.list_image_event_all[photoIndex].imageURL}
                        // nextSrc={image[(photoIndex + 1) % image.length].img}
                        // prevSrc={image[(photoIndex + image.length - 1) % image.length].img}
                        imageCaption={event.list_image_event_all[photoIndex].caption}
                        // imageCaption={<div style={{textAlign:'center',width:1200}}><p>{event.list_image_event_all[photoIndex].caption}</p></div>}
                        // mainSrcThumbnail={image[photoIndex].caption}
                        // mainSrcThumbnail={image[(photoIndex + 1) % image.length].caption}
                        // nextSrcThumbnail={image[(photoIndex + image.length - 1) % image.length].caption}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + event.doc.length - 1) % event.doc.length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (photoIndex + 1) % event.doc.length,
                        })
                        }
                    />
                )}
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    event:state.event,
    preview:state.preview
})
const mapDispatchToProps={
    getImageEventList
}

export default connect(mapStateToProps,mapDispatchToProps)(documentation)