import React, { Component } from "react";
import Checklist from "assets/icon/checklist.png";
import close from "assets/icon/close.svg";
import { connect } from "react-redux";
import { modalToggleReset, modalToggle } from "redux/actions/general";
import { setAction } from "redux/actions/event";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
const themeButton = createMuiTheme({
  palette: {
    primary: {
      main: "#00ACC1",
    },
    secondary: {
      main: "#E91E63",
      contrastText: "#FFFFFF",
    },
  },
});
class alert extends Component {
  detailCareer = (data) => {
    this.props.modalToggle({
      modal_open: true,
      modal_title: "Detail career",
      modal_component: "career",
      modal_size: 500,
      modal_type: "readonly",
      modal_data: data,
    });
  };
  detailEvent = () => {
    this.props.setAction("add_event");
    this.props.modalToggleReset();
  };
  updateEvent = () => {
    this.props.modalToggleReset();
  };
  renderFooter = () => {
    const { modal_component, modal_data, success_msg } = this.props.general;
    switch (modal_component) {
      case "add_career":
        return (
          <Button
            onClick={() => this.detailCareer(modal_data)}
            size="small"
            color="secondary"
            variant="contained"
            className="head-add-section__btn "
          >
            See detail career
          </Button>
        );
      case "delete_career":
        return (
          <Button
            onClick={() => this.props.modalToggleReset()}
            size="small"
            color="secondary"
            variant="contained"
            className="head-add-section__btn "
          >
            See career list
          </Button>
        );
      case "delete_image":
        return (
          <Button
            onClick={() => this.props.modalToggleReset()}
            size="small"
            color="secondary"
            variant="contained"
            className="head-add-section__btn "
          >
            See image list
          </Button>
        );
      case "update_career":
        return (
          <Button
            onClick={() => this.detailCareer(modal_data)}
            size="small"
            color="secondary"
            variant="contained"
            className="head-add-section__btn "
          >
            See detail career
          </Button>
        );
      case "update_image":
        return (
          <Button
            onClick={() => this.props.modalToggleReset()}
            size="small"
            color="secondary"
            variant="contained"
            className="head-add-section__btn "
          >
            See image list
          </Button>
        );
      case "unpublish_event":
        return (
          <Button
            onClick={() => this.props.modalToggleReset()}
            size="small"
            color="secondary"
            variant="contained"
            className="head-add-section__btn "
          >
            {success_msg}
          </Button>
        );
      case "delete_event":
        return (
          <Button
            onClick={() => this.props.modalToggleReset()}
            size="small"
            color="secondary"
            variant="contained"
            className="head-add-section__btn "
          >
            {success_msg}
          </Button>
        );
      case "add_event":
        return (
          <Button
            onClick={() => this.detailEvent()}
            size="small"
            color="secondary"
            variant="contained"
            className="head-add-section__btn "
          >
            See detail event
          </Button>
        );
      case "update_event":
        return (
          <Button
            onClick={() => this.updateEvent()}
            size="small"
            color="secondary"
            variant="contained"
            className="head-add-section__btn "
          >
            See detail event
          </Button>
        );
      case "add_image":
        return (
          <Button
            onClick={() => this.props.modalToggleReset()}
            size="small"
            color="secondary"
            variant="contained"
            className="head-add-section__btn "
          >
            See image list
          </Button>
        );
      case "post_caption":
        return (
          <Button
            onClick={() => this.props.modalToggleReset()}
            size="small"
            color="secondary"
            variant="contained"
            className="head-add-section__btn "
          >
            See detail home
          </Button>
        );
      default:
        return (
          <Button
            onClick={() => this.props.modalToggleReset()}
            size="small"
            color="secondary"
            variant="contained"
            className="head-add-section__btn "
          >
            Close
          </Button>
        );
        break;
    }
  };
  render() {
    const { modal_data, modal_title, modal_action } = this.props.general;
    return (
      <div className="alert-container">
        <img
          src={modal_action === "error" ? close : Checklist}
          style={{ width: "40%" }}
        />
        <br />
        <h4>{modal_title}</h4>
        {/* <p>{modal_data.msg}</p> */}
        <div dangerouslySetInnerHTML={{ __html: modal_data.msg }}></div>
        <MuiThemeProvider theme={themeButton}>
          {this.renderFooter()}
        </MuiThemeProvider>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  general: state.general,
});
const mapDispatchToProps = {
  modalToggleReset,
  modalToggle,
  setAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(alert);
