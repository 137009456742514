import React,{useState,useEffect} from 'react'
import {Button,FormControl,InputLabel,OutlinedInput,InputAdornment} from '@material-ui/core'
import SearchImg from 'assets/icon/Search.png'

import Layout from 'components/Layouts'
import moment from 'moment'
import {Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
import Eye from 'assets/icon/eye.svg'
import Edit from 'assets/icon/edit.svg'
import Close from 'assets/icon/close.svg'
import Skeleton from 'components/Skeleton'
import {modalToggle} from 'redux/actions/general'
import { useDispatch, useSelector } from "react-redux";
import {getCourse,getDetailAlumni,setAlumniDetail,setAlumniUpload, setCourseId,getAlumni,getExpert,deleteAlumni,deleteExpert,getCourseSearch } from 'redux/actions/cdhx'
import AutoCompleteSelect from 'components/Select'
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import {debounce, isEmpty} from 'lodash'
export default function List(props) {
    const [alumnisearch, setalumnisearch] = useState('')
    const [expertsearch, setexpertsearch] = useState('')
    const [textSelect,setTextSelect]=useState('Type course name')
    const [loading,setLoading]=useState(false)
    const [search, setsearch] = useState('')
    const cdhx = useSelector(state => state.cdhx)
    const general = useSelector(state => state.general)
    const {course_list,course_id,alumn_list,expert_list,alumn_pagination,expert_pagination}=cdhx
    const {isLoadingTable,isLoadingTable2}=general
    const dispatch=useDispatch()
    useEffect(() => {
        if(course_id!==null){
            dispatch(getAlumni(`/${course_id.id}/1/10/*`))
            dispatch(getExpert(`/${course_id.id}/1/10/*`))
        }
    }, [course_id])
    const addAlumnni=()=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Add New Alumni",
            modal_component: "alumni",
            modal_size:400,
            modal_data:null,
            modal_action:'add_alumni',
        }))
    }
    const uploadAlumni=()=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Upload Alumni",
            modal_component: "upload_alumni",
            modal_size:400,
            modal_data:null,
            modal_action:'upload_alumni',
            modal_type:'multi'
        }))
    }
    const addForumExpert=()=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Add Forum Expert",
            modal_component: "forum_expert",
            modal_size:350,
            modal_data:null,
            modal_action:'add_forum_expert',
        }))
    }
    const actionDeleteAlumni=async (data)=>{
        let res=await dispatch(deleteAlumni(`/${data.id}/${course_id.id}`))
        if(res){
            dispatch(getAlumni(`/${course_id.id}/1/10/*`))
        }
    }
    const ondeleteAlumni=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "list alumni",
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                ...data,
                confirm_action:()=>actionDeleteAlumni(data),
                msg:`<p>Are you sure delete <b>${data.name}</b></p>`
            },
            modal_action:'confirm_action'
        }))
    }
    const detailAlumni=async (data)=>{
        let res= await dispatch(getDetailAlumni(data.id))
        if(res){
            props.settab('detail')
        }
    }
    const actionDeleteExpert=async (data)=>{
        let res=await dispatch(deleteExpert(`/${data.id}`))
        if(res){
            dispatch(getExpert(`/${course_id.id}/1/10/*`))
        }
    }
    const ondeleteExpert=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "list expert",
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                ...data,
                confirm_action:()=>actionDeleteExpert(data),
                msg:`<p>Are you sure delete <b>${data.name}</b></p>`
            },
            modal_action:'confirm_action'
        }))
    }
    const editExpert=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Edit Forum Expert",
            modal_component: "forum_expert",
            modal_size:350,
            modal_data:data,
            modal_action:'edit_forum_expert',
        }))
    }
    const onChangeAlumni=debounce(async (value)=>{
        if(course_id!==null){
            setalumnisearch(value)
            dispatch(getAlumni(`/${course_id.id}/1/10/${value===''?'*':value}`))
        }
        
    },1000)
    const onChangeExpert=debounce(async (value)=>{
        if(course_id!==null){
            setexpertsearch(value)
            dispatch(getExpert(`/${course_id.id}/1/10/${value===''?'*':value}`))
        }
        
    },1000)
    const alumniTogglePagination=(page,pageLength)=>{
        dispatch(getAlumni(`/${course_id.id}/${page}/${pageLength}/${alumnisearch===''?'*':alumnisearch}`))

    }
    const expertTogglePagination=(page,pageLength)=>{
        dispatch(getExpert(`/${course_id.id}/${page}/${pageLength}/${expertsearch===''?'*':expertsearch}`))

    }
    const handleDisable=()=>{
        if(course_id===null){
            return true
        }else{
            return false
        }
    }
    const onChangeCourse=debounce(async (value)=>{
        let findCourse=course_list.filter((data)=>{
            return data.label.toLowerCase().includes(value.toLowerCase())
        })
        if(isEmpty(findCourse)){
            setsearch(value)

            if(value!==search&&value.length>0){
                setLoading(true)
                let res=await dispatch(getCourseSearch(value))
                if(isEmpty(res)){
                    setTextSelect('No options')
                }
                setLoading(false)
            }else{
                dispatch(getCourse())
            }
        }
        
    },1000)
    const onChange=(value)=>{
        dispatch(setCourseId(value))
        dispatch(setAlumniDetail({eventId:value}))
        dispatch(setAlumniUpload({eventId:value}))
    }
    return (
        <div>
            <Layout>
                <br/>
                <AutoCompleteSelect
                    color='secondary'
                    noOptionsText={textSelect}
                    loading={loading}
                    disableClearable={true}
                    onChange={(event,value)=>onChange(value)}
                    options={course_list}
                    value={course_id}
                    groupBy={(option)=>{
                        if(search===''){
                            return '5 Course terbaru'
                        }else{
                            return null
                        }
                    }}
                    getOptionLabel={(option) => option.label}
                    onInputChange={(event,e)=>onChangeCourse(e)}

                    label="Choose Course Name First"
                />
                <div className='head-section'>
                    <div>
                        <h3>CDHX Alumni</h3>
                    </div>
                    <div>
                        <Button onClick={addAlumnni} size='small' color='secondary' variant='outlined' className='head-section__btn'>Add New Alumni</Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button onClick={uploadAlumni} size='small' color='secondary' variant='contained' className='head-section__btn'>Upload Alumni</Button>
                    </div>
                </div>
                <div className='card-content'>
                    <div className='card-list'>
                    <div className='card-table'>
                       <div className='card-table__head'>
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="input-with-icon-textfield">Search</InputLabel>
                                <OutlinedInput
                                    size='small'
                                    style={{height:30,width:200,}}
                                    id="input-with-icon-textfield"
                                    name='search'
                                    onChange={(e)=>onChangeAlumni(e.target.value)}
                                    required
                                    startAdornment={
                                    <InputAdornment position="start">
                                       <img alt="search" src={SearchImg} style={{width:15}}/>
                                    </InputAdornment>
                                    }
                                    labelWidth={50}
                                />
                            </FormControl>
                            <div>
                                
                            </div>
                       </div>
                       <div className='card-table__content'>
                       <Table  size="small" aria-label="a dense table">
                            <TableHead>
                            <TableRow>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Company Name</TableCell>
                                <TableCell align="left">Department</TableCell>
                                <TableCell align="left">Job Title</TableCell>
                                <TableCell align="left">Email</TableCell>
                                <TableCell align="left">Phone Number</TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoadingTable!==true?alumn_list.map((data,i)=>(
                                    <TableRow key={i}>
                                        <TableCell >{data.name}</TableCell>
                                        <TableCell>{data.company}</TableCell>
                                        <TableCell>{data.department}</TableCell>
                                        <TableCell>{data.jobTitle}</TableCell>
                                        <TableCell>{data.email}</TableCell>
                                        <TableCell>{data.phone}</TableCell>
                                        <TableCell align="right">
                                            <img src={Edit} onClick={()=>detailAlumni(data)} className='icon-action'/>
                                            <img src={Close} className='icon-action' onClick={()=>ondeleteAlumni(data)}/>
                                        </TableCell>
                                    </TableRow>
                                )):<Skeleton count={7}/>}
                                
                            </TableBody>
                        </Table>
                        {alumn_pagination!==null&&
                            <div className='card-table__pagination'>
                                <TablePagination
                                    className="card-pagination"
                                    type="reduced"
                                    page={alumn_pagination.page}
                                    pageLength={alumn_pagination.perPage}
                                    totalRecords={alumn_pagination.total}
                                    onPageChange={({ page, pageLength }) => {
                                        alumniTogglePagination( page, pageLength )
                                    }}
                                    prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                                    nextPageRenderer={() => <img src={CevronRight}/>}
                                />
                            </div>
                        }
                       </div>
                   </div>
                    </div>
                
                </div>
                <br/>
                <div className='head-section'>
                    <div>
                        <h3>Forum Experts</h3>
                    </div>
                    <div>
                        <Button disabled={handleDisable()} onClick={addForumExpert} size='small' color='secondary' variant='contained' className='head-section__btn'>Add Expert Forum</Button>
                    </div>
                </div>
                <div className='card-content'>
                    <div className='card-list'>
                    <div className='card-table'>
                       <div className='card-table__head'>
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="input-with-icon-textfield">Search</InputLabel>
                                <OutlinedInput
                                    size='small'
                                    style={{height:30,width:200,}}
                                    id="input-with-icon-textfield"
                                    name='search'
                                    onChange={(e)=>onChangeExpert(e.target.value)}
                                    required
                                    startAdornment={
                                    <InputAdornment position="start">
                                       <img alt="search" src={SearchImg} style={{width:15}}/>
                                    </InputAdornment>
                                    }
                                    labelWidth={50}
                                />
                            </FormControl>
                            <div>
                               
                            </div>
                       </div>
                       <div className='card-table__content'>
                       <Table  size="small" aria-label="a dense table">
                            <TableHead>
                            <TableRow>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Email</TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoadingTable2!==true?expert_list.map((data,i)=>(
                                    <TableRow key={i}>
                                        <TableCell>{data.name}</TableCell>
                                        <TableCell>{data.email}</TableCell>
                                        <TableCell align="right">
                                            <img src={Edit} onClick={()=>editExpert(data)} className='icon-action'/>
                                            <img src={Close} className='icon-action' onClick={()=>ondeleteExpert(data)}/>
                                        </TableCell>
                                    </TableRow>
                                )):<Skeleton count={3}/>}
                                
                            </TableBody>
                        </Table>
                        {expert_pagination!==null&&
                            <div className='card-table__pagination'>
                                <TablePagination
                                    className="card-pagination"
                                    type="reduced"
                                    page={expert_pagination.page}
                                    pageLength={expert_pagination.perPage}
                                    totalRecords={expert_pagination.total}
                                    onPageChange={({ page, pageLength }) => {
                                        expertTogglePagination( page, pageLength )
                                    }}
                                    prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                                    nextPageRenderer={() => <img src={CevronRight}/>}
                                />
                            </div>
                        }
                       </div>
                   </div>
                    </div>
                
                </div>
            </Layout>
        </div>
    )
}
