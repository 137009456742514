import React, { Component } from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
} from "@material-ui/core";
import image_line from "assets/icon/image_line.svg";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import { handleFile, getBase64 } from "components/handleFile";
import { connect } from "react-redux";
import { modalToggleReset } from "redux/actions/general";
import { postBanner, putBanner } from "redux/actions/cdhx";
const themeButton = createMuiTheme({
  palette: {
    primary: {
      main: "#4CAF50",
    },
    secondary: {
      main: "#f44336",
    },
  },
});
class testimony extends Component {
  state = {
    bannerId: 0,
    file_error: null,
    file_url: null,
    file_name: null,
    file: null,
    link: "",
    category: "cdhx",
  };
  componentWillMount() {
    const { modal_data } = this.props.general;
    console.log("modal", modal_data, this.props.event.testimony);
    if (modal_data !== null) {
      this.setState({
        file: null,
        file_url: modal_data.url,
        link: modal_data.link,
        bannerId: modal_data.bannerId,
      });
    }
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  handleTestimony = (evt) => {
    let handle = handleFile(evt);
    if (handle.file_error === null) {
      let file_url = URL.createObjectURL(handle.file);
      // getBase64(handle.file,(result)=>{

      // })
      this.setState({
        file_url,
        file: handle.file,
        file_error: null,
        file_name: handle.file_name,
      });
    } else {
      console.log("err", handle);
      this.setState({ file_error: handle.file_error });
    }
  };
  deleteImage = () => {
    this.setState({ file_error: null, file_url: null, file: null });
  };

  addBanner = () => {
    let fd = new FormData();
    fd.append("BannerId", this.state.bannerId);
    fd.append("image", this.state.file);
    fd.append("link", this.state.link);
    fd.append("publish", 1);
    fd.append("category", this.state.category);
    fd.append("userId", this.props.profile.id);
    this.props.postBanner(this.props.token, fd);
    // this.props.modalToggleReset()
  };
  updateBanner = () => {
    let fd = new FormData();
    fd.append("BannerId", this.state.bannerId);
    fd.append("image", this.state.file);
    fd.append("link", this.state.link);
    fd.append("publish", 1);
    fd.append("category", this.state.category);

    fd.append("userId", this.props.profile.id);
    this.props.putBanner(this.props.token, fd);
    // this.props.modalToggleReset()
  };
  handleDisable = () => {
    if (this.state.file_url !== null) {
      return false;
    } else {
      return true;
    }
  };
  render() {
    const { file, name, file_error, file_url, link } = this.state;
    const { modal_action } = this.props.general;

    return (
      <div className="testimony-modal-wrapper">
        <p className="bold" style={{ marginBottom: 20 }}>
          Image
        </p>
        <div className="card-container">
          {file_url !== null ? (
            <img
              src={file_url}
              style={{
                width: 200,
                marginRight: 20,
                height: 200,
                borderRadius: 10,
                objectFit: "cover",
              }}
            />
          ) : (
            <div className="square-image-banner">
              <MuiThemeProvider theme={themeButton}>
                <Button
                  className="btn-remove-capital"
                  variant="text"
                  startIcon={<img src={image_line} />}
                  color="primary"
                  component="label"
                >
                  Add image here
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={this.handleTestimony}
                  />
                </Button>
              </MuiThemeProvider>
            </div>
          )}
          {file_error !== null && (
            <p style={{ color: "red", fontSize: 10 }}>{file_error}</p>
          )}
          <div className="testimony-form">
            <FormControl
              variant="outlined"
              size="small"
              className="add-investment__field"
            >
              <InputLabel htmlFor="date">Link</InputLabel>
              <OutlinedInput
                id="link"
                type="text"
                name="link"
                value={link}
                onChange={this.onChange}
                required
                labelWidth={40}
                className="field-radius"
              />
            </FormControl>
          </div>
        </div>
        <div className="card-event-footer">
          <div>
            <MuiThemeProvider theme={themeButton}>
              <Button
                className="btn-remove-capital"
                variant="text"
                color="secondary"
                onClick={this.deleteImage}
                style={{ padding: 0 }}
              >
                Delete image
              </Button>
            </MuiThemeProvider>
            <p className="semi-bold">
              For beter banner use image with 1667 x 617
            </p>
          </div>

          {modal_action === "add_banner" ? (
            <Button
              disabled={this.handleDisable()}
              onClick={this.addBanner}
              size="small"
              color="primary"
              variant="contained"
              className="head-add-section__btn "
            >
              Add
            </Button>
          ) : (
            <Button
              disabled={this.handleDisable()}
              onClick={this.updateBanner}
              size="small"
              color="primary"
              variant="contained"
              className="head-add-section__btn"
            >
              Update
            </Button>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  event: state.event,
  general: state.general,
});
const mapDispatchToProps = {
  postBanner,
  modalToggleReset,
  putBanner,
};
export default connect(mapStateToProps, mapDispatchToProps)(testimony);
