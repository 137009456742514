import React, { Component } from 'react'
import {FormControl,InputLabel,OutlinedInput,Button,MenuItem} from '@material-ui/core'
import image_line from 'assets/icon/image_line.svg'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
import {handleFile} from 'components/handleFile'
import {connect} from 'react-redux'
import {modalToggleReset} from 'redux/actions/general'
import {
    setImage,
    setCaption,
    setEventId,
    addImage,
    updateImage
} from 'redux/actions/event'
import Select from 'react-select'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#e91e63',
            contrastText: '#FFFFFF',
        },
        secondary:{
            main:'#ff9800',
            contrastText: '#FFFFFF',
        }
    } 
})
class image extends Component {
    state={
        image_url:'',
        image_error:null,
    }
    componentWillMount(){
        if(this.props.modal_data!==null){
            const {id,caption,imageURL,eventId}=this.props.modal_data
            this.setState({image_url:imageURL})
            this.props.setCaption(caption)
            this.props.setEventId(eventId)
        }
        
        
    }
    handleImage=(evt)=>{
        let handle= handleFile(evt)
        if(handle.file_error===null){
            let image_url=URL.createObjectURL(handle.file)
            this.setState({image_url,thumbnail_error:null})
            this.props.setImage(handle.file)
        }else{
            this.setState({image_error:handle.file_error})
        }
      
    }
    deleteImage=()=>{
        this.setState({image_url:''})
        this.props.setImage(null)
    }
    disableToggle=()=>{
        const {modal_type}=this.props.general
        let {image,caption,event_id}=this.props.event
        let {image_url}=this.state
        if(modal_type==='add' ){
            if(image===null||caption===''||event_id===''){
                return true
            }else{
                return false
            }
        }else{
            if(image_url===''||caption===''||event_id===''){
                return true
            }else{
                return false
            }
        }
        
    }
    buttonToggle=async(key)=>{
        const {token,profile,event}=this.props
        const {modal_type}=this.props.general
        let {image,caption,event_id,active_tab}=this.props.event
        await this.props.modalToggleReset()
        if(modal_type==='add'){
            let fd=new FormData()
            fd.append('caption',caption)
            fd.append('image',image)
            fd.append('publish',key==='publish'?1:0)
            fd.append('eventId',event_id)
            fd.append('userId',profile.id)
            await this.props.addImage(token,fd,active_tab,active_tab)
            // this.props.modalToggleReset()
        }else{
            const {id}=this.props.modal_data
            let fd=new FormData()
            fd.append('id',id)
            fd.append('caption',caption)
            fd.append('image',image)
            fd.append('publish',key==='publish'?1:0)
            fd.append('eventId',event_id)
            fd.append('userId',profile.id)
            await this.props.updateImage(token,fd,id,`/0/${active_tab==='drafted'?0:1}/1/10/*`,active_tab)
        }
    }
    render() {
        const {event,setEventId}=this.props
        console.log('event', event)
        return (
            <div>
                <div className='card-container'>
                    <div className='framework-image'>
                        <p className='bold'>Image</p>
                        {this.state.image_url!==''?
                        <img src={this.state.image_url} style={{width:200,height:180,borderRadius:10,objectFit:'cover'}}/>
                        :
                        <div className='square-image-big'>
                            <MuiThemeProvider theme={themeButton}>
                                <Button
                                    className='btn-remove-capital'
                                    variant='text'
                                    startIcon={<img src={image_line}/>}
                                    color='primary'
                                    component="label"
                                >
                                    Add image here
                                    <input
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={this.handleImage}
                                    />
                                </Button>
                            </MuiThemeProvider>
                        </div>
                        }
                    </div>
                   
                    <div className='framework-image-caption'>
                      
                       <FormControl variant="outlined" size="small" style={{width:'100%'}}>
                            <InputLabel  htmlFor="Caption">Caption</InputLabel>
                            <OutlinedInput
                                
                                id="Caption"
                                type='textarea'
                                name='Caption'
                                value={event.caption}
                                onChange={(e)=>this.props.setCaption(e.target.value)}
                                required
                                rows={5}
                                multiline
                                labelWidth={60}
                                className='field-radius'
                                
                            />
                        </FormControl>
                        <br/><br/>
                        
                        <Select 
                            options={event.list_event_all} 
                            value={event.list_event_all!==null&&event.list_event_all.filter(option=>{return option.value===event.event_id})}
                            onChange={(data)=>this.props.setEventId(data.value)}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 10,
                                colors: {
                                ...theme.colors,
                                primary: '#e91e63',
                                },
                            })}
                        />
                    </div>
                </div>
                <div className='card-event-footer'>
                    <MuiThemeProvider theme={themeButton}>
                        <Button
                            className='btn-remove-capital'
                            variant='text'
                            color='primary'
                            onClick={this.deleteImage}
                        >
                            Delete image
                        </Button>
                    </MuiThemeProvider>
                    {/* {modal_action==='add_framework'? */}
                    <div>
                    <MuiThemeProvider theme={themeButton}>
                        <Button onClick={()=>this.buttonToggle('draft')} disabled={this.disableToggle()}  size='small' color='primary' variant='contained' className='head-add-section__btn save'>Save</Button>
                        <Button onClick={()=>this.buttonToggle('publish')} disabled={this.disableToggle()}  size='small' color='secondary' variant='contained' className='head-add-section__btn '>Publish</Button>
                    </MuiThemeProvider>
                    </div>
                    {/* : */}
                    {/* <Button onClick={this.updateFramework}  size='small' color='primary' variant='contained' className='head-add-section__btn'>Update</Button> */}
                    {/* } */}
                    
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    event:state.event,
    general:state.general
})
const mapDispatchToProps={
    setImage,
    setCaption,
    setEventId,
    addImage,
    modalToggleReset,
    updateImage
}
export default connect(mapStateToProps,mapDispatchToProps)(image)