import React, { Component } from 'react'
import Navbar from 'components/Navbar'
import Sidebar from 'components/Sidebar'
import Layout from 'components/Layouts'
import {Button,FormControl,InputLabel,OutlinedInput,InputAdornment,
    Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
import SearchImg from 'assets/icon/Search.png'
import {connect} from 'react-redux'
import {getEvent,getDetailEvent,setAction,clearState,tabToggle,setSearch,getCategory,getType,getLocation} from 'redux/actions/event'
import {modalToggle} from 'redux/actions/general'
import EventPublish from 'views/event_management/list/list_published'
import {debounce ,isEmpty} from 'lodash'
import CareersPublihs from 'views/careers/list/list_published'
import {getCarrers} from 'redux/actions/careers'
class list extends Component {
    state={
        careers:[],
        search:''
    }
    componentDidMount(){
        this.props.getCarrers(this.props.token)
        this.props.getCategory()
        this.props.getType()
        this.props.getLocation()
    }
    componentWillReceiveProps(newProps){
        let careers=newProps.careers.careers
       
        this.spliceCarrer(careers)
    }
    getEvent=(slug,key,search='*')=>{
        this.props.getEvent(`${slug}/${search}`,key)
    }
    searchByType=debounce(async(value)=>{
        let {search}=this.state
        let {active_tab}=this.props.event
        this.getEvent(`/0/1/1/10`,'published',value.length>0?value:'*')
    },1000)
    searchToggle=async(e)=>{
        let {value}=e.target
        let {active_tab,search}=this.state
        const {event}=this.props
        await this.props.setSearch(value)
        this.setState({search:value},()=>{
            if(event.search.length>0){
                this.searchByType(value)
            }
        })
       
        
    }
    tabToggle=(key)=>{
        this.setState({active_tab:key})
        this.props.tabToggle(key)
    }
    detailEvent=async (id)=>{
        let res=await this.props.getDetailEvent(id)
        if(res){
            this.props.tabsToggle('add_event')
            this.props.setAction('update_event')
        }
    }
    seeEventOnly=async(id)=>{
        let res=await this.props.getDetailEvent(id)
        if(res){
            this.props.tabToggle('published')
            this.props.tabsToggle('add_event')
            this.props.setAction('see_event')
        }
        // window.location.assign(`/eventmanagement/see/${id}`)
    }
    
    onClickAdd=()=>{
        this.props.tabsToggle('add_event')
        this.props.setAction('add_event')
        this.props.clearState()
    }
    deleteEvent=(data)=>{
        console.log('data',data)
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "Event",
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:data.webEvent,
            modal_action:'delete_event'
        })
    
    }
    unPublish=(data)=>{
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "dashboard",
            modal_component: "confirm_unpublish",
            modal_size:400,
            modal_type:'confirm',
            modal_data:data.webEvent,
            modal_action:'unpublish_event',
            success_msg:'See event list'
        })
    }
    detailCareers=(data)=>{
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "Detail career",
            modal_component: "career",
            modal_size:500,
            modal_type:'readonly',
            modal_data:data,
        })
    }
    unPublishCareer=(data)=>{
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "dashboard",
            modal_component: "confirm_unpublish",
            modal_size:400,
            modal_type:'confirm',
            modal_data:data,
            modal_action:'unpublish_career'
        })
    }
    spliceCarrer=(careers)=>{
        let publish=careers.filter((data)=>{
            return data.publish===1
        })
        this.setState({careers:publish})
    }
    searchCareer=(e)=>{
        let {value}=e.target
        let {careers}=this.props.careers
        let publish=careers.filter((data)=>{
            return data.publish===1
        })
        var publish_filter = publish.filter(item=>item.title.toLowerCase().includes(value));
        if(value!==''){
            this.setState({ careers: publish_filter })

        }else{
            this.spliceCarrer(careers)
        }
    }
    render() {
        let {tabToggle,event,general}=this.props
        let {careers}=this.state
        console.log('this.props.token', this.props.token)
        return (
            <div>
                    <div className='head-section'>
                        <div>
                            <h3>Dashboard</h3>
                        </div>
                        <div>
                            {/* <Button onClick={this.onClickAdd} size='small' color='secondary' variant='contained' className='head-section__btn'>Create new event</Button> */}
                        </div>
                    </div>
                    <div className='card-content'>
                    <div className='card-list'>
                        <p className='bold'>Published event management</p>
                        <div className='card-table'>
                            <div className='card-table__head'>
                                    <FormControl variant="outlined">
                                        <InputLabel htmlFor="input-with-icon-textfield">Search</InputLabel>
                                        <OutlinedInput
                                            size='small'
                                            style={{height:30,width:200,}}
                                            id="input-with-icon-textfield"
                                            name='password'
                                            onChange={this.searchToggle}
                                            required
                                            // value={search}
                                            startAdornment={
                                            <InputAdornment position="start">
                                            <img alt="search" src={SearchImg} style={{width:15}}/>
                                            </InputAdornment>
                                            }
                                            labelWidth={50}
                                        />
                                    </FormControl>
                                    <div>
                                        {/* <button className='card-table__head_btn'>Filter</button>
                                        <button className='card-table__head_btn'>Sort</button>
                                        */}
                                    </div>
                            </div>
                            <div className='card-table__content'>
                            
                            <EventPublish isLoadingTable={general.isLoadingTable} getEvent={this.getEvent} seeEventOnly={this.seeEventOnly} deleteEvent={this.deleteEvent} unPublish={this.unPublish} detailEvent={this.detailEvent} event={event}/>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <br/>
                <div className='card-content'>
                <div className='card-list'>
                    <p className='bold'>Published careers</p>
                    <div className='card-table'>
                        <div className='card-table__head'>
                                <FormControl variant="outlined">
                                    <InputLabel htmlFor="input-with-icon-textfield">Search</InputLabel>
                                    <OutlinedInput
                                        size='small'
                                        style={{height:30,width:200,}}
                                        id="input-with-icon-textfield"
                                        name='password'
                                        onChange={this.searchCareer}
                                        required
                                        // value={search}
                                        startAdornment={
                                        <InputAdornment position="start">
                                        <img alt="search" src={SearchImg} style={{width:15}}/>
                                        </InputAdornment>
                                        }
                                        labelWidth={50}
                                    />
                                </FormControl>
                                <div>
                                    {/* <button className='card-table__head_btn'>Filter</button>
                                    <button className='card-table__head_btn'>Sort</button>
                                    */}
                                </div>
                        </div>
                        <div className='card-table__content'>
                        
                        <CareersPublihs isLoadingTable={general.isLoadingTable} careers={careers} unPublish={this.unPublishCareer} detailCareers={this.detailCareers}/>
                        </div>
                        
                    </div>
                </div>
            </div>
            </div>
        )
    }
}
const mapStateToProps=state=>({
    event:state.event,
    careers:state.careers,
    general:state.general
})
const mapDispatchToProps={
    getEvent,
    getDetailEvent,
    setAction,
    modalToggle,
    clearState,
    tabToggle,
    getCarrers,
    setSearch,
    getCategory,getType,getLocation
}
export default connect(mapStateToProps,mapDispatchToProps)(list)