import * as actionTypes from '../constants/preview'
import moment from 'moment'
const initialState={
    title:'',
    start_date:moment().format('YYYY-MM-DD'),
    end_date:moment().format('YYYY-MM-DD'),
    start_time:null,
    end_time:null,
    address:'',
    category:'',
    status:'', 
    target:'',
    thumbnail:null,
    brochure:null,
    description:'',
    additional:'',
    framework:[],
    testimony:[],
    agenda:[],
    investment:[],
    speaker:[],
    short_desc:'',
    list_event:[],
    event_id:''
}

export default (state=initialState,action)=>{
    switch (action.type) {
        case actionTypes.PREVIEW_SET_TITLE:
            return{
                ...state,
                title:action.payload
            }
        case actionTypes.PREVIEW_SET_DATE:
            return{
                ...state,
                date:action.payload
            }
        case actionTypes.PREVIEW_SET_START_DATE:
            return{
                ...state,
                start_date:action.payload
            }
        case actionTypes.PREVIEW_SET_END_DATE:
            return{
                ...state,
                end_date:action.payload
            }   
        case actionTypes.PREVIEW_SET_START_TIME:
            return{
                ...state,
                start_time:action.payload
            }
        case actionTypes.PREVIEW_SET_END_TIME:
            return{
                ...state,
                end_time:action.payload
            }   
        case actionTypes.PREVIEW_SET_ADDRESS:
            return{
                ...state,
                address:action.payload
            }
        case actionTypes.PREVIEW_SET_CATEGORY:
            return{
                ...state,
                category:action.payload
            }
        case actionTypes.PREVIEW_SET_STATUS:
            return{
                ...state,
                status:action.payload
            }
        case actionTypes.PREVIEW_SET_TARGET:
            return{
                ...state,
                target:action.payload
            }   
        case actionTypes.PREVIEW_SET_THUMBNAIL:
            return{
                ...state,
                thumbnail:action.payload
            }
        case actionTypes.PREVIEW_SET_BROCHURE:
            return{
                ...state,
                brochure:action.payload
            }
        case actionTypes.PREVIEW_SET_DESCRIPTION:
            return{
                ...state,
                description:action.payload
            }
        case actionTypes.PREVIEW_SET_ADDITIONAL:
            return{
                ...state,
                additional:action.payload
            }   
        case actionTypes.PREVIEW_SET_FRAMEWORK:
            return{
                ...state,
                framework:action.payload
            }   
        case actionTypes.PREVIEW_SET_TESTIMONY:
            return{
                ...state,
                testimony:action.payload
            }   
        case actionTypes.PREVIEW_SET_AGENDA:
            return{
                ...state,
                agenda:action.payload
            }   
        case actionTypes.PREVIEW_SET_INVESTMENT:
            return{
                ...state,
                investment:action.payload
            }   
        case actionTypes.PREVIEW_SET_SPEAKER:
            return{
                ...state,
                speaker:action.payload
            }   
        case actionTypes.PREVIEW_SET_SHORT_DESC:
            return{
                ...state,
                short_desc:action.payload
            }   
        case actionTypes.PREVIEW_SET_ID:
            return{
                ...state,
                event_id:action.payload
            }   
        case actionTypes.GET_EVENT:
            return{
                ...state,
                list_event:action.payload
            }   
        default:
            return state;
    }
}