import React,{useState} from 'react'
import Layout from 'components/Layouts'
import '@trendmicro/react-paginations/dist/react-paginations.css';
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import Edit from 'assets/icon/edit.svg'
import Close from 'assets/icon/close.svg'
import {Button,TextField,FormControl,InputLabel,OutlinedInput,Select,MenuItem,
    FormHelperText,Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from "react-redux";
import {modalToggle} from 'redux/actions/general'
import InputMask from 'react-input-mask'
import AutoCompleteSelect from 'components/Select'
import { putAlumni,setAlumniDetail,getClient,deleteCourse,getDetailAlumni } from 'redux/actions/cdhx';
import { debounce,isEmpty } from 'lodash';
const themeField = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00acc1', 
        },
    } 
})
const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,
      },
      width:'80%',
      marginBottom:15
  }
  
}));
export default function Detail_alumni(props) {
    const {action,tab,settab}=props
    const cdhx = useSelector(state => state.cdhx)
    const [search, setsearch] = useState('')
    const [loading, setLoading] = useState(false)
    const {id,eventId,clientId,name,email,phone,department,jobTitle,events}=cdhx.alumni_detail
    const dispatch = useDispatch()
    const {alumni_detail,course_id}=cdhx
    const classes=useStyles()
    const [textSelect,setTextSelect]=useState('Type company name')

    const onChange=()=>{
        
    }
    const adcourse=()=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Add Course",
            modal_component: "add_course",
            modal_size:350,
            modal_data:null,
            modal_action:'add_course',
        }))
    }
    const goBack=()=>{
        settab('list')
    }
    const selectClient=(data)=>{
        dispatch(setAlumniDetail({clientId:data}))
    }
    const searchToggle=debounce(async (value)=>{
        setsearch(value)
        setLoading(true)
        if(value!==search&&value.length>0){
            setLoading(true)
            let res=await dispatch(getClient(value))
            if(isEmpty(res)){
                setTextSelect('No options')
            }
            setLoading(false)
        }
        
    },1000)
    const actionDeleteCourse=async (data)=>{
        let res=await dispatch(deleteCourse(`/${alumni_detail.id}/${data.id}`))
        if(res){
            dispatch(getDetailAlumni(alumni_detail.id))
        }
    }
    const onDeleteCourse=(data)=>{
        if(tab==='edit'){
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "detail alumni",
                modal_component: "confirm_delete",
                modal_size:400,
                modal_type:'confirm',
                modal_data:{
                    ...data,
                    confirm_action:()=>actionDeleteCourse(data),
                    msg:`<p>Are you sure delete <b>${data.text}</b></p>`
                },
                modal_action:'confirm_action'
            }))
        }
        
    }
    const onClickSave=()=>{
        if(tab==='detail'){
            settab('edit')
        }else{
            let data={
                id,
                clientId:clientId.id,
                eventId:course_id.id,
                name,
                email,
                phone,
                department,
                jobTitle,
                userId:props.profile.id
            }
            dispatch(putAlumni(data))
        }
    }
    const handleDisable=()=>{
        if(tab==='detail'){
            return true
        }else{
            return false
        }
    }
    return (
        <div>
            <Layout>
                <div className='head-section'>
                    <div>
                        <h3>Alumni Information Detail</h3>
                    </div>
                    <div>
                        <Button  onClick={()=>goBack()} size='small' color='primary' variant='outlined' className='head-add-section__btn back'>Back</Button>
                        &nbsp;&nbsp;
                        <Button  onClick={onClickSave} size='small' color='primary' variant='contained' className='head-section__btn'>{tab==='detail'?'Edit':'Save'}</Button>
                    </div>
                </div>
                <div className='card-content'>
                    <div className='div-flex' style={{padding:20}}>
                        <div style={{width:'50%'}}>
                            <p className='bold'>Welcome section</p>
                            <br/>
                            <MuiThemeProvider theme={themeField}>
                            <TextField
                                label={<>Name <span style={{color:'red'}}>*</span></>}
                                variant='outlined'
                                value={name}
                                size='small'
                                disabled={handleDisable()}
                                name="name"
                                className={classes.textField}
                                onChange={(e)=>dispatch(setAlumniDetail({name:e.target.value}))}

                            />
                            <div style={{width:'80%'}}>
                            <AutoCompleteSelect
                                 color='secondary'
                                 noOptionsText={textSelect} 
                                 loading={loading}
                                 disableClearable={true}
                                 onChange={(event,value)=>selectClient(value)}
                                 options={cdhx.client}
                                 getOptionLabel={(option) => option.text}
                                 onInputChange={(event,e)=>searchToggle(e)}
                                 value={clientId}
                                disabled={handleDisable()}
                                 
                                 label={<>Company Name <span style={{color:'red'}}>*</span></>}
                            />
                            </div>
                            <TextField
                                label={<>Department <span style={{color:'red'}}>*</span></>}
                                variant='outlined'
                                value={department}
                                size='small'
                                disabled={handleDisable()}
                                name="department"
                                className={classes.textField}
                                onChange={(e)=>dispatch(setAlumniDetail({department:e.target.value}))}

                            />
                            <TextField
                                label={<>Job Title <span style={{color:'red'}}>*</span></>}
                                variant='outlined'
                                value={jobTitle}
                                size='small'
                                disabled={handleDisable()}
                                name="jobTitle"
                                className={classes.textField}
                                onChange={(e)=>dispatch(setAlumniDetail({jobTitle:e.target.value}))}

                            />
                            <p className='bold'>Contact</p>
                            <br/>
                            
                            <TextField
                                label={<>Email <span style={{color:'red'}}>*</span></>}
                                variant='outlined'
                                value={email}
                                size='small'
                                disabled={handleDisable()}
                                name="email"
                                className={classes.textField}
                                onChange={(e)=>dispatch(setAlumniDetail({email:e.target.email}))}

                            />
                            <InputMask
                                mask="9999-9999-9999-9999"
                                maskChar={null}
                                value={phone}
                                onChange={(e)=>dispatch(setAlumniDetail({phone:e.target.value}))}
                                name="phoneNumber"
                                id="phoneNumber"
                                disabled={handleDisable()}

                                // value={emp.phone}
                            >
                            {() =>
                                <TextField
                                    label={<>Phone No. <span style={{color:'red'}}>*</span></>}
                                    variant='outlined'
                                    // value={event.title}
                                    size='small'
                                    disabled={handleDisable()}
                                    name="phoneNumber"
                                    className={classes.textField}
                    
                                />
                            }
                        </InputMask>
                             </MuiThemeProvider>
                        </div>
                        <div style={{width:'50%'}}>
                            <div className='div-flex div-space-between' >
                                <p className='bold'>Add Course</p>
                                <Button disabled={handleDisable()} onClick={adcourse} size='small' color='secondary' variant='contained' className='head-section__btn'>Add Course</Button>
                            </div>
                            <div className='table-section'>
                                <Table  size="small" aria-label="a dense table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Course name</TableCell>
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {events.map((data,i)=>(
                                            <TableRow key={i}>
                                                <TableCell>{data.text}</TableCell>
                                                <TableCell align="right">
                                                <img onClick={()=>onDeleteCourse(data)} src={Close} className='icon-action' />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                {/* {banner_pagination!==null&&
                                <div className='card-table__pagination'>
                                <TablePagination
                                    className="card-pagination"
                                    type="reduced"
                                    page={pagination.page}
                                    pageLength={pagination.pageSize}
                                    totalRecords={banner_pagination.total}
                                    onPageChange={({ page, pageLength }) => {
                                        togglePagination( page, pageLength )
                                    }}
                                    prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                                    nextPageRenderer={() => <img src={CevronRight}/>}
                                />
                                </div>
                                } */}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}
