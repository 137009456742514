import React,{useState,useEffect} from 'react'
import List from './list_alumni'
import Detail from './detail_alumni'
import { useDispatch,useSelector } from 'react-redux'
import { getCourse } from 'redux/actions/cdhx'
export default function Regis(props) {
    const dispatch = useDispatch()
    const cdhx = useSelector(state => state.cdhx)
    const [tab, settab] = useState('list')

    
    useEffect(() => {
       if(cdhx.course_list.length<1){
           dispatch(getCourse())
       }
    }, [])
    return (
        <div>
            {tab==='list'&&<List profile={props.profile} tab={tab} settab={settab}/>}
            {tab==='detail'&&<Detail profile={props.profile} tab={tab} settab={settab}/>}
            {tab==='edit'&&<Detail profile={props.profile} tab={tab} settab={settab}/>}
        </div>
    )
}
