import * as actionTypes from '../constants/careers'

const initialState={
    careers:[],
    title:'',
    placement:'',
    job_code:'',
    status:'',
    description:'',
}

export default (state=initialState,action)=>{
    switch (action.type) {
        case actionTypes.GET_CAREERS:
            return{
                ...state,
                careers:action.payload
            }
        case actionTypes.SET_TITLE:
            return{
                ...state,
                title:action.payload
            }
        case actionTypes.SET_PLACEMENT:
            return{
                ...state,
                placement:action.payload
            }
        case actionTypes.SET_JOBCODE:
            return{
                ...state,
                job_code:action.payload
            }
        case actionTypes.SET_STATUS:
            return{
                ...state,
                status:action.payload
            }
        case actionTypes.SET_DESCRIPTION:
            return{
                ...state,
                description:action.payload
            }
        case actionTypes.CLEAR_STATE:

            state=initialState
            return state
        default:
            return state
    }
}