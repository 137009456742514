import React, { Component } from "react";
import "./style.css";
import Chevron from "assets/icon/chevron_white.svg";
import page from "assets/icon/page.svg";
import calendar from "assets/icon/calendar.svg";
import Dashboard from "assets/icon/Dashboard.svg";
import empty_user from "assets/icon/empty_user.png";
import image from "assets/icon/image.svg";

import { Collapse } from "@material-ui/core";
import { connect } from "react-redux";
import { logout } from "redux/actions/auth";
import { withRouter } from "react-router-dom";
import Cookie from "universal-cookie";

const cookie = new Cookie();
class index extends Component {
  state = {
    page_collapse: false,
    admin_collapse: false,
    cdhx_collapse: false,
    profile: null,
  };
  componentDidMount() {
    let url = this.props.location.pathname;
    let page_collapse = url.includes("/page");
    let admin_collapse = url.includes("/admin");
    let cdhx_collapse = url.includes("/cdhx");
    const profile = cookie.get("profile_cookie");
    console.log("profile", profile);
    this.setState({ page_collapse, admin_collapse, profile, cdhx_collapse });
  }
  toggleCollapse = (key) => {
    this.setState({ [key]: !this.state[key] });
  };
  toggleActive = (key) => {
    let url = this.props.location.pathname;
    let match = url.includes(key);
    return match;
  };
  render() {
    const { page_collapse, admin_collapse, cdhx_collapse } = this.state;
    return (
      <div className="sidebar-wrapper">
        <div className="hr" />
        <div
          className="is-collapse"
          onClick={() => this.toggleCollapse("admin_collapse")}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="rounded-avatar">
              <img src={empty_user} className="ava-size" />
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a>Admin</a>
          </div>
          <div>
            <img
              src={Chevron}
              className={admin_collapse ? "active-chevron" : "chevron"}
            />
          </div>
        </div>
        <Collapse in={admin_collapse}>
          {/* <div className='content-collapsee'>
                        <div className='list-collapse'>
                            <a href='/profile'>Profile</a>
                        </div>
                    </div> */}
          <div className="content-collapsee" onClick={this.props.logout}>
            <div className="list-collapse">
              <a>Logout</a>
            </div>
          </div>
        </Collapse>
        <div className="hr" />
        <div
          onClick={() => window.location.assign("/dashboard")}
          className={`list-sidebar ${
            this.toggleActive("/dashboard") ? "is-active-menu" : ""
          }`}
        >
          <img src={Dashboard} className="icon-size" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a>Dasboard</a>
        </div>
        <div
          onClick={() => window.location.assign("/eventmanagement")}
          className={`list-sidebar ${
            this.toggleActive("/eventmanagement") ? "is-active-menu" : ""
          }`}
        >
          <img src={calendar} className="icon-size" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a>Event Management</a>
        </div>
        <div
          onClick={() => window.location.assign("/speakers")}
          className={`list-sidebar ${
            this.toggleActive("/speakers") ? "is-active-menu" : ""
          }`}
        >
          <img src={page} className="icon-size" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a>Speaker Management</a>
        </div>
        <div
          className="is-collapse"
          onClick={() => this.toggleCollapse("page_collapse")}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={page} className="icon-size" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a>Page</a>
          </div>
          <div>
            <img
              src={Chevron}
              className={page_collapse ? "active-chevron" : "chevron"}
            />
          </div>
        </div>
        <Collapse in={page_collapse}>
          <div className="content-collapsee">
            <div
              className={`list-collapse ${
                this.toggleActive("/page/home") ? "list-collapse-active" : ""
              }`}
            >
              <a href="/page/home">Home</a>
            </div>
          </div>
          <div className="content-collapsee">
            <div
              className={`list-collapse ${
                this.toggleActive("/page/careers") ? "list-collapse-active" : ""
              }`}
            >
              <a href="/page/careers">Careers</a>
            </div>
          </div>
          {/* <div className='content-collapsee'>
                        <div className={`list-collapse ${this.toggleActive('/page/tribe')?'list-collapse-active':''}`}>
                            <a href='/page/tribe'>Tribe</a>
                        </div>
                    </div>
                    <div className='content-collapsee'>
                        <div className={`list-collapse ${this.toggleActive('/page/contact')?'list-collapse-active':''}`}>
                            <a href='/page/contact'>Contact Us</a>
                        </div>
                    </div> */}
        </Collapse>
        <div
          className="is-collapse"
          onClick={() => this.toggleCollapse("cdhx_collapse")}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={page} className="icon-size" />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a>CDHX</a>
          </div>
          <div>
            <img
              src={Chevron}
              className={cdhx_collapse ? "active-chevron" : "chevron"}
            />
          </div>
        </div>
        <Collapse in={cdhx_collapse}>
          <div className="content-collapsee">
            <div
              className={`list-collapse ${
                this.toggleActive("/cdhx/home") ? "list-collapse-active" : ""
              }`}
            >
              <a href="/cdhx/home">Home</a>
            </div>
          </div>
          <div className="content-collapsee">
            <div
              className={`list-collapse ${
                this.toggleActive("/cdhx/registration")
                  ? "list-collapse-active"
                  : ""
              }`}
            >
              <a href="/cdhx/registration">Registration</a>
            </div>
          </div>
        </Collapse>
        <div
          onClick={() => window.location.assign("/images")}
          className={`list-sidebar ${
            this.toggleActive("/images") ? "is-active-menu" : ""
          }`}
        >
          <img src={image} className="icon-size" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a>Images</a>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  logout,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(index));
