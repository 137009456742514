import React, { Component } from 'react'
import './style.css'

import {FormControl,CircularProgress,RadioGroup,FormControlLabel,Radio,TextField,Button,
InputLabel,OutlinedInput } from '@material-ui/core'
import axios from 'axios'
// import {URL,USER,PASS} from '../../service/base_url'
import {get} from 'lodash'
import Slider from 'infinite-react-carousel';
import Dummy from 'assets/image/noimage.png'
// import Modal from '../../components/modal'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',

        },
        secondary: {
            main:'#FF9800',
            contrastText: '#FFFFFF',
        }
    } 
})
const contact='<p>Or you can contact our team for more information</p><br/> <p>Contact &nbsp; &nbsp; &nbsp; &nbsp; :Yenny / Shanti / Tya</p> <p>Phone No. &nbsp; &nbsp; : (021) 4525718 (Hunting)</p> <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;- (021) 4525719</p> <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;- 0821-2325-3700</p> <p>Email &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; : gml@gmlperformance.co.id</p> <p> <br> </p> <p> <br> </p>'
const contact_webinar='<p>Or you can contact our team for more information</p><br/> <p>Contact &nbsp; &nbsp; &nbsp; &nbsp; :Yenny / Shanti / Tya</p><br/> <p>Phone No. &nbsp; &nbsp; : (021) 4525718 (Hunting)</p> <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;- (021) 4525719</p> <p>&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;- 0821-2325-3700</p><br/> <p>Email&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; :gml@knowcap.co.id<p></p> <p>&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;gml@gmlperformance.co.id</p><p>&nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;knowcap@knowcap.co.id</p>'
const payment='<p>Payment &nbsp; &nbsp; &nbsp; &nbsp;: PT. Kreasi Cipta Asia</p> <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; A/C No. 840-010-4647 | BCA</p> <p>&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; BCA Cabang Rukan Artha Gading</p>'

export default class registration extends Component {
    state={
        isOpen: false,
        title:'',
        name:'',
        phoneNumber:'',
        email:'',
        companyName:'',
        loading:false,
        success_form:false,
    }
    onChange=(e)=>{
        e.preventDefault()
        this.setState({[e.target.name]:e.target.value})
    }
    toggleSucces=()=>{
        this.setState({success_form:!this.state.success_form})
    }
    submit=(e)=>{
        // e.preventDefault()
        // this.setState({loading:true})
        // let {name,phoneNumber,email,companyName,title}=this.state
        // axios.post(`${URL}`,{
        //     name,phoneNumber,email,companyName,action:`eventregister-${this.props.event.title}`
        // },{
        //     auth: {
        //         username: USER,
        //         password: PASS
        //       }
        // }).then((res)=>{
        //     if(get(res,'status')==200){
        //         this.setState({loading:false})

        //         this.setState({success_form:!this.state.success_form,request_form:!this.state.request_form})
        //     }else{
        //         this.setState({loading:false})
        //         alert('Ops something error')
        //     }
        // })
    }
    children2=()=>{
        return(
            <div>
                <p>Thank you for your interest in One GML. we will contact you as soon as possible</p>
                <br/>
                <Button onClick={this.toggleSucces} style={{textTransform:'none',width:100}} size="small" color="primary" variant="contained">Ok</Button>
            </div>
        )
    }
    render() {
        let {success_form,loading}=this.state
        let {event}=this.props
        
        return (
            <div>
                {/* <Modal title="Register Event" isOpen={success_form} children={this.children2()} modalToggle={this.toggleSucces}/> */}
                <div className='registration-form'>
                    <p>Please fill out the form below to registration.</p>
                    
                    <br/>
                    <form onSubmit={this.submit}>
                        <MuiThemeProvider theme={themeButton}>
                <FormControl variant="outlined" size="small"  className='form-register'>
                    <InputLabel  htmlFor="name">Name<span style={{color:'red'}}>*</span></InputLabel>
                    <OutlinedInput
                        
                        id="name"
                        type='text'
                        name='name'
                        onChange={this.onChange}
                        required
                        labelWidth={70}
                    />
                </FormControl>
                <br/><br/>
               
                 <FormControl variant="outlined" size="small"  className='form-register'>
                    <InputLabel  htmlFor="email">Email<span style={{color:'red'}}>*</span></InputLabel>
                    <OutlinedInput
                        
                        id="email"
                        type='email'
                        name='email'
                        onChange={this.onChange}
                        required
                        labelWidth={70}
                    />
                </FormControl>
                <br/><br/>
               
                <FormControl variant="outlined" size="small"  className='form-register'>
                    <InputLabel  htmlFor="phoneNumber">Handphone No.<span style={{color:'red'}}>*</span></InputLabel>
                    <OutlinedInput
                        
                        id="phoneNumber"
                        type='number'
                        name='phoneNumber'
                        onChange={this.onChange}
                        required
                        labelWidth={120}
                    />
                </FormControl>
                <br/><br/>
                
                <FormControl variant="outlined" size="small"  className='form-register'>
                    <InputLabel  htmlFor="companyName">Company name<span style={{color:'red'}}>*</span></InputLabel>
                    <OutlinedInput
                        
                        id="companyName"
                        type='companyName'
                        name='companyName'
                        onChange={this.onChange}
                        required
                        labelWidth={120}
                    />
                </FormControl>
                <br/><br/>
                <Button className='button-regis' type='submit'  size="small" color="primary" variant="contained">
                    {loading? <CircularProgress size={20} style={{color:'white'}}/>:'Send'}
                </Button>
                </MuiThemeProvider>
                </form>
                    <>
                    <br/><br/>
                    <div  dangerouslySetInnerHTML={{__html:event.type==='3'?contact_webinar:contact}}></div>
                {/* {event.type!=='Webinar'&& */}
                    <br/>
                    <div className='payment-box' dangerouslySetInnerHTML={{__html:payment}}></div>
                {/* } */}
                    
                    </>
                </div>
                <br/>
               
            </div>
        )
    }
}
