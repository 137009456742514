import React, { Component } from "react";
import "../style.css";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { deleteCareer } from "redux/actions/careers";
import {
  setAgenda,
  setFramework,
  setTestimony,
  setInvestment,
  setSpeaker,
  deleteImage,
  deleteEvent,
  setBrochure,
  setFlyer,
} from "redux/actions/event";
import { deleteBanner } from "redux/actions/home";
import { deleteBanner as deleteBanner2 } from "redux/actions/cdhx";
import { modalToggleReset } from "redux/actions/general";
const themeButton = createMuiTheme({
  palette: {
    primary: {
      main: "#00ACC1",
    },
    secondary: {
      main: "#E91E63",
      contrastText: "#FFFFFF",
    },
  },
});
class confirm_delete extends Component {
  deleteCareer = async () => {
    let { modal_data, modal_action, profile, token } = this.props;

    await this.props.modalToggleReset();
    this.props.deleteCareer(token, modal_data.id, profile.id);
  };
  deleteAgenda = () => {
    let { modal_data, modal_action, profile, token, event } = this.props;
    // this.props.setAgenda(modal_data.agenda)
    event.agenda.splice(modal_data.index, 1);

    this.props.modalToggleReset();
  };
  deleteFramework = () => {
    let { modal_data, modal_action, profile, token } = this.props;
    this.props.setFramework(modal_data.framework);
    this.props.modalToggleReset();
  };
  deleteTestimony = () => {
    let { modal_data, modal_action, profile, token, event } = this.props;
    // this.props.setTestimony(modal_data.testimony)
    event.testimony.splice(modal_data.index, 1);
    this.props.modalToggleReset();
  };
  deleteInvestment = () => {
    let { modal_data, modal_action, profile, token, event } = this.props;
    // this.props.setInvestment(modal_data.investment)
    event.investment.splice(modal_data.index, 1);

    this.props.modalToggleReset();
  };
  deleteSpeaker = () => {
    let { modal_data, modal_action, profile, token, event } = this.props;
    event.speaker.splice(modal_data.index, 1);
    this.props.modalToggleReset();
  };
  deleteImage = async () => {
    let { modal_data, modal_action, profile, token, event } = this.props;
    await this.props.modalToggleReset();

    this.props.deleteImage(token, modal_data.id, profile.id, event.active_tab);
  };
  deleteEvent = async () => {
    let { modal_data, modal_action, profile, token, event, success_msg } =
      this.props;
    const { active_tab } = this.props.event;
    console.log("props", this.props.event);
    await this.props.modalToggleReset();
    this.props.deleteEvent(
      token,
      modal_data.id,
      profile.id,
      `/0/0/${event.drafted_pagination.page}/${event.drafted_pagination.perPage}/*`,
      active_tab
    );
  };
  deleteBrocure = async () => {
    await this.props.modalToggleReset();
    this.props.setBrochure(null);
  };
  deleteFlyer = async () => {
    await this.props.modalToggleReset();
    this.props.setFlyer(null);
  };
  deletesBanner = async (modal_data) => {
    await this.props.modalToggleReset();
    this.props.deleteBanner(
      this.props.token,
      `/${modal_data.bannerId}/${this.props.profile.id}`
    );
  };
  deletesBanner2 = async (modal_data) => {
    await this.props.modalToggleReset();
    this.props.deleteBanner2(
      this.props.token,
      `/${modal_data.bannerId}/${this.props.profile.id}`
    );
  };
  renderAction = () => {
    let { modal_data, modal_action, profile, token } = this.props;
    switch (modal_action) {
      case "delete_career":
        return this.deleteCareer();
      case "delete_agenda":
        return this.deleteAgenda();
      case "delete_framework":
        return this.deleteFramework();
      case "delete_testimony":
        return this.deleteTestimony();
      case "delete_investment":
        return this.deleteInvestment();
      case "delete_speaker":
        return this.deleteSpeaker();
      case "delete_image":
        return this.deleteImage();
      case "delete_event":
        return this.deleteEvent();
      case "delete_brochure":
        return this.deleteBrocure();
      case "delete_flyer":
        return this.deleteFlyer();
      case "delete_banner":
        return this.deletesBanner(modal_data);
      case "delete_banner2":
        return this.deletesBanner2(modal_data);
      case "confirm_action":
        return modal_data.confirm_action();

      default:
        return modal_data.action();
        break;
    }
    // case
  };
  render() {
    // console.log('this.props.modal_data', this.props.modal_data)
    let { modal_data, modal_title } = this.props;
    return (
      <div className="confirm-container">
        <h4>Delete {modal_data.title}</h4>
        <div dangerouslySetInnerHTML={{ __html: modal_data.msg }}></div>
        {/* <p>Are you sure delete <b>{modal_data.title}</b></p> */}
        <div className="card-footer">
          <MuiThemeProvider theme={themeButton}>
            <Button
              onClick={() => this.props.modalToggleReset()}
              size="small"
              color="primary"
              className="btn-remove-capital"
            >
              No, back to {modal_title}
            </Button>
            <Button
              onClick={() => this.renderAction()}
              size="small"
              color="secondary"
              variant="contained"
              className="head-add-section__btn "
            >
              Yes
            </Button>
          </MuiThemeProvider>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  general: state.general,
  event: state.event,
});
const mapDispatchToProps = {
  deleteCareer,
  modalToggleReset,
  setAgenda,
  setFramework,
  setTestimony,
  setInvestment,
  setSpeaker,
  deleteImage,
  deleteEvent,
  setBrochure,
  deleteBanner,
  setFlyer,
  deleteBanner2,
};
export default connect(mapStateToProps, mapDispatchToProps)(confirm_delete);
