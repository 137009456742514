import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "@material-ui/core";
import "./style.css";
import Close from "assets/icon/close.svg";
import { modalToggleReset, modalToggle } from "redux/actions/general";
import Frameworks from "components/Modal/src/framework";
import Testimony from "components/Modal/src/testimony";
import Career from "components/Modal/src/career";
import ConfirmDelete from "components/Modal/src/confirm_delete";
import ConfirmUnpublish from "components/Modal/src/confirm_unpublish";
import ConfirmPubUn from "components/Modal/src/confirm_pub_un";
import Alert from "components/Modal/src/alert";
import Backdrop from "./backdrop";
import Agenda from "./src/agenda";
import Investment from "./src/investment";
import Speaker from "./src/speaker";
import Image from "./src/image";
import Banner from "./src/banner";
import Banner2 from "./src/banner2";
import Alumni from "./src/alumni";
import Forum_expert from "./src/forum_expert";
import Forum_expert2 from "./src/forum_expert2";
import Upload_alumni from "./src/upload_alumni";
import Email_template from "./src/email_template";
import Add_course from "./src/add_course";
import Cookie from "universal-cookie";
import Cdhx_category from "./src/cdhx_category";
import Speaker_add from "./src/speaker_add";
const cookie = new Cookie();

class index extends Component {
  renderBody = () => {
    const {
      modal_data,
      modal_title,
      modal_open,
      modal_size,
      modal_component,
      modal_type,
      modal_action,
      success_msg,
    } = this.props.general;
    const token = cookie.get("login_cookie");
    const profile = cookie.get("profile_cookie");
    switch (modal_type) {
      case "confirm":
        return (
          <>
            {modal_component === "confirm_delete" && (
              <ConfirmDelete
                modal_title={modal_title}
                token={token}
                profile={profile}
                modal_data={modal_data}
                modal_action={modal_action}
              />
            )}
            {modal_component === "confirm_unpublish" && (
              <ConfirmUnpublish
                modal_title={modal_title}
                token={token}
                profile={profile}
                modal_data={modal_data}
                modal_action={modal_action}
                success_msg={success_msg}
              />
            )}
            {modal_component === "confirm_pub_un" && (
              <ConfirmPubUn
                modal_title={modal_title}
                token={token}
                profile={profile}
                modal_data={modal_data}
                modal_action={modal_action}
                success_msg={success_msg}
              />
            )}
          </>
        );
        break;
      case "alert":
        return <Alert />;
      case "multi":
        return (
          <>
            {modal_component === "upload_alumni" ||
            modal_component === "add_facilitator" ? (
              <Modal className="modal" open={modal_open}>
                <div className="modal-content" style={{ width: 400 }}>
                  <div className="modal-header">
                    <h2>{modal_title}</h2>
                    <img
                      src={Close}
                      style={{ width: 20 }}
                      onClick={this.props.modalToggleReset}
                    />
                  </div>
                  <div className="modal-body">
                    <Upload_alumni
                      modal_action={modal_action}
                      modal_data={modal_data}
                      token={token}
                      profile={profile}
                    />
                  </div>
                </div>
              </Modal>
            ) : null}
            {modal_component === "add_facilitator" ||
            modal_component === "add_facilitator" ? (
              <Modal className="modal" open={modal_open}>
                <div className="modal-content" style={{ width: modal_size }}>
                  <div className="modal-header">
                    <h2>{modal_title}</h2>
                    <img
                      src={Close}
                      style={{ width: 20 }}
                      onClick={() => {
                        this.props.modalToggle({
                          modal_open: true,
                          modal_title: "Upload Alumni",
                          modal_component: "upload_alumni",
                          modal_size: 400,
                          modal_data: null,
                          modal_action: "upload_alumni",
                          modal_type: "multi",
                        });
                      }}
                    />
                  </div>
                  <div className="modal-body">
                    <Forum_expert2
                      modal_action={modal_action}
                      modal_data={modal_data}
                      token={token}
                      profile={profile}
                    />
                  </div>
                </div>
              </Modal>
            ) : null}
          </>
        );
      //    if(modal_component==='upload_alumni'||modal_component==='add_facilitator'){
      //        return(
      //         <Modal
      //             className='modal'
      //             open={modal_open}
      //         >
      //             <div className='modal-content' style={{width:modal_size}}>
      //                 <div className='modal-header'>
      //                     <h2>{modal_title}</h2>
      //                     <img src={Close} style={{width:20}} onClick={this.props.modalToggleReset}/>
      //                 </div>
      //                 <div className='modal-body' >
      //                 <Upload_alumni modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>
      //                 </div>
      //             </div>
      //         </Modal>
      //        )
      //    }
      //    if(modal_component==='add_facilitator'||modal_component==='add_facilitator'){
      //         return(
      //             <Modal
      //                 className='modal'
      //                 open={modal_open}
      //             >
      //                 <div className='modal-content' style={{width:modal_size}}>
      //                     <div className='modal-header'>
      //                         <h2>{modal_title}</h2>
      //                         <img src={Close} style={{width:20}} onClick={()=>{
      //                             this.props.modalToggle({
      //                                 modal_open: true,
      //                                 modal_title: "Upload Alumni",
      //                                 modal_component: "upload_alumni",
      //                                 modal_size:400,
      //                                 modal_data:null,
      //                                 modal_action:'upload_alumni',
      //                                 modal_type:'multi'

      //                             })
      //                         }}/>
      //                     </div>
      //                     <div className='modal-body' >
      //                         <Forum_expert2 modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>
      //                     </div>
      //                 </div>
      //             </Modal>
      //        )
      //    }
      default:
        return (
          <>
            <div className="modal-header">
              <h2>{modal_title}</h2>
              <img
                src={Close}
                style={{ width: 20 }}
                onClick={this.props.modalToggleReset}
              />
            </div>
            {/* <hr size='1'/> */}
            <div className="modal-body">
              {modal_component === "framework" && <Frameworks />}
              {modal_component === "image" && (
                <Image
                  token={token}
                  profile={profile}
                  modal_data={modal_data}
                  modal_action={modal_action}
                />
              )}
              {modal_component === "testimony" && <Testimony />}
              {modal_component === "agenda" && (
                <Agenda modal_data={modal_data} modal_action={modal_action} />
              )}
              {modal_component === "investment" && (
                <Investment
                  modal_data={modal_data}
                  modal_action={modal_action}
                />
              )}
              {modal_component === "speaker" && (
                <Speaker modal_data={modal_data} modal_action={modal_action} />
              )}
              {modal_component === "career" && (
                <Career
                  token={token}
                  profile={profile}
                  modal_data={modal_data}
                  modal_action={modal_action}
                />
              )}
              {modal_component === "banner" && (
                <Banner
                  token={token}
                  profile={profile}
                  modal_data={modal_data}
                  modal_action={modal_action}
                />
              )}
              {modal_component === "banner2" && (
                <Banner2
                  token={token}
                  profile={profile}
                  modal_data={modal_data}
                  modal_action={modal_action}
                />
              )}
              {modal_component === "alumni" && (
                <Alumni
                  token={token}
                  profile={profile}
                  modal_data={modal_data}
                  modal_action={modal_action}
                />
              )}
              {modal_component === "forum_expert" && (
                <Forum_expert
                  token={token}
                  profile={profile}
                  modal_data={modal_data}
                  modal_action={modal_action}
                />
              )}
              {modal_component === "upload_alumni" && (
                <Upload_alumni
                  token={token}
                  profile={profile}
                  modal_data={modal_data}
                  modal_action={modal_action}
                />
              )}
              {modal_component === "email_template" && (
                <Email_template
                  token={token}
                  profile={profile}
                  modal_data={modal_data}
                  modal_action={modal_action}
                />
              )}
              {modal_component === "add_course" && (
                <Add_course
                  token={token}
                  profile={profile}
                  modal_data={modal_data}
                  modal_action={modal_action}
                />
              )}
              {modal_component === "cdhx_category" && (
                <Cdhx_category
                  token={token}
                  profile={profile}
                  modal_data={modal_data}
                  modal_action={modal_action}
                />
              )}
              {modal_component === "speaker_add" && (
                <Speaker_add
                  token={token}
                  profile={profile}
                  modal_data={modal_data}
                  modal_action={modal_action}
                />
              )}
            </div>
          </>
        );
        break;
    }
  };
  render() {
    const {
      modal_open,
      modal_data,
      modal_title,
      modal_component,
      modal_size,
      modal_type,
      modal_action,
    } = this.props.general;
    const { token, profile } = this.props;
    return (
      <div>
        <Modal
          className="modal"
          open={modal_open}
          onClose={this.props.modalToggleReset}
        >
          <div className="modal-content" style={{ width: modal_size }}>
            {this.renderBody()}
          </div>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    general: state.general,
  };
};
const mapDispatchToProp = {
  modalToggleReset,
  modalToggle,
};
export default connect(mapStateToProps, mapDispatchToProp)(index);
