import * as actionTypes from "../constants/event";
import moment from "moment";
const initialState = {
  title: "",
  start_date: moment().format("YYYY-MM-DD"),
  end_date: moment().format("YYYY-MM-DD"),
  start_time: null,
  end_time: null,
  address: "",
  category: "",
  status: "",
  target: "",
  thumbnail: null,
  brochure: null,
  flyer: null,
  description: "",
  additional: "",
  framework: [],
  testimony: [],
  agenda: [],
  investment: [],
  speaker: [],
  short_desc: "",
  list_event_drafted: null,
  list_event_published: null,
  list_event_all: null,
  action: "add_event",
  id_event: "",
  drafted_pagination: null,
  published_pagination: null,
  image: null,
  caption: "",
  event_id: "",
  list_image_event_drafted: null,
  list_image_event_published: null,
  list_image_event_all: null,
  active_tab: "drafted",
  search: "",

  meta_title: "",
  slug: "",
  meta_desc: "",
  keyword: [],
  registration_url: "",
  link_zoom: "",
  type: "",
  location: "",
  take_aways: [],
  category_list: [],
  location_list: [],
  type_list: [],
  emailNotification: true,
  emailSubject: "",
  email: "",
  cdhxCategory: "",

  cdhx_categorys: [],

  tribeId: "",

  tribes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case actionTypes.SET_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case actionTypes.SET_START_DATE:
      return {
        ...state,
        start_date: action.payload,
      };
    case actionTypes.SET_END_DATE:
      return {
        ...state,
        end_date: action.payload,
      };
    case actionTypes.SET_START_TIME:
      return {
        ...state,
        start_time: action.payload,
      };
    case actionTypes.SET_END_TIME:
      return {
        ...state,
        end_time: action.payload,
      };
    case actionTypes.SET_ADDRESS:
      return {
        ...state,
        address: action.payload,
      };
    case actionTypes.SET_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case actionTypes.SET_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case actionTypes.SET_TARGET:
      return {
        ...state,
        target: action.payload,
      };
    case actionTypes.SET_THUMBNAIL:
      return {
        ...state,
        thumbnail: action.payload,
      };
    case actionTypes.SET_BROCHURE:
      return {
        ...state,
        brochure: action.payload,
      };
    case actionTypes.SET_FLYER:
      return {
        ...state,
        flyer: action.payload,
      };
    case actionTypes.SET_TAKE_AWAYS:
      return {
        ...state,
        take_aways: action.payload,
      };
    case actionTypes.SET_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
      };
    case actionTypes.SET_ADDITIONAL:
      return {
        ...state,
        additional: action.payload,
      };
    case actionTypes.SET_FRAMEWORK:
      return {
        ...state,
        framework: action.payload,
      };
    case actionTypes.SET_TESTIMONY:
      return {
        ...state,
        testimony: action.payload,
      };
    case actionTypes.SET_AGENDA:
      return {
        ...state,
        agenda: action.payload,
      };
    case actionTypes.SET_INVESTMENT:
      return {
        ...state,
        investment: action.payload,
      };
    case actionTypes.SET_SPEAKER:
      return {
        ...state,
        speaker: action.payload,
      };
    case actionTypes.SET_SHORT_DESC:
      return {
        ...state,
        short_desc: action.payload,
      };
    case actionTypes.GET_EVENT_DRAFTED:
      return {
        ...state,
        list_event_drafted: action.payload,
        drafted_pagination: action.pagination,
      };
    case actionTypes.GET_EVENT_PUBLISHED:
      return {
        ...state,
        list_event_published: action.payload,
        published_pagination: action.pagination,
      };
    case actionTypes.SET_ACTION:
      return {
        ...state,
        action: action.payload,
      };
    case actionTypes.SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };
    case actionTypes.SET_ID:
      return {
        ...state,
        id_event: action.payload,
      };
    case actionTypes.SET_IMAGE:
      return {
        ...state,
        image: action.payload,
      };
    case actionTypes.SET_IMAGE:
      return {
        ...state,
        image: action.payload,
      };
    case actionTypes.SET_CAPTION:
      return {
        ...state,
        caption: action.payload,
      };
    case actionTypes.SET_EVENT_ID:
      return {
        ...state,
        event_id: action.payload,
      };
    case actionTypes.GET_EVENT_IMAGE_DRAFTED:
      return {
        ...state,
        list_image_event_drafted: action.payload,
        drafted_pagination: action.pagination,
      };
    case actionTypes.GET_EVENT_IMAGE_PUBLISHED:
      return {
        ...state,
        list_image_event_published: action.payload,
        published_pagination: action.pagination,
      };
    case actionTypes.GET_EVENT_IMAGE_ALL:
      return {
        ...state,
        list_image_event_all: action.payload,
        // published_pagination:action.pagination
      };
    case actionTypes.TOGGLE_TAB:
      return {
        ...state,
        active_tab: action.payload,
      };
    case actionTypes.GET_EVENT_ALL:
      return {
        ...state,
        list_event_all: action.payload,
      };
    case actionTypes.SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };

    case actionTypes.SET_META_TITLE:
      return {
        ...state,
        meta_title: action.payload,
      };
    case actionTypes.SET_SLUG:
      return {
        ...state,
        slug: action.payload,
      };
    case actionTypes.SET_META_DESC:
      return {
        ...state,
        meta_desc: action.payload,
      };
    case actionTypes.SET_KEYWORD:
      return {
        ...state,
        keyword: action.payload,
      };
    case actionTypes.SET_REGISTRATION_URL:
      return {
        ...state,
        registration_url: action.payload,
      };
    case actionTypes.SET_LINK_ZOOM:
      return {
        ...state,
        link_zoom: action.payload,
      };
    case actionTypes.SET_VIDEO_URL:
      return {
        ...state,
        videoURL: action.payload,
      };
    case actionTypes.SET_EVENT_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case actionTypes.SET_EVENT_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case actionTypes.GET_CATEGORY:
      return {
        ...state,
        category_list: action.payload,
      };
    case actionTypes.GET_LOCATION:
      return {
        ...state,
        location_list: action.payload,
      };
    case actionTypes.GET_TYPE:
      return {
        ...state,
        type_list: action.payload,
      };
    case actionTypes.SET_EMAIL_NOTIF:
      return {
        ...state,
        emailNotification: action.payload,
      };
    case actionTypes.SET_EMAIL_SUBJECT:
      return {
        ...state,
        emailSubject: action.payload,
      };
    case actionTypes.SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case actionTypes.GET_CDHX_CATEGORY:
      return {
        ...state,
        cdhx_categorys: action.payload,
      };
    case actionTypes.SET_CDHX_CATEGORY:
      return {
        ...state,
        cdhxCategory: action.payload,
      };
    case actionTypes.SET_TRIBE:
      return {
        ...state,
        tribeId: action.payload,
      };
    case actionTypes.CLEAR_STATE:
      return {
        ...state,
        title: "",
        start_date: moment().format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        start_time: null,
        end_time: null,
        address: "",
        link_zoom:"",
        category: "",
        status: "",
        target: "",
        thumbnail: null,
        brochure: null,
        description: "",
        additional: "",
        framework: [],
        testimony: [],
        agenda: [],
        investment: [],
        speaker: [],
        short_desc: "",
        event_id: "",
        id_event: "",
        meta_title: "",
        slug: "",
        meta_desc: "",
        keyword: [],
        registration_url: "",
        location: "",
        type: "",
        flyer: null,
        take_aways: [],
        emailNotification: true,
        emailSubject: "",
        email: "",
        cdhxCategory: "",
        tribeId: "",
      };
    case actionTypes.GET_TRIBES:
      return {
        ...state,
        tribes: action.payload,
      };
    default:
      return state;
  }
};
