import React, { Component } from 'react'
import {Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
import Eye from 'assets/icon/eye.svg'
import Edit from 'assets/icon/edit.svg'
import Close from 'assets/icon/close.svg'
import {connect} from 'react-redux'
import {
    
    addImage,
    getImageEvent,
    getEventWithoutPaging
} from 'redux/actions/event'
import {modalToggle} from 'redux/actions/general'
import Skeleton from 'components/Skeleton'
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
class list_drafted extends Component {
    state={
        drafted:[],
        page:1,
        pageLength:10
    }
    componentDidMount(){
        let {event}=this.props
        let {page,pageLength}=this.state
        // this.props.getImageEvent(`/0/0/${page}/${pageLength}/*`)
        if(event.list_image_event_drafted!==null){
            this.props.getImageEvent(`/0/0/${event.drafted_pagination.page}/${event.drafted_pagination.perPage}/${event.search.length>0?event.search:'*'}`,'drafted')
        }else{
            this.props.getImageEvent(`/0/0/${page}/${pageLength}/*`,'drafted')
        }

    }
    //  componentWillReceiveProps(newProps){
    //     let list_image_event=newProps.event.list_image_event
    //     this.spliceImages(list_image_event)
    // }
    // spliceImages=(list_image_event)=>{
    //     let drafted=[]
    //     if(list_image_event!==null){
    //         list_image_event.map((data,i)=>{
    //             if(data.publish===0){
    //                 drafted.push(data)
    //             }
    //         })
    //         this.setState({drafted})
    //     }
    // }
    togglePagination=(page,pageLength)=>{
        let {event}=this.props

        this.setState({page,pageLength},()=>{
            this.props.getImageEvent(`/0/0/${page}/${pageLength}/${event.search.length>0?event.search:'*'}`,'drafted')

        })

    }
    render() {
        const {isLoadingTable,event}=this.props
        const {drafted}=this.state
        console.log('event.lis',isLoadingTable)
        return (
            <div>
                <Table  size="small" aria-label="a dense table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="left">Image name</TableCell>
                        <TableCell align="left">Event name</TableCell>
                        <TableCell align="left">Caption</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoadingTable!==true&&event.list_image_event_drafted!==null?event.list_image_event_drafted.map((data,i)=>(
                            <TableRow key={i}>
                                <TableCell style={{maxWidth:255,minHeight:100,}}>{data.imageName}</TableCell>
                                <TableCell style={{maxWidth:255,minHeight:100,}}>{data.event}</TableCell>
                                <TableCell>{data.caption.substring(0,30)}</TableCell>
                                <TableCell align="right">
                                    <img src={Eye} onClick={()=>this.props.onPreviewClick(data)} className='icon-action'/>
                                    <img src={Edit} onClick={()=>this.props.editImage(data)} className='icon-action'/>
                                    <img src={Close} className='icon-action' onClick={()=>this.props.deleteImage(data)}/>
                                </TableCell>
                            </TableRow>
                        )):<Skeleton count={4}/>}
                        
                    </TableBody>
                </Table>
                <div className='card-table__pagination'>
                {event.drafted_pagination!==null&&
                <TablePagination
                        className="card-pagination"
                        type="reduced"
                        page={event.drafted_pagination.page}
                        pageLength={event.drafted_pagination.perPage}
                        totalRecords={event.drafted_pagination.total}
                        onPageChange={({ page, pageLength }) => {
                            this.togglePagination( page, pageLength )
                        }}
                        prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                        nextPageRenderer={() => <img src={CevronRight}/>}
                    />
                }
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    general:state.general,
    event:state.event
    // careers:state.careers
})
const mapDispatchToProps={
    modalToggle,
    
    addImage,
    getImageEvent,
    getEventWithoutPaging
}
export default connect(mapStateToProps,mapDispatchToProps)(list_drafted)