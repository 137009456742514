import * as actionTypes from '../constants/home'

const initialState={
    welcome_word:'',
    additional_word:'',
    banner_image:[],
    banner_pagination:null
}

export default (state=initialState,action)=>{
    switch (action.type) {
        case actionTypes.SET_WELCOME_WORD:
            return{
                ...state,
                welcome_word:action.payload
            }
        case actionTypes.SET_ADDITIONAL_WORD:
            return{
                ...state,
                additional_word:action.payload
            }
        case actionTypes.SET_BANNER_IMAGE:
            return{
                ...state,
                banner_image:action.payload,
            }
        case actionTypes.SET_BANNER_PAGINATION:
            return{
                ...state,
                banner_pagination:action.payload,
            }
       
        default:
            return state
    }
}