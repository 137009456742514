import React, { Component } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "ckeditor5-build-classic-with-upload-image";
// import ClassicEditor from 'ckeditor5-build-classic-with-upload-image';
import {
  Button,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { modalToggle } from "redux/actions/general";
import { connect } from "react-redux";
import {
  setDescription,
  setAdditional,
  setFramework,
  setTestimony,
} from "redux/actions/event";
import Cookie from "universal-cookie";
import { URL } from "service/base_url";
import Editor from "ckeditor5-custom-build/build/ckeditor";

const cookie = new Cookie();
const token = cookie.get("login_cookie");

// ClassicEditor.defaultConfig = {
//   toolbar: {
//     items: [
//       "heading",
//       "|",
//       "bold",
//       "italic",
//       "alignment",
//       "link",
//       "bulletedList",
//       "numberedList",
//       "|",
//       "indent",
//       "outdent",
//       "|",
//       "imageUpload",
//       "mediaEmbed",
//       "blockQuote",
//       "insertTable",
//       "undo",
//       "redo",
//     ],
//   },
//   alignment: {
//     options: ["left", "right", "center"],
//   },
//   image: {
//     toolbar: [
//       "imageStyle:full",
//       "imageStyle:side",
//       "imageStyle:alignLeft",
//       "imageStyle:alignCenter",
//       "imageStyle:alignRight",
//       "|",
//       "imageTextAlternative",
//     ],
//     styles: ["full", "side", "alignLeft", "alignCenter", "alignRight"],
//   },
//   table: {
//     contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
//   },
//   // This value must be kept in sync with the language defined in webpack.config.js.
//   language: "en",
//   simpleUpload: {
//     // The URL that the images are uploaded to.
//     uploadUrl: `${URL}/upload/ckeditor`,

//     // Headers sent along with the XMLHttpRequest to the upload server.
//     headers: {
//       "X-CSRF-TOKEN": "CSFR-Token",
//       Authorization: `Bearer ${token}`,
//     },
//   },
//   mediaEmbed: { previewsInData: true },
// };
const editorConfiguration = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "alignment",
    "link",
    "bulletedList",
    "numberedList",
    "|",
    "indent",
    "outdent",
    "|",
    "imageUpload",
    "mediaEmbed",
    "blockQuote",
    "insertTable",
    "undo",
    "redo",
  ],
  image: {
    toolbar: [
      "imageStyle:full",
      "imageStyle:side",
      "imageStyle:alignLeft",
      "imageStyle:alignCenter",
      "imageStyle:alignRight",
      "|",
      "imageTextAlternative",
    ],
    styles: ["full", "side", "alignLeft", "alignCenter", "alignRight"],
  },
  simpleUpload: {
    // The URL that the images are uploaded to.
    uploadUrl: `${URL}/upload/ckeditor`,

    // Headers sent along with the XMLHttpRequest to the upload server.
    headers: {
      "X-CSRF-TOKEN": "CSFR-Token",
      Authorization: `Bearer ${token}`,
    },
  },
  mediaEmbed: { previewsInData: true },
};
class information extends Component {
  addFramework = () => {
    this.props.modalToggle({
      modal_open: true,
      modal_title: "Framework",
      modal_component: "framework",
      modal_data: null,
      modal_size: 420,
      modal_action: "add_framework",
    });
  };

  render() {
    const { event } = this.props;
    return (
      <div className="workshop-information">
        <p className="bold">Event description</p>
        <br />
        <CKEditor
          onInit={(editor) => {
            editor.isReadOnly = this.state.disabled;
          }}
          disabled={this.props.handleDisable()}
          data={event.description}
          editor={Editor}
          config={editorConfiguration}
          onChange={(event, editor) => {
            const data = editor.getData();
            this.props.setDescription(data);
          }}
        />

        <br />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  event: state.event,
  general: state.general,
});
const mapDispatchToProps = {
  modalToggle,
  setDescription,
  setAdditional,
  setFramework,
  setTestimony,
};
export default connect(mapStateToProps, mapDispatchToProps)(information);
