import React, { Component } from 'react'
import Navbar from 'components/Navbar'
import Sidebar from 'components/Sidebar'
import Backdrop from 'components/Sidebar/backdrop'
import './style.css'
import Modal from 'components/Modal'
import Cookie from 'universal-cookie'
import Loading from 'components/Loading'
const cookie=new Cookie()
export default class index extends Component {
    state={
        show_sidebar:false,
        width: window.innerWidth,
    }
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }
      
     
      componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
      }
      
      handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
      };
    sidebarToggle=()=>{
        this.setState({show_sidebar:!this.state.show_sidebar})       
    }
    render() {
        // console.log('this.props.token', this.props.token)
        const token=cookie.get('login_cookie')
        const profile=cookie.get('profile_cookie')
        if(token===undefined){
            window.location.assign('/')
        }
        let {show_sidebar,width}=this.state
        const isMobile = width <= 768;
        let sidebar,backdrop
        if(isMobile){
            if(show_sidebar){
                sidebar=<Sidebar/>
                backdrop=<Backdrop sidebarToggle={this.sidebarToggle}/>
            }
        }else{
            sidebar=<Sidebar/>
        }
        // if(show_sidebar&&isMobile){

       
        // }else{
        //     // sidebar=<Sidebar/>
        // }
        return (
            <div>
                {/* <Navbar sidebarToggle={this.sidebarToggle}/> */}
                {sidebar}
                {backdrop}
                <Loading/>

                <div className='content-wrapper'>

                    <Modal token={token} profile={profile}/>
                    {this.props.children}
                </div>
            </div>
        )
    }
}
