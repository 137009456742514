import React, { Component } from 'react'
import {connect} from 'react-redux'
import {modalToggle,modalToggleReset} from 'redux/actions/general'
import {unPublishCareer} from 'redux/actions/careers'
import {
    updateImage,
    unPublishEvent,
    setAction,
    setStatus
} from 'redux/actions/event'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
import {Button } from '@material-ui/core'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00ACC1',
        },
        secondary: {
            main:'#E91E63',
            contrastText: '#FFFFFF',
        }
    } 
})
class confirm_unpublish extends Component {
    unPublish=async()=>{
        let {modal_data,modal_action,profile,token}=this.props
        await this.props.modalToggleReset()
        this.props.unPublishCareer(token,modal_data.id,0,profile.id)
    }
    unPublishImage=async()=>{
        let {modal_data,modal_action,profile,token}=this.props
        const {id,caption,eventId}=this.props.modal_data
        let {image,active_tab}=this.props.event
        console.log(modal_data)
        let fd=new FormData()
        fd.append('id',id)
        fd.append('caption',caption)
        fd.append('image',image)
        fd.append('publish',0)
        fd.append('eventId',eventId)
        fd.append('userId',profile.id)
        await this.props.modalToggleReset()
        this.props.updateImage(token,fd,id,`/0/${active_tab==='drafted'?0:1}/1/10/*`,active_tab)
    } 
    unPublishEvent=async()=>{
        let {modal_data,modal_action,profile,token,success_msg}=this.props
        // let {page,perPage}=this.props.event.list_event.info
        await this.props.modalToggleReset()
            
        await this.props.unPublishEvent(token,modal_data.id,0,profile.id,`/0/1/1/10/*`,success_msg)
        await this.props.setAction('add_event')
        await this.props.setStatus(0)
    } 
    renderAction=()=>{
        let {modal_data,modal_action,profile,token}=this.props
        switch (modal_action) {
            case 'unpublish_career':
               return this.unPublish()
            case 'unpublish_image':
               return this.unPublishImage()
            case 'unpublish_event':
               return this.unPublishEvent()
        
            default:
                break;
        }
        // case
    }
    render() {
        const {modal_data,modal_title}=this.props
        return (
            <div className='confirm-container'>
                <h4>Unpublish {modal_data.title}</h4>
                <p>Are you sure unpublish <b>{modal_data.title}</b></p>
                <div className='card-footer'>
                    <MuiThemeProvider theme={themeButton}>
                        <Button onClick={()=>this.props.modalToggleReset()}   size='small' color='primary' className='btn-remove-capital'>No, back to {modal_title}</Button>
                        <Button onClick={()=>this.renderAction()}   size='small' color='secondary' variant='contained' className='head-add-section__btn '>Yes</Button>
                    </MuiThemeProvider>
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    general:state.general,
    event:state.event
})
const mapDispatchToProps={
    modalToggleReset,
    modalToggle,
    unPublishCareer,
    updateImage,
    unPublishEvent,
    setAction,
    setStatus
}
export default connect(mapStateToProps,mapDispatchToProps)(confirm_unpublish)