import React, { Component } from 'react'
import {Button,Table,TableHead,TableRow,TableCell,TableBody} from '@material-ui/core'
import Edit from 'assets/icon/edit.svg'
import Close from 'assets/icon/close.svg'
import {modalToggle} from 'redux/actions/general'
import {connect} from 'react-redux'
import {
    setTestimony
} from 'redux/actions/event'
class testimony extends Component {
    addTestimony=()=>{
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "Testimony",
            modal_component: "testimony",
            modal_data:null ,
            modal_size:500,
            modal_action:'add_testimony'

        })
        // alert('asdf')
    }
    editTestimony=(data,index)=>{
        console.log('data',data)
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: "Testimony",
            modal_component: "testimony",
            modal_data:{...data,index:index} ,
            modal_size:450,
            modal_action:'edit_testimony'

        })

    }
    deleteTestimony=(id,i,plus1)=>{
        const {event}=this.props
        let new_testimony=[]
       
        // let testimony=event.testimony.filter((data)=>{
        //     return !data[i]
        // })
        // testimony.map((data,i)=>{
        //     new_testimony.push({id:i,file:data.file,name:data.name,title:data.title,company:data.company,testimony:data.testimony,file_url:data.file_url})
        // })
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: `Testimony`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                index:i,
                title:`testimony ${plus1}`,
                // testimony:new_testimony,
                msg:`<p>Are you sure delete <b>testimony ${plus1}</b></p>`
            },
            modal_action:'delete_testimony'
        })
       
        // this.props.setAgenda(new_agenda)
    }
    render() {
        const {event}=this.props

        return (
            <div>
                <div className='subcontent-container'>
                    <p className='bold'>Testimony</p>
                    <Button onClick={this.addTestimony} disabled={this.props.handleDisable()}  size='small' color='secondary' variant='contained' className='head-section__btn'>
                        Add new testimony
                    </Button>
                </div>
                <Table  size="small" aria-label="a dense table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="left">Job title</TableCell>
                        <TableCell align="left">Company name</TableCell>
                        {/* <TableCell align="right">Testimony</TableCell> */}
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {event.testimony.length>0&&event.testimony.map((data,i)=>(
                                <TableRow>
                                    <TableCell align="left">{data.name}</TableCell>
                                    <TableCell align="left">{data.title}</TableCell>
                                    <TableCell align="left">{data.company}</TableCell>
                                    {/* <TableCell align="left">{data.testimony}</TableCell> */}
                                    <TableCell align="right">
                                        <img src={Edit} onClick={()=>this.props.handleDisable()?null:this.editTestimony(data,i)} className='icon-action'/>
                                        <img src={Close} onClick={()=>this.props.handleDisable()?null:this.deleteTestimony(data.id,i,i+1)} className='icon-action'/>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {event.testimony.length<1&&
                            <TableRow>
                                <TableCell colSpan={4} style={{textAlign:'center'}}>
                                    <p className='bold'>No image,<Button disabled={this.props.handleDisable()} onClick={this.addTestimony} color='secondary' size='small' className='btn-remove-capital'>Add new testimony</Button></p>
                                </TableCell>
                            </TableRow>
                            }
                    </TableBody>
                </Table>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    event:state.event,
    general:state.general
})
const mapDispatchToProps={
    modalToggle,
    setTestimony
}
export default connect(mapStateToProps,mapDispatchToProps)(testimony)
