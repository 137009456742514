import React, { Component } from 'react'
import {Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
import Eye from 'assets/icon/eye.svg'
import Edit from 'assets/icon/edit.svg'
import Close from 'assets/icon/close.svg'
import moment from 'moment'
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import Skeleton from 'components/Skeleton'

export default class list_published extends Component {
    state={
        page:1,
        pageLength:10
    }
    componentDidMount(){
        const {list_event_published,published_pagination}=this.props.event
        const {page,pageLength}=this.state
        if(list_event_published!==null){
            this.props.getEvent(`/0/1/${published_pagination.page}/${published_pagination.perPage}`,'published')
        }else{
            this.props.getEvent(`/0/1/${page}/${pageLength}`,'published')

        }
    }
    renderCategory=(id)=>{
        switch (id) {
            case 1:
                return 'Mega Seminar'
            case 2:
                return 'Public workshop'
            case 3:
                return 'Webinar'
            case 4:
                return 'Blended learning'
                case 5:
                    return 'Hybrid Class'   
            default:
                break;
        }
    }
    togglePagination=(page,pageLength)=>{
        console.log('pagination',pageLength)
        this.setState({page,pageLength},()=>{
            this.props.getEvent(`/0/1/${page}/${pageLength}`,'published')

        })

    }
    render() {
        let {detailEvent,seeEventOnly,isLoadingTable,event}=this.props
        let {page,pageLength}=this.state
        // const {events,info}=event
        console.log('list_publish', event)
        return (
            <div>
                <Table  size="small" aria-label="a dense table">
                    <TableHead>
                    <TableRow>
                        <TableCell align="left">Event Title</TableCell>
                        <TableCell align="left">Date</TableCell>
                        <TableCell align="left">Category</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoadingTable!==true&&event.list_event_published!==null?event.list_event_published.map((data,i)=>(
                            <TableRow key={i}>
                                <TableCell style={{maxWidth:255,minHeight:100,}}>{data.webEvent.title}</TableCell>
                                <TableCell>{moment(data.webEvent.fromDate).format('DD MMM')}-{moment(data.webEvent.toDate).format('DD MMM YYYY')}</TableCell>
                                <TableCell>{this.renderCategory(data.webEvent.categoryId)}</TableCell>
                                <TableCell align="right">
                                <button  className='btn-unpublish' onClick={()=>seeEventOnly(data.webEvent.id)}><img src={Eye} /></button>
                                <button className='btn-unpublish' onClick={()=>this.props.unPublish(data)}>Unpublish</button>
                                </TableCell>
                            </TableRow>
                        )):<Skeleton count={4}/>}
                    </TableBody>
                </Table>
                <div className='card-table__pagination'>
                    {event.published_pagination!==null&&
                    <TablePagination
                            className="card-pagination"
                            type="reduced"
                            page={event.published_pagination.page}
                            pageLength={event.published_pagination.perPage}
                            totalRecords={event.published_pagination.total}
                            // totalRecords={event.published_pagination.total}
                            onPageChange={({ page, pageLength }) => {
                                this.togglePagination(page,pageLength)
                            }}
                            prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                            nextPageRenderer={() => <img src={CevronRight}/>}
                        />
                    }
                </div>
            </div>
        )
    }
}
