import React, { Component } from 'react'
import {Button} from '@material-ui/core'
import './style.css'
import Dummy from 'assets/image/dummy.jpg'
import {connect} from 'react-redux'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
import moment from 'moment'
import NoImage from 'assets/image/noimage.png'

const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#E91E63',
        },
        secondary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',
        }
    } 
})
class index extends Component {
    renderCategory=(id)=>{
        switch (id) {
            case '1':
                return 'Mega Seminar'
            case '2':
                return 'Public Workshop'
            case '3':
                return 'Webinar'
            case '4':
                return 'Blended learning'
                case '5':
                    return 'Hybrid Class'
            default:
                break;
        }
    }
    megaSeminarPublicWorkshop=()=>{
        const event=this.props.preview
        console.log('hiiks',event)
        const renderDate=`${moment(event.start_date).format('DD MMM')}-${moment(event.end_date).format('DD MMM')} ${moment(event.start_date).format('YYYY')}`
        const renderThumbnail=event.thumbnail!==null?event.thumbnail.file_url!==undefined?event.thumbnail.file_url:event.thumbnail.thumbnail_url:NoImage
        return(
            <div className='grid-preview'>
                    
            <div className='medium-preview'>
                <div  style={{display:'flex',flexDirection:'column'}}>
                    <div className='grid3-box-img-overlay'>
                        <img src={renderThumbnail} className='grid3-box-img'/>
                    </div>
                    <div className='grid3-box-content'>
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <h3>{renderDate}</h3>
                            <h3>{this.renderCategory(event.category.toString())}</h3>
                        </div>
                        {/* <br/> */}
                        <h1>{event.title.substring(0,55)}</h1>
                        <p>{event.short_desc}</p>
                        {/* <Button style={{textTransform:'none',fontWeight:'bold',padding:0,borderRadius:10}} color="secondary">Read more</Button> */}
                        <div style={{display:'flex',justifyContent:'flex-end',width:'100%'}}>
                        <Button style={{textTransform:'none',fontWeight:'bold',padding:0,borderRadius:10}} color="secondary" variant='contained'>Detail</Button>

                        </div>

                    </div>
                </div>
                </div>
            <div>
                <div className='small-preview'>
                    <div className='small-image'>
                        <img src={renderThumbnail}/>
                    </div>
                    <div className='small-caption'>
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <h3>{renderDate}</h3>
                            <h3>{this.renderCategory(event.category.toString())}</h3>
                        </div>
                        <h1>{event.title.substring(0,50)} </h1>
                        {/* <br/> */}
                        <div style={{display:'flex',justifyContent:'flex-end',width:'100%'}}>
                        <Button style={{textTransform:'none',fontWeight:'bold',padding:0,borderRadius:10}} color="secondary" variant='contained'>Detail</Button>

                        </div>
                    </div>
                </div>
                <br/>
                

            </div>
        </div>
        )
    }
    webinarBlended=()=>{
        const event=this.props.preview
        console.log('event',event)
        const renderDate=`${moment(event.start_date).format('DD MMM')}-${moment(event.end_date).format('DD MMM')} ${moment(event.start_date).format('YYYY')}`
        // const renderDateWebinar=moment(event.start_date).format('dddd, DD MMM YYYY')
        const renderTime=`${moment(event.start_time).format('HH.mm')} - ${moment(event.end_time).format('HH.mm')} WIB`
        const renderThumbnail=event.thumbnail!==null?event.thumbnail.file_url!==undefined?event.thumbnail.file_url:event.thumbnail.thumbnail_url:NoImage
        let renderDateWebinar
        if(event.start_date===event.end_date){
            renderDateWebinar=`${moment(event.start_date).format('dddd, DD MMM YYYY')} `
        }else{
            renderDateWebinar=`${moment(event.start_date).format('DD MMM')}-${moment(event.end_date).format('DD MMM')} ${moment(event.start_date).format('YYYY')}`
        }
        return(
            <div className='grid-preview'>
                    
            <div className='medium-preview'>
                <div  style={{display:'flex',flexDirection:'column'}}>
                    <div className='grid3-box-img-overlay'>
                        <img src={renderThumbnail} className='grid3-box-img'/>
                    </div>
                    <div className='grid3-box-content'>
                        {event.category.toString()==='3'?
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <h3>{renderDateWebinar}</h3>
                            <h3>{renderTime}</h3>
                        </div>
                        :
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <h3>{renderDate}</h3>
                        </div>
                        }
                        {/* <br/> */}
                        
                        {/* <h1>{event.title.substring(0,55)}</h1> */}
                        <p>{event.short_desc}</p>
                        <div style={{textAlign:"right"}}>
                        <Button style={{textTransform:'none',fontWeight:'bold',padding:0,borderRadius:10}} color="secondary">Read more</Button>
                        </div>

                    </div>
                </div>
                </div>
           
        </div>
        )
    }
    render() {
        const {tabsToggle}=this.props
        const event=this.props.preview
       console.log('event.category', event.category,toString(event.category))
        return (
            <div>
                <MuiThemeProvider theme={themeButton}>
                <div className='head-section'>
                    <div>
                        <h3>Preview</h3>
                    </div>
                    <div>
                            
                        <Button onClick={()=>tabsToggle('add_event')} size='small' color='primary' variant='outlined' className='head-add-section__btn back'>Back</Button>
                        <Button onClick={()=>window.open('/previewdetail','_blank')}  size='small' color='primary' variant='contained' className='head-add-section__btn preview'>Preview detail event</Button>
                    </div>
                </div>
                {event.category.toString()==='1'||event.category.toString()==='2'?this.megaSeminarPublicWorkshop():this.webinarBlended()}
                </MuiThemeProvider>

            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    preview:state.preview
})
const mapDispatchToProps={

}
export default connect(mapStateToProps,mapDispatchToProps)(index)