import React, { useEffect, useState } from "react";
import ChipInput from "material-ui-chip-input";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { addCDHXCategory } from "redux/actions/event";
import Cookies from "universal-cookie";
const cookie = new Cookies();
const useStyles = makeStyles((theme) => ({
  textField: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
    width: "80%",
    marginBottom: 15,
  },
  textField2: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
    width: "100%",
    marginBottom: 15,
  },
}));

export default function Cdhx_category() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const event = useSelector((state) => state.event);
  const [categories, setcategories] = useState([]);
  const [id, setid] = useState([]);
  let token = cookie.get("login_cookie");
  let profile = cookie.get("profile_cookie");

  useEffect(() => {
    let new_c = [];
    let new_id = [];
    event.cdhx_categorys.map((d) => {
      new_id.push(Object.values(d)[0]);

      new_c.push(Object.values(d)[1]);
    });
    setcategories(new_c);
    setid(new_id);
  }, []);
  const onAdd = (value) => {
    setcategories([...categories, value]);
    setid([...id, ""]);
  };
  const onDelete = (v, i) => {
    let new_c = [...categories];
    let new_id = [...id];
    new_c.splice(i, 1);
    new_id.splice(i, 1);

    setcategories(new_c);
    setid(new_id);
  };

  const onSave = () => {
    let new_c = [];
    categories.map((d, i) => {
      new_c.push({ id: id[i], text: d });
    });
    let data = {
      userId: profile.id,
      items: new_c,
    };
    dispatch(addCDHXCategory(token, data));
  };
  return (
    <div>
      <ChipInput
        label="Category"
        value={categories}
        onAdd={(chip) => onAdd(chip)}
        // onAdd={(chip) =>console.log('chip', chip)}
        onDelete={(chip, index) => onDelete(chip, index)}
        variant="outlined"
        className={classes.textField2}
      />
      <div className="card-footer">
        <Button
          onClick={onSave}
          size="small"
          color="secondary"
          variant="contained"
          className="head-add-section__btn "
        >
          Save
        </Button>
      </div>
    </div>
  );
}
