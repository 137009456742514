import React, { Component } from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import { connect } from "react-redux";
import { modalToggleReset } from "redux/actions/general";
import { setSpeaker } from "redux/actions/event";
import { handleFile, getBase64 } from "components/handleFile";
import image_line from "assets/icon/image_line.svg";
import AutoCompleteSelect from "components/Select";
import { getDetailSpeakers } from "redux/actions/speaker";
const themeField = createMuiTheme({
  palette: {
    primary: {
      main: "#00acc1",
    },
  },
});
class speaker extends Component {
  state = {
    name: "",
    title: "",
    company: "",
    profile: "",
    profileFileName: "",
    file_error: "",
  };
  componentWillMount() {
    const { modal_data } = this.props.general;
    if (modal_data !== null) {
      console.log(`modal_data`, modal_data);
      this.setState({
        name: modal_data.name,
        title: modal_data.title,
        company: modal_data.company,
        profile: modal_data.profile,
        profileFileName: modal_data.profileFileName,
      });
    }
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  addSpeaker = () => {
    const { name, title, company, profile, profileFileName } = this.state;
    const { speaker } = this.props.event;
    let speaker_state = {
      id: 0,
      name: name,
      title: title,
      company: company,
      profile: profile,
      profileFilename: profileFileName,
    };
    let existing = [...speaker, speaker_state];
    this.props.setSpeaker(existing);
    this.props.modalToggleReset();
  };
  updateSpeaker = () => {
    const { name, title, company, profile, profileFileName } = this.state;

    const { modal_data } = this.props.general;
    this.props.event.speaker[modal_data.index].name = name;
    this.props.event.speaker[modal_data.index].title = title;
    this.props.event.speaker[modal_data.index].company = company;
    this.props.event.speaker[modal_data.index].profile = profile;
    this.props.event.speaker[modal_data.index].profileFileName =
      profileFileName;
    this.props.modalToggleReset();
  };
  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  handleProfile = (evt) => {
    let handle = handleFile(evt);
    if (handle.file_error === null) {
      let file_url = URL.createObjectURL(handle.file);
      getBase64(handle.file, (result) => {
        this.setState({ profile: result, profileFileName: handle.file_name });
      });
    } else {
      console.log("err", handle);
      this.setState({ file_error: handle.file_error });
    }
  };
  onChangeSpeakers = async (value) => {
    let res = await this.props.getDetailSpeakers(value.id);
    this.setState({
      name: res.name,
      title: res.title,
      company: res.company,
    });
  };
  render() {
    const { name, title, company, profile, profileFileName } = this.state;
    const { modal_action } = this.props.general;

    return (
      <div>
        <MuiThemeProvider theme={themeField}>
          <AutoCompleteSelect
            color="secondary"
            noOptionsText={""}
            loading={false}
            disableClearable={true}
            onChange={(event, value) => this.onChangeSpeakers(value)}
            options={this.props.speaker.speakers}
            // value={course_id}
            // groupBy={(option)=>{
            //     if(search===''){
            //         return '5 Course terbaru'
            //     }else{
            //         return null
            //     }
            // }}
            getOptionLabel={(option) => option.name}
            // onInputChange={(event,e)=>onChangeCourse(e)}

            label="Choose Speaker"
          />
          <FormControl
            variant="outlined"
            size="small"
            className="add-investment__field"
          >
            <InputLabel htmlFor="speaker_name">Speaker name</InputLabel>
            <OutlinedInput
              id="speaker_name"
              type="text"
              name="name"
              value={name}
              onChange={this.onChange}
              required
              labelWidth={110}
              className="field-radius"
            />
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className="add-investment__field"
          >
            <InputLabel htmlFor="speaker_job_title">
              Speaker job title
            </InputLabel>
            <OutlinedInput
              id="speaker_job_title"
              type="text"
              name="title"
              value={title}
              onChange={this.onChange}
              required
              labelWidth={120}
              className="field-radius"
            />
          </FormControl>
          <FormControl
            variant="outlined"
            size="small"
            className="add-investment__field"
          >
            <InputLabel htmlFor="company">Company</InputLabel>
            <OutlinedInput
              id="company"
              type="text"
              name="company"
              value={company}
              onChange={this.onChange}
              required
              labelWidth={80}
              className="field-radius"
            />
          </FormControl>
          {profileFileName === "" || profileFileName === null ? (
            <Button
              className="btn-remove-capital"
              variant="text"
              startIcon={<img src={image_line} />}
              color="primary"
              component="label"
            >
              Add photo here
              <input
                type="file"
                style={{ display: "none" }}
                onChange={this.handleProfile}
              />
            </Button>
          ) : (
            <p style={{ overflowWrap: "break-word" }}>
              {profileFileName}&nbsp;&nbsp;
              <Button
                component="label"
                color="primary"
                className="btn-remove-capital"
                variant="text"
              >
                Change
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={this.handleProfile}
                />
              </Button>
            </p>
          )}
        </MuiThemeProvider>
        <div className="card-footer">
          {modal_action === "add_speaker" ? (
            <Button
              onClick={this.addSpeaker}
              size="small"
              color="primary"
              variant="contained"
              className="head-add-section__btn"
            >
              Add
            </Button>
          ) : (
            <Button
              onClick={this.updateSpeaker}
              size="small"
              color="primary"
              variant="contained"
              className="head-add-section__btn"
            >
              Update
            </Button>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  event: state.event,
  general: state.general,
  speaker: state.speaker,
});
const mapDispatchToProps = {
  setSpeaker,
  modalToggleReset,
  getDetailSpeakers,
};
export default connect(mapStateToProps, mapDispatchToProps)(speaker);
