import * as actionTypes from '../constants/careers'
import {apiCall} from 'service/apiCall'
import {setLoading,setLoadingTable,setError,modalToggle} from './general'
import Cookie from 'universal-cookie'
import {get} from 'lodash'


export const setTitle=(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_TITLE,
        payload:payload
    })
}
export const setPlacement=(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_PLACEMENT,
        payload:payload
    })
}
export const setJobCode=(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_JOBCODE,
        payload:payload
    })
}
export const setStatus=(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_STATUS,
        payload:payload
    })
}
export const setDescription=(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_DESCRIPTION,
        payload:payload
    })
}
export const clearState=()=>dispatch=>{
    dispatch({
        type:actionTypes.CLEAR_STATE,
    })
}






export const getCarrers=(token,slug='')=>async dispatch=>{
    dispatch(setLoadingTable(true))
    let dataReq={
        url:`/Career${slug}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoadingTable(false))
        dispatch({
            type:actionTypes.GET_CAREERS,
            payload:res.data
        })
        
    }else{
        dispatch(setLoadingTable(false))

        
    }
}

export const postCareer=(token,data)=>async dispatch=>{
    dispatch(setLoading(true))
    let dataReq={
        url:`/Career`,
        method:'POST',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==201){
        dispatch(setLoading(false))

        dispatch(getCarrers(token))
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Add new career succsess",
            modal_component: "add_career",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>career have been successfully registered with the name <b>${res.data.title}</b></p> `,
                ...res.data
            },
            modal_action:'success'
        }))
    }else{
        dispatch(setLoading(false))
        
    }
}

export const publishCareer=(token,id,is_publish=1,userId)=>async dispatch=>{
    dispatch(setLoading(true))
    let dataReq={
        url:`/Career/publish/${id}/${is_publish}/${userId}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading(false))

        dispatch(getCarrers(token))
    }
}
export const unPublishCareer=(token,id,is_publish=0,userId)=>async dispatch=>{
    dispatch(setLoading(true))
    let dataReq={
        url:`/Career/publish/${id}/${is_publish}/${userId}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading(false))

        dispatch(getCarrers(token))
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Update career success",
            modal_component: "update_career",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Career ${res.data.title} successfully updated</p> `,
                ...res.data
            },
            modal_action:'success'
        }))
    }
}
export const updateCareer=(token,data,id)=>async dispatch=>{
    dispatch(setLoading(true))
    let dataReq={
        url:`/Career/${id}`,
        method:'PUT',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
            data
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==204){
    dispatch(setLoading(false))

        dispatch(getCarrers(token))
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Update career success",
            modal_component: "update_career",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Career ${data.title} successfully updated</p> `,
                ...data
            },
            modal_action:'success'
        }))
    }
}
export const deleteCareer=(token,id,userId)=>async dispatch=>{
    dispatch(setLoading(true))
    let dataReq={
        url:`/Career/${id}/${userId}`,
        method:'DELETE',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
    dispatch(setLoading(false))

        dispatch(getCarrers(token))
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Career Deleted",
            modal_component: "delete_career",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Career ${res.data.title} successfully deleted in our system</p> `
            },
            modal_action:'success'
        }))
    }
}
