import React from 'react';
import logo from './logo.svg';
import './App.css';
import { ThemeProvider } from '@material-ui/styles';
import Routes from './routes'
import Theme from './theme'
import {Provider} from 'react-redux'
import {store,persistor} from './store'
import { PersistGate } from 'redux-persist/integration/react'
function App() {
  return (
    <Provider store={store}>
       <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={Theme}>
        <Routes/>
      </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
