import React, { Component } from 'react'
import './style.css'
export default class index extends Component {
    render() {
        return (
           <nav>
               <div className='hamburger' onClick={this.props.sidebarToggle}>
                   <div className='hamburger__list'/>
                   <div className='hamburger__list'/>
                   <div className='hamburger__list'/>
               </div>
               <div className='logo'>
                <h1>CMS</h1>
               </div>
               
               <div style={{flex:1}}></div>
               <div className='list hide-mobile'>
                   <ul>
                       <li>Logout</li>
                   </ul>
               </div>
           </nav>
        )
    }
}
