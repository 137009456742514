import React, { Component } from 'react'
import Layout from 'components/Layouts'
import ListEvent from './list'
import Add from './add/index'
import Preview from './preview_new'
import {connect} from 'react-redux'
import {setAction,getDetailEvent} from 'redux/actions/event'
import {withRouter} from 'react-router-dom';

class index extends Component {
    state={
        tab_active:'list_event',
        from_dashboard:false
    }
    async componentDidMount(){
        let {key,id}=this.props.match.params
        if(key!==undefined){
            if(key==='see'){
                let res=await this.props.getDetailEvent(id)
                if(res){
                    this.setState({tab_active:'add_event',from_dashboard:true})
                    this.props.setAction('see_event')
                }
            }
        }
    }
    tabToggle=(key)=>{
        this.setState({tab_active:key})
    }
   
    render() {
        let {tab_active,from_dashboard}=this.state
        const {profile,token}=this.props
        return (
            <Layout>
                {tab_active==='list_event'&&<ListEvent  tabsToggle={this.tabToggle} profile={profile} token={token}/>}
                {tab_active==='add_event'&&<Add from_dashboard={from_dashboard} tabsToggle={this.tabToggle} profile={profile} token={token}/>}
                {tab_active==='preview'&&<Preview tabsToggle={this.tabToggle} profile={profile} token={token}/>}
            </Layout>
        )
    }
}
export default withRouter(connect(null,{setAction,getDetailEvent})(index))