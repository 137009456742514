import React, { Component } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from 'ckeditor5-build-classic-with-upload-image';
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  setTitle,
  setPlacement,
  setDescription,
  setJobCode,
  setStatus,
  postCareer,
  publishCareer,
  updateCareer,
} from "redux/actions/careers";
import { modalToggleReset } from "redux/actions/general";
import Editor from "ckeditor5-custom-build/build/ckeditor";

const config = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "blockQuote",
    "link",
    "numberedList",
    "bulletedList",
    "imageUpload",
    "insertTable",
    "mediaEmbed",
    "|",
    "undo",
    "redo",
  ],
  placeholder: "Type here",
};
const themeButton = createMuiTheme({
  palette: {
    primary: {
      main: "#E91E63",
    },
    secondary: {
      main: "#FF9800",
      contrastText: "#FFFFFF",
    },
  },
});

class career extends Component {
  componentWillMount() {
    // if(this.props.modal_data!==null){
    //     const {title,location,publish,description}=this.props.modal_data
    //     this.props.setTitle(title)
    //     this.props.setPlacement(location)
    //     this.props.setJobCode(title)
    //     this.props.setStatus(publish)
    //     this.props.setDescription(description)
    // }
    console.log(`this.props.modal_data`, this.props.modal_data);
  }
  onChange = (e) => {
    let { name, value } = e.target;
    switch (name) {
      case "job_title":
        this.props.setTitle(value);
        break;
      case "placement":
        this.props.setPlacement(value);
        break;
      case "job_code":
        this.props.setJobCode(value);
        break;
      case "status":
        this.props.setStatus(value);
        break;
      default:
        break;
    }
  };
  disableToggle = () => {
    const { modal_type } = this.props.general;
    let { title, placement, job_code, status, description } =
      this.props.careers;
    if (modal_type === "add" || modal_type === "edit") {
      if (
        title === "" ||
        placement === "" ||
        job_code === "" ||
        description === ""
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  buttonToggle = async (key) => {
    const { token, profile } = this.props;
    const { modal_type } = this.props.general;
    let { title, placement, job_code, status, description } =
      this.props.careers;
    await this.props.modalToggleReset();
    if (modal_type === "add") {
      let data = {
        title,
        location: placement,
        job_code,
        publish: key === "publish" ? 1 : 0,
        description,
      };
      await this.props.postCareer(token, data);
      // this.props.modalToggleReset()
    } else {
      const { id } = this.props.modal_data;
      let data = {
        id: id,
        title,
        location: placement,
        job_code,
        publish: key === "publish" ? 1 : 0,
        description,
        userId: profile.id,
      };
      await this.props.updateCareer(token, data, id);
    }
  };
  render() {
    let { title, placement, job_code, status, description } =
      this.props.careers;
    const { modal_type } = this.props.general;
    return (
      <div>
        <div className="grid-2-column">
          <FormControl variant="outlined" size="small" className="modal__field">
            <InputLabel htmlFor="job_title">Job title</InputLabel>
            <OutlinedInput
              disabled={modal_type === "readonly" ? true : false}
              value={title}
              id="najob_titleme"
              type="text"
              name="job_title"
              onChange={this.onChange}
              required
              labelWidth={75}
              className="field-radius"
            />
          </FormControl>
          <FormControl variant="outlined" size="small" className="modal__field">
            <InputLabel htmlFor="placement">Placement</InputLabel>
            <OutlinedInput
              value={placement}
              id="placement"
              type="text"
              name="placement"
              onChange={this.onChange}
              required
              labelWidth={75}
              className="field-radius"
              disabled={modal_type === "readonly" ? true : false}
            />
          </FormControl>
          <FormControl variant="outlined" size="small" className="modal__field">
            <InputLabel htmlFor="job_code">Job code</InputLabel>
            <OutlinedInput
              value={job_code}
              id="job_code"
              type="text"
              name="job_code"
              onChange={this.onChange}
              required
              labelWidth={75}
              className="field-radius"
              disabled={modal_type === "readonly" ? true : false}
            />
          </FormControl>
          <FormControl variant="outlined" size="small" className="modal__field">
            <InputLabel htmlFor="status">Status</InputLabel>
            <OutlinedInput
              value={status === 1 ? "Published" : "Drafted"}
              id="status"
              type="text"
              name="status"
              onChange={this.onChange}
              required
              disabled
              labelWidth={50}
              className="field-radius"
            />
          </FormControl>
        </div>
        <br />
        <div className="job-description">
          <p className="bold">Job description</p>
          <br />
          <CKEditor
            disabled={modal_type === "readonly" ? true : false}
            data={description}
            name="description"
            editor={Editor}
            config={config}
            onChange={(event, editor) => {
              const data = editor.getData();
              this.props.setDescription(data);
            }}
          />
        </div>
        <br />
        <div className="card-footer">
          <MuiThemeProvider theme={themeButton}>
            <Button
              onClick={() => this.buttonToggle("draft")}
              disabled={this.disableToggle()}
              size="small"
              color="primary"
              variant="contained"
              className="head-add-section__btn save"
            >
              Save
            </Button>
            <Button
              onClick={() => this.buttonToggle("publish")}
              disabled={this.disableToggle()}
              size="small"
              color="secondary"
              variant="contained"
              className="head-add-section__btn "
            >
              Publish
            </Button>
          </MuiThemeProvider>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  general: state.general,
  careers: state.careers,
});
const mapDispatchToProps = {
  setTitle,
  setPlacement,
  setDescription,
  setJobCode,
  setStatus,
  setDescription,
  postCareer,
  publishCareer,
  updateCareer,
  modalToggleReset,
};
export default connect(mapStateToProps, mapDispatchToProps)(career);
// export default career
