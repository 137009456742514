export const SET_PARTICIPANT='SET_PARTICIPANT'
export const SET_BANNER_IMAGE='SET_BANNER_IMAGE'
export const SET_BANNER_PAGINATION='SET_BANNER_PAGINATION'

export const GET_COURSE='GET_COURSE'
export const GET_ALUMN='GET_ALUMN'
export const GET_EXPERT='GET_EXPERT'
export const GET_CLIENT='GET_CLIENT'
export const SET_COURSE_ID='SET_COURSE_ID'
export const SET_ALUMNI_DETAIL='SET_ALUMNI_DETAIL'
export const SET_ALUMNI_UPLOAD='SET_ALUMNI_UPLOAD'
export const RESET_ALUMNI_UPLOAD='RESET_ALUMNI_UPLOAD'
export const SET_ALUMNI_EMAIL='SET_ALUMNI_EMAIL'