import React,{useState} from 'react'
import AutoCompleteSelect from 'components/Select'
import {Button} from '@material-ui/core'
import {debounce, isEmpty} from 'lodash'
import {getCourse,getDetailAlumni,getCourseSearch,postAlumni, setAlumniUpload,uploadAlumni,setCourseId, setAlumniDetail } from 'redux/actions/cdhx'

import { useDispatch,useSelector } from 'react-redux'
export default function Add_course(props) {
    const dispatch = useDispatch()
    const cdhx = useSelector(state => state.cdhx)
    const {course_list,alumni_detail}=cdhx
    let {id,clientId,eventId,name,email,phone,department,jobTitle}=alumni_detail

    const [textSelect,setTextSelect]=useState('Type course name')
    const [loading,setLoading]=useState(false)
    const [search, setsearch] = useState('')
    const save=async()=>{
        let data={
            id:id,
            clientId:clientId.id,
            name,
            email,
            phone,
            department,
            eventId:eventId.id,
            jobTitle,
            userId:props.profile.id
        }
        let res=await dispatch(postAlumni(data))
        if(res){
            dispatch(getDetailAlumni(id))
        }
    }
    const onChangeCourse=debounce(async (value)=>{
        let findCourse=course_list.filter((data)=>{
            return data.label.toLowerCase().includes(value.toLowerCase())
        })
        if(isEmpty(findCourse)){
            setsearch(value)

            if(value!==search&&value.length>0){
                setLoading(true)
                let res=await dispatch(getCourseSearch(value))
                if(isEmpty(res)){
                    setTextSelect('No options')
                }
                setLoading(false)
            }else{
                dispatch(getCourse())
            }
        }
        
    },1000)
    return (
        <div>
             <AutoCompleteSelect
                color='secondary'
                noOptionsText={textSelect}
                loading={loading}
                disableClearable={true}
                onChange={(event,value)=>dispatch(setAlumniDetail({eventId:value}))}
                options={course_list}
                groupBy={(option)=>{
                    if(search===''){
                        return '5 Course terbaru'
                    }else{
                        return null
                    }
                }}
                getOptionLabel={(option) => option.label}
                onInputChange={(event,e)=>onChangeCourse(e)}

                label="Choose Course Name First"
            />
            <br/>
            <div style={{textAlign:'right'}}>
                <Button onClick={save}   size='small' color='primary' variant='contained' className='btn-remove-capital btn-rounded'>Save</Button>
            </div>
            <br/>
        </div>
    )
}
